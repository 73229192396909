import React, { useMemo, useState } from 'react';
import { IconTrash } from '@tabler/icons-react';
import { AllocationQuerySchema, StockLotQuerySchema } from 'types/inventory';
import ExtendedDatagrid from 'ui-component/DataGrid/ExtendedDatagrid';
import { DatagridNames, RowAction } from 'types/datagrid';
import { ALL_APPS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import { COLOR_OPTIONS } from 'types';
import useGetCustomPropertyColumns from 'hooks/useGetCustomPropertyColumns';
import {
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';
import { getTrackingStrings } from 'constants/trackingStrings';
import { useKitRequestContext } from 'views/kitting/Kit/KitRequestContext';
import _ from 'lodash';
import { DecoratedKitRequestLine } from 'hooks/useGetDecoratedKitRequestLines';

import { columns } from 'views/kitting/Kit/components/KitRequestLineDetailPanel/KitRequestAllocatedStockLotsTable/columns';
import { Lite } from 'types/api';
import { Alert, AlertTitle } from '@mui/material';
import { useIsCofactr } from 'hooks/useIsCofactr';
import { useDialogManager } from 'hooks/useDialogManager';
import ConfirmDialog from 'ui-component/shared/ConfirmDialog';
import { useDeleteAllocationsMutation } from 'store/slices/clientV2/allocations';
import { handleErr } from 'utils/functions';
import useSnackbar from 'hooks/useSnackbar';
import AddStockLotToKitRequestLineDialog from 'views/kitting/Kit/components/KitRequestLineDetailPanel/AddStockLotToKitRequestLineDialog';

enum ALLOCATED_STOCK_LOTS_TABLE_DIALOGS {
  DELETE_ALLOCATION = 'DELETE_ALLOCATION',
  ADD_STOCK_LOT = 'ADD_STOCK_LOT',
}

type StockLotWithAllocatedQuant = Lite<StockLotQuerySchema> & {
  quant: AllocationQuerySchema['quant'];
  allocationId: AllocationQuerySchema['id'];
};

const KitRequestAllocatedStockLotsTable = ({
  kitRequestLineId,
}: {
  kitRequestLineId: DecoratedKitRequestLine['id'];
}) => {
  const { isCofactr } = useIsCofactr();
  const { openAddStockLotDialog } = getTrackingStrings();
  const { dispatchSuccessSnackbar } = useSnackbar();

  const { closeDialog, toggleDialog, isDialogOpen } = useDialogManager(
    Object.values(ALLOCATED_STOCK_LOTS_TABLE_DIALOGS)
  );

  const [activeRow, setActiveRow] = useState<StockLotWithAllocatedQuant | null>(
    null
  );
  const [deleteAllocationError, setDeleteAllocationError] = useState<
    string | null
  >(null);

  const { customPropertyColumns } = useGetCustomPropertyColumns({
    model: CustomPropertyModel.STOCK_LOT,
    visibilityContext: CustomPropertyVisibility.DATAGRID_READ_ONLY,
  });

  const [deleteAllocation, { isLoading: isLoadingDeleteAllocation }] =
    useDeleteAllocationsMutation();

  const { kitRequest, decoratedKitRequestLines, isLoadingKitRequestLines } =
    useKitRequestContext();

  const kitRequestLine = _.find(
    decoratedKitRequestLines,
    (krl) => krl.id === kitRequestLineId
  );

  const allocations: StockLotWithAllocatedQuant[] = useMemo(
    () =>
      _.chain(decoratedKitRequestLines)
        .find((line) => line.id === kitRequestLineId)
        .get('allocations')
        .map((allocation) => ({
          // Hard-coding this typing until we can untangle where allocation.stockLot is new vs old schema
          ...(_.chain(decoratedKitRequestLines)
            .find((line) => line.id === kitRequestLineId)
            .get('stockLots')
            .find({ id: allocation.stockLot.id })
            .value() ||
            allocation.stockLot ||
            {}),
          allocatedQuantity: allocation.quant,
          shippedQuant: _.chain(decoratedKitRequestLines)
            .find((line) => line.id === kitRequestLineId)
            .get('shipmentLines')
            .filter((sl) => sl.stockLot === allocation.stockLot.id)
            .sumBy('shippedQuant')
            .value(),
          allocationId: allocation.id,
        }))
        .value(),
    [decoratedKitRequestLines, kitRequestLineId]
  );

  const handleDeleteAllocation = async () => {
    if (activeRow) {
      try {
        await deleteAllocation({
          ids: [activeRow.allocationId],
        }).unwrap();
        dispatchSuccessSnackbar('Allocation deleted successfully');
        closeDialog();
      } catch (err) {
        handleErr(err, (errMessage: string) => {
          setDeleteAllocationError(errMessage);
        });
      }
    }
  };

  const rowActions: RowAction<StockLotWithAllocatedQuant>[] = [
    {
      Icon: IconTrash,
      getLabel: () => 'Delete Allocation',
      getRowTooltip: () => 'Delete Allocation',
      color: COLOR_OPTIONS.error,
      onRowClick: (row) => {
        if (row) {
          setActiveRow(row);
          toggleDialog(ALLOCATED_STOCK_LOTS_TABLE_DIALOGS.DELETE_ALLOCATION);
        }
      },
      disabled: !isCofactr,
      hideRowAction: (__) => !isCofactr,
      showInRowMenu: true,
      permissionScope: {
        app: ALL_APPS.KITTING.id,
        accessLevel: appPermissionAccessLevels.edit,
      },
    },
  ];

  const memoizedColumns = useMemo(
    () => [...columns, ...customPropertyColumns],
    [customPropertyColumns]
  );

  const totalAllocatedQuant = _.sumBy(allocations, 'allocatedQuantity');

  return (
    <>
      {kitRequestLine?.minimumQuantity &&
        totalAllocatedQuant < kitRequestLine.minimumQuantity &&
        isCofactr && (
          <Alert severity="warning" sx={{ mt: 3 }}>
            <AlertTitle>Allocate More Quantity</AlertTitle>
            The minimum quantity for this kit line is{' '}
            {kitRequestLine?.minimumQuantity}. Currently, only{' '}
            {totalAllocatedQuant} is allocated. Please allocate more stock lots
            to meet the minimum quantity.
          </Alert>
        )}
      <ExtendedDatagrid<StockLotWithAllocatedQuant>
        loading={isLoadingKitRequestLines}
        exportFileNamePrefix={`${kitRequest?.name}-KitRequest-StockLots`}
        // TODO: Update this copy to reference adding stock lots once UI-3874 is implemented
        noRows="No Stock Lots are allocated to this kit line."
        gridName={DatagridNames.inventoryShipmentStockLotTab}
        rows={allocations}
        columns={memoizedColumns}
        rowActions={isCofactr ? rowActions : undefined}
        initialVisibleColumns={[
          'allocatedQuantity',
          'shippedQuant',
          'quant',
          // 'stockLocation', Removed until we determine if/how to pull in relevant location info
          'package',
          'lotId',
        ]}
        newRecordButton={
          isCofactr
            ? {
                label: 'Add Stock Lot',
                permissionScope: {
                  app: ALL_APPS.KITTING.id,
                  accessLevel: appPermissionAccessLevels.edit,
                },
                onClick: () =>
                  toggleDialog(
                    ALLOCATED_STOCK_LOTS_TABLE_DIALOGS.ADD_STOCK_LOT
                  ),
                trackingName: openAddStockLotDialog,
              }
            : undefined
        }
        initialSorting={{
          sortModel: [{ field: 'part', sort: 'asc' }],
        }}
      />
      {isDialogOpen(ALLOCATED_STOCK_LOTS_TABLE_DIALOGS.DELETE_ALLOCATION) && (
        <ConfirmDialog
          title="Delete Allocation"
          dialogText="This allocation will be deleted. You must add a new stock lot to allocate the correct quantity for the kit line. This cannot be undone."
          onClose={closeDialog}
          dialogOpen={isDialogOpen(
            ALLOCATED_STOCK_LOTS_TABLE_DIALOGS.DELETE_ALLOCATION
          )}
          loading={isLoadingDeleteAllocation}
          confirmButtonText="Delete"
          onConfirm={handleDeleteAllocation}
          error={deleteAllocationError}
          confirmButtonColor={COLOR_OPTIONS.error}
        />
      )}
      {kitRequestLine &&
        isDialogOpen(ALLOCATED_STOCK_LOTS_TABLE_DIALOGS.ADD_STOCK_LOT) && (
          <AddStockLotToKitRequestLineDialog
            kitRequestLine={kitRequestLine}
            onClose={closeDialog}
            dialogOpen={isDialogOpen(
              ALLOCATED_STOCK_LOTS_TABLE_DIALOGS.ADD_STOCK_LOT
            )}
            quantToAllocate={
              kitRequestLine.minimumQuantity
                ? kitRequestLine.minimumQuantity - totalAllocatedQuant
                : undefined
            }
          />
        )}
    </>
  );
};

export default KitRequestAllocatedStockLotsTable;
