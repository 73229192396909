import { ALL_APPS, ALL_VIEW_IDS, ALL_VIEWS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Redirect } from 'react-router6-redirect';
import { Navigate } from 'react-router-dom';

const InventoryParts = Loadable(
  lazy(() => import('views/inventory/InventoryParts'))
);
const InventoryPart = Loadable(
  lazy(() => import('views/inventory/InventoryPart/InventoryPart'))
);
const InventoryStockLots = Loadable(
  lazy(() => import('views/inventory/InventoryStockLots'))
);
const InventoryStockLot = Loadable(
  lazy(() => import('views/inventory/StockLot/InventoryStockLot'))
);
const InventoryAllocations = Loadable(
  lazy(() => import('views/inventory/InventoryAllocations'))
);

export const StockRoutes = [
  {
    path: `${ALL_VIEWS.PART_STOCK.deprecatedPath}`,
    element: <Redirect to={`${ALL_VIEWS.PART_STOCK.path}`} />,
  },
  {
    path: `${ALL_APPS.STOCK.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.STOCK.id,
          }}
        >
          <Navigate to={`${ALL_VIEWS.PART_STOCK.path}`} replace />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PART_STOCK.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.STOCK.id,
            view: ALL_VIEW_IDS.PART_STOCK,
          }}
        >
          <InventoryParts />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PART_STOCK.deprecatedPath}/:partId`,
    element: <Redirect to={`${ALL_VIEWS.PART_STOCK.path}/:partId`} />,
  },
  {
    path: `${ALL_VIEWS.PART_STOCK.path}/:partId`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.STOCK.id,
          view: ALL_VIEW_IDS.PART_STOCK,
        }}
      >
        <InventoryPart />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.STOCK_LOTS.deprecatedPath}`,
    element: <Redirect to={`${ALL_VIEWS.STOCK_LOTS.path}`} />,
  },
  {
    path: `${ALL_VIEWS.STOCK_LOTS.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.STOCK.id,
            view: ALL_VIEW_IDS.STOCK_LOTS,
          }}
        >
          <InventoryStockLots />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.STOCK_LOTS.deprecatedPath}/:stockLotId`,
    element: <Redirect to={`${ALL_VIEWS.STOCK_LOTS.path}/:stockLotId`} />,
  },
  {
    path: `${ALL_VIEWS.STOCK_LOTS.path}/:stockLotId`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.STOCK.id,
          view: ALL_VIEW_IDS.STOCK_LOTS,
        }}
      >
        <InventoryStockLot />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.ALLOCATIONS.deprecatedPath}`,
    element: <Redirect to={`${ALL_VIEWS.ALLOCATIONS.path}`} />,
  },
  {
    path: `${ALL_VIEWS.ALLOCATIONS.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.STOCK.id,
            view: ALL_VIEW_IDS.ALLOCATIONS,
          }}
        >
          <InventoryAllocations />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
];
