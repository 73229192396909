import { FC } from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { Part, lifeCycleStatusFormats, lifecycleStatus } from 'types/part';
import PartInfoWidget from 'ui-component/PartInfoWidget';
import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import { removeNonAlphanumericOrPeriod } from 'utils/functions';
import reactStringReplace from 'react-string-replace';

interface PartRowProps {
  part: Part;
  selected: boolean;
  hideButton?: boolean;
  detailedMode?: boolean;
  searchString?: string;
}

const PartRow: FC<PartRowProps> = ({
  part,
  selected,
  detailedMode,
  hideButton,
  searchString,
}) => {
  const theme = useTheme();

  const colorMap: { [key: string]: string } = {
    warning: theme.palette.warning.main,
    success: theme.palette.success.main,
    error: theme.palette.error.main,
    info: theme.palette.info.main,
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    grey: theme.palette.grey[500],
  };

  const splitSearchString = _.chain(searchString).split(' ').compact().value();

  const searchStringAltMatches = _.filter(part.altMpns || [], (a) =>
    _.some(splitSearchString, (s) =>
      _.includes(
        removeNonAlphanumericOrPeriod(a),
        removeNonAlphanumericOrPeriod(s)
      )
    )
  );

  const searchHighlightString = (input: string) => {
    if (!searchString) return input;
    const escapedSplitSearchString = _.map(splitSearchString, (s) =>
      _.replace(s, /[.*+?^${}()|[\]\\]/g, '\\$&')
    );
    const highlightRegex = _.join(escapedSplitSearchString, '|');
    const replacedString = reactStringReplace(
      input,
      new RegExp(`(${highlightRegex})`, 'i'),
      (match, i) => (
        <span
          key={i}
          style={{ color: theme.palette.success.main, fontWeight: 'bold' }}
        >
          {match}
        </span>
      )
    );
    return replacedString;
  };

  return (
    <>
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        container
        columnSpacing={3}
      >
        <Grid item xs>
          <Grid container display="flex" alignItems="center" gap={3}>
            <PartInfoWidget part={part} searchString={searchString} />
          </Grid>
        </Grid>
        {detailedMode && (
          <Grid item xs>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              {!!part.lifecycleStatus &&
                part.lifecycleStatus !== lifecycleStatus.UNKNOWN && (
                  <Typography
                    color={
                      colorMap[
                        _.get(lifeCycleStatusFormats, [
                          part.lifecycleStatus || '',
                          'color',
                        ]) || 'grey'
                      ]
                    }
                  >
                    {_.get(
                      lifeCycleStatusFormats,
                      [part.lifecycleStatus || '', 'label'],
                      'Unknown'
                    )}
                  </Typography>
                )}
            </Stack>
          </Grid>
        )}
        <Grid item xs="auto">
          <Grid container display="flex" alignItems="center" gap={3}>
            <Grid item xs>
              <Grid
                container
                display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography>
                  {part?.totalAvailability?.buyableStock.toLocaleString(
                    'en-US'
                  )}
                </Typography>
                <Typography variant="subtitle2">buyable</Typography>
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid
                container
                display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography>
                  {part?.totalAvailability?.quotableStock.toLocaleString(
                    'en-US'
                  )}
                </Typography>
                <Typography variant="subtitle2">quotable</Typography>
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid
                container
                display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography>
                  {part?.totalAvailability?.possibleStock.toLocaleString(
                    'en-US'
                  )}
                </Typography>
                <Typography variant="subtitle2">maybe</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!hideButton && (
          <Grid item xs="auto">
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={3}
            >
              <Grid item>
                {/* Swapping for SegmentButton breaks the Autocomplete select functionality when an option is selected. */}
                <Button
                  size="small"
                  variant={selected ? 'contained' : 'outlined'}
                >
                  {selected ? 'Selected' : 'Select'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {searchStringAltMatches.length > 0 &&
          !_.includes(part.mpn, searchString) && (
            <Grid item xs={12}>
              <Typography sx={{ fontStyle: 'italic' }} variant="caption">
                This Part Is Also Known As:{' '}
                {searchHighlightString(searchStringAltMatches.join(', '))}
              </Typography>
            </Grid>
          )}
      </Grid>
    </>
  );
};

export default PartRow;
