import { ALL_APPS } from 'constants/appConstants';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Redirect } from 'react-router6-redirect';
import FullHeightMainCardWrapper from 'layout/MainLayout/FullHeightMainCardWrapper';

const PurchasingPartQuotes = Loadable(
  lazy(() => import('views/purchasing/PurchasingPartQuotes'))
);

export const QuotesRoutes = [
  {
    path: `${ALL_APPS.QUOTES.deprecatedPath}`,
    element: <Redirect to={`${ALL_APPS.QUOTES.path}`} />,
  },
  {
    path: `${ALL_APPS.QUOTES.path}`,
    element: (
      <FullHeightMainCardWrapper>
        <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.QUOTES.id }}>
          <PurchasingPartQuotes />
        </PermissionsRouteWrapper>
      </FullHeightMainCardWrapper>
    ),
  },
];
