import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import { FC, useEffect, useState } from 'react';
import {
  ExtendedDialog,
  DialogTrackingNames,
} from 'ui-component/extended/ExtendedDialog';
import * as Yup from 'yup';
import _ from 'lodash';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { PermissionScope } from 'types/apps';
import { handleErr } from 'utils/functions';
import useSnackbar from 'hooks/useSnackbar';

type BaseUpdateRecordDialogProps = {
  formConstants: {
    [id: string]: {
      id: string;
      label: string;
    };
  };
  validationSchema: Yup.ObjectSchema<any>;
  newRecordDefaults?: object;
  onSave?: (record: object) => void;
  useUpdateMutation: UseMutation<any>;
  preSubmit?: (data: object) => object;
  recordName: string;
  otherProps?: object;
  idProp?: string;
  selectedRecord?: object | null;
  trackingNames?: DialogTrackingNames;
  permissionScope?: PermissionScope;
  submitAsClientV2Api?: boolean;
};

export default function useUpdateRecordDialog(
  props: BaseUpdateRecordDialogProps
) {
  // create dictionary called initialFormValues with keys from formConstants and values from selectedRecord
  const initialFormValues =
    props?.newRecordDefaults ??
    _.mapValues(props.formConstants, (formConstant) =>
      _.get(props.selectedRecord, formConstant.id, null)
    );

  const methods = useForm<FieldValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(props.validationSchema),
    mode: 'all',
  });

  useEffect(() => {
    methods.reset(initialFormValues);
  }, [props?.selectedRecord]);

  return {
    methods,
    control: methods.control,
    errors: methods.formState.errors,
    watch: methods.watch,
    dialogProps: {
      props,
      methods,
    },
    BaseUpdateRecordDialog,
  };
}

const BaseUpdateRecordDialog: FC<{
  open: boolean;
  onClose: () => void;
  dialogProps: {
    props: BaseUpdateRecordDialogProps;
    methods: UseFormReturn;
  };
  confirmClose?: boolean;
}> = ({ children, open, onClose, dialogProps, confirmClose }) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { dispatchSuccessSnackbar } = useSnackbar();
  const [update, { isLoading: isLoadingUpdate }] =
    dialogProps.props.useUpdateMutation();

  const handleClose = () => {
    onClose();
    dialogProps.methods.reset();
    setSubmitError(null);
  };

  const onSubmit = async (data: FieldValues) => {
    try {
      const dataToSubmit = dialogProps.props.preSubmit
        ? dialogProps.props.preSubmit(data)
        : data;
      const payload = {
        ...(dialogProps.props.otherProps || {}),
        ...(dialogProps.props.submitAsClientV2Api
          ? {
              ...dataToSubmit,
              id: _.get(dialogProps.props.selectedRecord, 'id'),
            }
          : { payload: dataToSubmit }),
        ...(dialogProps.props.idProp
          ? {
              [dialogProps.props.idProp]: _.get(
                dialogProps.props.selectedRecord,
                'id',
                null
              ),
            }
          : {}),
      };

      const record = await update(payload).unwrap();
      if (dialogProps.props.onSave) {
        dialogProps.props.onSave(record as object);
      }
      handleClose();
      dispatchSuccessSnackbar(`${dialogProps.props.recordName} Updated`);
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        setSubmitError(errMessage);
      });
    }
  };

  return (
    <ExtendedDialog
      onSubmit={dialogProps.methods.handleSubmit(onSubmit)}
      formSubmitError={submitError}
      submitButtonCopy="Save"
      isSubmitting={isLoadingUpdate}
      confirmClose={confirmClose}
      open={open}
      isForm
      onCloseDialog={handleClose}
      title={`Update ${dialogProps.props.recordName}`}
      permissionScope={dialogProps.props.permissionScope}
      trackingNames={dialogProps.props.trackingNames}
    >
      {children}
    </ExtendedDialog>
  );
};
