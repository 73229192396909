import _ from 'lodash';
import { Stack } from '@mui/material';
import ProgressChip from 'ui-component/extended/Chip/ProgressChip';
import { ChipColor } from 'ui-component/extended/Chip';
import { statusColorCallback } from 'ui-component/DataGrid/GridColTypeDef';
import { statusTextMapper } from 'constants/datagrid';
import { DecoratedKitRequestLine } from 'hooks/useGetDecoratedKitRequestLines';
import { StatusCount } from 'hooks/useGetDecoratedKitRequestLines/utils';

type KitRequestLineStatusChipsProps = {
  kitRequestLine: DecoratedKitRequestLine;
};

export const KitRequestLineStatusChip = ({
  kitRequestLine,
}: KitRequestLineStatusChipsProps) => {
  const statuses = kitRequestLine.stats?.statusCounts as StatusCount[];

  const totalAllocated = _.sumBy(kitRequestLine.allocations, 'quant');
  const totalShipped = _.chain(kitRequestLine.shipmentLines)
    .filter((sl) => !!sl.shipment.shippedAt)
    .sumBy('shippedQuant')
    .value();

  const minimumAdditionalToAllocate = Math.max(
    (kitRequestLine.minimumQuantity || 0) - (totalAllocated + totalShipped),
    0
  );

  const targetAdditionalAllocation = Math.max(
    (kitRequestLine.targetQuantity || 0) -
      (totalAllocated + totalShipped) -
      minimumAdditionalToAllocate,
    0
  );

  const statusLabels = [
    ..._.chain(statuses)
      .map('status')
      .map((s) => statusTextMapper[s as keyof typeof statusTextMapper])
      .uniq()
      .value(),
    ...(minimumAdditionalToAllocate > 0 ? ['Unavailable'] : []),
  ];

  return (
    <Stack direction="row" spacing={1}>
      <ProgressChip
        sections={[
          ...statuses.map((s) => ({
            chipColor: statusColorCallback(statusTextMapper[s.status]),
            amount: s.quant,
          })),
          {
            chipColor: ChipColor.error,
            amount: minimumAdditionalToAllocate,
          },
          {
            chipColor: ChipColor.warning,
            amount: targetAdditionalAllocation,
          },
        ]}
        label={statusLabels.join('/')}
        style={{ textTransform: 'uppercase' }}
      />
    </Stack>
  );
};
