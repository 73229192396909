import {
  useGetLabelTemplatesQuery,
  useGetStockLocationQuery,
} from 'store/slices/inventory';
import { useGetUserQuery } from 'store/slices/user';
import { useSelector } from 'store';
import { LabelTemplate, LabelFormat } from 'types/inventory';
import _ from 'lodash';
import useLocalStorage from 'hooks/useLocalStorage';
import {
  mobileInventoryStorageName,
  mobileInventoryDefaults,
} from 'types/mobileInventorySettings';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { orgFromUserOrgId } from 'utils/userPermissions';

const LabelFormatConfigs = {
  [LabelFormat.StockLotDefault]: {
    useShipTo: false,
    useRecordOrg: true,
  },
  [LabelFormat.StockLocationDefault]: {
    useShipTo: false,
    useRecordOrg: false,
  },
  [LabelFormat.StockLocationVertical]: {
    useShipTo: false,
    useRecordOrg: false,
  },
  [LabelFormat.StockLocationShipment]: {
    useShipTo: false,
    useRecordOrg: false,
  },
  [LabelFormat.StockLocationCustomer]: {
    useShipTo: false,
    useRecordOrg: false,
  },
  [LabelFormat.StockLocationKit]: {
    useShipTo: false,
    useRecordOrg: false,
  },
  [LabelFormat.ShipmentDefault]: {
    useShipTo: true,
    useRecordOrg: false,
  },
  [LabelFormat.ShipmentLineDefault]: {
    useShipTo: true,
    useRecordOrg: false,
  },
  [LabelFormat.UserDefault]: {
    useShipTo: false,
    useRecordOrg: true,
  },
  [LabelFormat.KitRequestDefault]: {
    useShipTo: true,
    useRecordOrg: false,
  },
};

export function useDefaultLabelTemplates({
  shipToId,
  recordOrgId,
}: {
  shipToId?: string | null;
  recordOrgId?: string | null;
}) {
  const { data: user } = useGetUserQuery();
  const { activeOrgId } = useSelector((state) => state.org);
  const [settings] = useLocalStorage(
    mobileInventoryStorageName,
    mobileInventoryDefaults
  );

  const activeCreatedFromOrg = _.chain(user?.orgs || [])
    .find({ id: activeOrgId || '' })
    .get('createdFrom')
    .value();

  const { data: stockLocation, isSuccess: isGetStockLocationSuccess } =
    useGetStockLocationQuery(settings.facility || skipToken);

  const { data: shipToLocation } = useGetStockLocationQuery(
    shipToId || skipToken
  );

  const { data: labelTemplates = [], isFetching: isFetchingLabelTemplates } =
    useGetLabelTemplatesQuery({
      facilityId: settings.facility,
      shipToId,
      recordOrgId,
    });

  const activeFacilityOrgId =
    isGetStockLocationSuccess && stockLocation ? stockLocation.org : null;

  const shipToLocationOrg = orgFromUserOrgId(user, shipToLocation?.org);
  const shipToLocationParentOrgId = shipToLocationOrg?.createdFrom;

  const recordOrg = orgFromUserOrgId(user, recordOrgId);
  const recordParentOrgId = recordOrg?.createdFrom;

  const findMatchingTemplate = (format: LabelFormat) => {
    const config = _.get(LabelFormatConfigs, format as string);
    if (!config) return null;
    const matchingTemplates = _.filter(labelTemplates, { labelFormat: format });
    if (matchingTemplates.length === 0) return null;

    if (shipToId) {
      if (
        _.find(matchingTemplates, (lt) => lt.org?.id === shipToLocationOrg?.id)
      ) {
        return _.find(
          matchingTemplates,
          (lt) => lt.org?.id === shipToLocationOrg?.id
        );
      }
      if (
        _.find(
          matchingTemplates,
          (lt) => lt.org?.id === shipToLocationParentOrgId
        )
      ) {
        return _.find(
          matchingTemplates,
          (lt) => lt.org?.id === shipToLocationParentOrgId
        );
      }
      if (
        _.find(matchingTemplates, (lt) => lt.org?.id === activeFacilityOrgId)
      ) {
        return _.find(
          matchingTemplates,
          (lt) => lt.org?.id === activeFacilityOrgId
        );
      }
    }

    if (recordOrgId) {
      if (_.find(matchingTemplates, (lt) => lt.org?.id === recordOrgId)) {
        return _.find(matchingTemplates, (lt) => lt.org?.id === recordOrgId);
      }
      if (_.find(matchingTemplates, (lt) => lt.org?.id === recordParentOrgId)) {
        return _.find(
          matchingTemplates,
          (lt) => lt.org?.id === recordParentOrgId
        );
      }
      if (
        _.find(matchingTemplates, (lt) => lt.org?.id === activeFacilityOrgId)
      ) {
        return _.find(
          matchingTemplates,
          (lt) => lt.org?.id === activeFacilityOrgId
        );
      }
    }

    if (_.find(matchingTemplates, (lt) => lt.org?.id === activeOrgId)) {
      return _.find(matchingTemplates, (lt) => lt.org?.id === activeOrgId);
    }

    if (
      _.find(matchingTemplates, (lt) => lt.org?.id === activeCreatedFromOrg)
    ) {
      return _.find(
        matchingTemplates,
        (lt) => lt.org?.id === activeCreatedFromOrg
      );
    }

    return _.find(matchingTemplates, (lt) => !lt.org);
  };

  const templates: { [key in LabelFormat]?: null | undefined | LabelTemplate } =
    _.chain(Object.keys(LabelFormatConfigs))
      .map((k) => [k, findMatchingTemplate(k as LabelFormat)])
      .fromPairs()
      .value();

  const getLabelTemplate = (format: LabelFormat) =>
    _.find(templates, { labelFormat: format });

  return {
    fetching: isFetchingLabelTemplates,
    templates,
    getLabelTemplate,
  };
}
