import { createContext, JSX, useContext } from 'react';
import { KitRequestSchema } from 'types/kitting';
import useServerSideQuery from 'hooks/useServerSideQuery';
import { useGetKitRequestsQuery } from 'store/slices/clientV2/kitRequests';
import { ALL, Lite } from 'types/api';
import {
  DecoratedKitRequestLine,
  useGetDecoratedKitRequestLines,
} from 'hooks/useGetDecoratedKitRequestLines';
import { CalculateKitRequestStatusReturn } from 'hooks/useGetDecoratedKitRequestLines/utils';
import { StockLotQuerySchema } from 'types/inventory';

export type KitRequestSubtype = Lite<
  KitRequestSchema,
  | 'approvedBy'
  | 'team'
  | 'productionRun'
  | 'org'
  | 'sourceLocations'
  | 'shipTo'
  | 'customProperties'
>;

type KitRequestContextType = {
  kitRequest: KitRequestSubtype | null;
  isLoadingKitRequest: boolean;
  stockLots?: Lite<StockLotQuerySchema>[];
  decoratedKitRequestLines?: DecoratedKitRequestLine[];
  kitRequestStatuses?: CalculateKitRequestStatusReturn;
  isLoadingKitRequestLines: boolean;
};

const KitRequestContext = createContext({} as KitRequestContextType);

export const useKitRequestContext = () => {
  const context = useContext(KitRequestContext);
  if (context === undefined) {
    throw new Error(
      'useKitRequestContext must be used within a KitRequestContext'
    );
  }
  return context;
};

export const KitRequestProvider = ({
  kitRequestId,
  children,
}: {
  kitRequestId: KitRequestSchema['id'] | undefined;
  children: JSX.Element;
}) => {
  const {
    data: { data: [kitRequest] } = { data: [null] },
    isLoading: isLoadingKitRequest,
  } = useServerSideQuery<KitRequestSchema, KitRequestSubtype>(
    useGetKitRequestsQuery,
    {
      filters: [
        {
          field: 'id',
          operator: 'is',
          value: kitRequestId,
        },
      ],
      schema: [
        ALL,
        {
          approvedBy: [],
          team: [],
          productionRun: [],
          org: ['id', 'name'],
          sourceLocations: [],
          shipTo: [],
          customProperties: [],
        },
      ],
    }
  );

  const {
    stockLots,
    decoratedKitRequestLines,
    kitRequestStatuses,
    isLoadingKitRequestLines,
  } = useGetDecoratedKitRequestLines({
    selectedKitRequestIds: kitRequestId ? [kitRequestId] : [],
  });

  return (
    <KitRequestContext.Provider
      value={{
        kitRequest,
        isLoadingKitRequest,
        stockLots,
        decoratedKitRequestLines,
        kitRequestStatuses,
        isLoadingKitRequestLines,
      }}
    >
      {children}
    </KitRequestContext.Provider>
  );
};
