import {
  useState,
  useMemo,
  useEffect,
  SyntheticEvent,
  ChangeEvent,
} from 'react';
import _ from 'lodash';
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  CircularProgress,
  FormHelperText,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { ListResponse, QueryParams } from 'types/api';
import { GenericObject } from 'types';
import { useController } from 'react-hook-form';
import { QueryDefinition, skipToken } from '@reduxjs/toolkit/dist/query/react';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';

type FreeSoloProps = {
  freeSolo: true;
  matchBy: string;
};

type NotFreeSoloProps = {
  freeSolo?: false;
  matchBy?: never;
};

export const FREE_SOLO_OPTION = 'FREE_SOLO_OPTION';

export type HookQuerySearchSelectPropsDisableRequire<T extends GenericObject> =
  {
    disableRequiredProps: true;
    name: string;
    disabled?: boolean;
    mb?: number;
    schema: QueryParams<T>['schema'];
    searchSchema?: QueryParams<T>['schema'];
    filters?: QueryParams<T>['filters'];
    excludes?: QueryParams<T>['excludes'];
    useGetQuery: UseQuery<
      QueryDefinition<
        QueryParams<T>,
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
        string,
        ListResponse<T>
      >
    >;
    getOptionLabel: (option: T) => string;
    handleChange?: (option: T) => void;
    /**
     * Optional prop to override default onChange.
     * Called with new value on change event.
     * Receives new value (T or T[] if `multiple` is true).
     * */
    overrideOnChange?: (value: T | T[]) => void;
    multiple?: boolean;
    renderOption?: AutocompleteProps<
      T,
      boolean,
      boolean,
      boolean
    >['renderOption'];
    contextuallyCreatedOption?: T;
    /**
     * Optional prop to get default value from option.
     * use this if the default value being passed into useHook is something other than the option's id.
     * example:
     * useForm({
     *  defaultValues: {
     *   [formConstants.supplier.id]: {
     *      id: '123',
     *      name: 'test'
     *    },
     *  }
     * });
     * then pass in getDefaultValue={(option) => option.id}
     */
    getDefaultValue?: (option: any) => string;
    disableClearable?: boolean;
    autoSelectFirst?: boolean;
  } & (FreeSoloProps | NotFreeSoloProps) &
    TextFieldProps;

export type BaseHookQuerySearchSelectProps<T extends GenericObject> = {
  name: string;
  disabled?: boolean;
  mb?: number;
  schema: QueryParams<T>['schema'];
  searchSchema?: QueryParams<T>['schema'];
  filters?: QueryParams<T>['filters'];
  excludes?: QueryParams<T>['excludes'];
  useGetQuery: UseQuery<
    QueryDefinition<
      QueryParams<T>,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      string,
      ListResponse<T>
    >
  >;
  getOptionLabel: (option: T) => string;
  handleChange?: (option: T) => void;
  /**
   * Optional prop to override default onChange.
   * Called with new value on change event.
   * Receives new value (T or T[] if `multiple` is true).
   * */
  overrideOnChange?: (value: T | T[]) => void;
  multiple?: boolean;
  renderOption?: AutocompleteProps<
    T,
    boolean,
    boolean,
    boolean
  >['renderOption'];
  contextuallyCreatedOption?: T;
  /**
   * Optional prop to get default value from option.
   * use this if the default value being passed into useHook is something other than the option's id.
   * example:
   * useForm({
   *  defaultValues: {
   *   [formConstants.supplier.id]: {
   *      id: '123',
   *      name: 'test'
   *    },
   *  }
   * });
   * then pass in getDefaultValue={(option) => option.id}
   */
  getDefaultValue?: (option: any) => string;
  disableClearable?: boolean;
  autoSelectFirst?: boolean;
} & (FreeSoloProps | NotFreeSoloProps) &
  TextFieldProps;

export type HookQuerySearchSelectProps<T extends GenericObject> =
  HookFormComponentProps &
    (
      | BaseHookQuerySearchSelectProps<T>
      | HookQuerySearchSelectPropsDisableRequire<T>
    );

export const HookQuerySearchSelect = <T extends GenericObject>({
  errors,
  name,
  label,
  control,
  useGetQuery,
  filters = [],
  excludes = [],
  schema,
  searchSchema,
  multiple = false,
  disabled,
  getOptionLabel,
  sx,
  freeSolo = false,
  matchBy,
  overrideOnChange,
  renderOption,
  contextuallyCreatedOption,
  getDefaultValue,
  fullWidth = false,
  mb = 2,
  disableClearable = false,
  autoSelectFirst = false,
  handleChange,
}: HookQuerySearchSelectProps<T>) => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [cache, setCache] = useState<Record<string, T>>({});
  const [freeSoloOption, setFreeSoloOption] = useState<T | null>(null);

  const {
    field: { ref, onChange, value: preprocessedValue, onBlur },
    fieldState: { isDirty },
  } = useController({
    name,
    control,
  });

  const value = getDefaultValue
    ? Array.isArray(preprocessedValue)
      ? preprocessedValue.map((val) => getDefaultValue(val))
      : getDefaultValue(preprocessedValue)
    : preprocessedValue;

  useEffect(() => {
    if (value !== undefined) {
      const newCache = { ...cache };
      const selectedOptions = multiple
        ? value?.map((id: string) =>
            uniqueOptions.find((option) => option?.id === id)
          )
        : [uniqueOptions.find((option) => option?.id === value)];
      selectedOptions?.forEach((option: T | undefined) => {
        if (option) {
          newCache[option.id as string] = option;
        }
      });
      setCache(newCache);
    }
  }, [value]);

  const initialFilters: QueryParams<T>['filters'] = useMemo(
    () =>
      isDirty
        ? filters
        : value &&
          (!Array.isArray(value) || (Array.isArray(value) && value.length > 0))
        ? [
            {
              field: 'id',
              operator: 'isAnyOf',
              value: Array.isArray(value) ? value : [value],
            },
            ...filters,
          ]
        : filters || [],
    [value, isDirty]
  );

  const {
    data: { data: initialValueOptions = [] } = { data: [] },
    isFetching: isFetchingInitialValueOptions,
    isSuccess: isInitialValueOptionsSuccess,
  } = useGetQuery(
    initialFilters.length > 0
      ? {
          pageSize: 100,
          filters: initialFilters,
          excludes,
          schema: ['id' as keyof T, ...(Array.isArray(schema) ? schema : [])],
        }
      : skipToken
  );

  const {
    data: { data: options = [] } = { data: [] },
    isFetching: isFetchingOptions,
  } = useGetQuery({
    filters,
    excludes,
    schema: ['id' as keyof T, ...(Array.isArray(schema) ? schema : [])],
    searchSchema: searchSchema || schema,
    search: searchQuery,
  });

  const uniqueOptions = useMemo(() => {
    const combinedOptions = [
      ...options,
      ...initialValueOptions,
      ...(contextuallyCreatedOption ? [contextuallyCreatedOption] : []),
      ...(freeSoloOption ? [freeSoloOption] : []),
    ];
    const sortedOptions = _.orderBy(combinedOptions, [
      (option) => {
        const optionValue = getOptionLabel(option)?.toLowerCase();
        const searchLower = searchQuery?.toLowerCase();
        return !optionValue.startsWith(searchLower);
      },
    ]);
    return Array.from(new Set(sortedOptions.map((option) => option.id))).map(
      (id) => sortedOptions.find((option) => option.id === id)
    );
  }, [
    options,
    initialValueOptions,
    contextuallyCreatedOption,
    searchQuery,
    getOptionLabel,
  ]);

  // NOTE: make sure selectedValues, is only updating when value of Autocomplete changes
  // changes to selectedValues will cause Autocomplete to re-render and if textfield is focused
  // it will cause the textfield to reset its value to the value
  const selectedValues = useMemo(() => {
    if (multiple) {
      return value
        ? value
            .map(
              (id: string) =>
                cache[id] ||
                initialValueOptions.find((option: T) => option.id === id)
            )
            .filter(Boolean)
        : [];
    }
    return value
      ? cache[value] ||
          initialValueOptions.find((option: T) => option.id === value) ||
          (overrideOnChange ? overrideOnChange(value) : null)
      : null;
  }, [multiple, value, isInitialValueOptionsSuccess, cache, overrideOnChange]);

  // handle autoSelectFirst
  useEffect(() => {
    if (autoSelectFirst && uniqueOptions.length > 0 && !selectedValues) {
      onChange(uniqueOptions[0]?.id);
    }
  }, [autoSelectFirst, uniqueOptions, selectedValues]);

  return (
    <Autocomplete
      sx={sx}
      open={open}
      value={selectedValues}
      multiple={multiple}
      filterOptions={(originalOptions: T[], params) => {
        if (freeSolo && matchBy) {
          const isOptionExists = originalOptions.some(
            (option) => option[matchBy] === params.inputValue
          );
          if (!isOptionExists) {
            originalOptions = [
              ...originalOptions,
              {
                id: FREE_SOLO_OPTION,
                [matchBy]: params.inputValue,
              } as unknown as T,
            ];
          }
        }
        return originalOptions;
      }}
      freeSolo={freeSolo}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={
        ((__, newValue: T | T[]) => {
          if (handleChange) {
            handleChange(newValue as T);
          } else if (newValue !== null && overrideOnChange) {
            if ((newValue as T)?.id === FREE_SOLO_OPTION) {
              setFreeSoloOption(newValue as T);
            }
            onChange(overrideOnChange(newValue));
          } else if (multiple) {
            onChange((newValue as T[]).map((valueEl) => valueEl.id));
          } else if (newValue === null) {
            onChange(null);
          } else {
            onChange((newValue as T)?.id);
          }
        }) as (event: SyntheticEvent, newValue: unknown) => void
      }
      isOptionEqualToValue={
        ((option: T, valueToCompare: T) =>
          valueToCompare?.id === option.id) as (
          option: unknown,
          valueToCompare: unknown
        ) => boolean
      }
      getOptionLabel={(option: T) => getOptionLabel(option)}
      options={uniqueOptions}
      loading={isFetchingOptions || isFetchingInitialValueOptions}
      noOptionsText={`No ${label} found`}
      renderInput={(params) => (
        <Box sx={{ mb }}>
          <TextField
            inputRef={ref}
            {...params}
            onBlur={onBlur}
            onChange={(e) => {
              if (params.inputProps.onChange)
                params.inputProps.onChange(e as ChangeEvent<HTMLInputElement>);
              setSearchQuery(e.target.value);
            }}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetchingOptions ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
          {errors?.[name]?.message && (
            <FormHelperText error id={`${name}Error`}>
              {errors?.[name]?.message}
            </FormHelperText>
          )}
        </Box>
      )}
      renderOption={renderOption}
      disabled={disabled}
      fullWidth={fullWidth}
      disableClearable={disableClearable}
    />
  );
};
