import { ALL_APPS, ALL_VIEW_IDS, ALL_VIEWS } from 'constants/appConstants';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import FullHeightMainCardWrapper from 'layout/MainLayout/FullHeightMainCardWrapper';

const Suppliers = Loadable(lazy(() => import('views/suppliers/Suppliers')));
const PurchaseRules = Loadable(
  lazy(() => import('views/suppliers/PurchaseRules'))
);
const Supplier = Loadable(lazy(() => import('views/suppliers/Supplier')));

export const SuppliersRoutes = [
  {
    path: `${ALL_APPS.SUPPLIERS.path}`,
    element: (
      <FullHeightMainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.SUPPLIERS.id }}
        >
          <Navigate
            to={`${ALL_APPS.SUPPLIERS.views?.SUPPLIERS?.path}`}
            replace
          />
        </PermissionsRouteWrapper>
      </FullHeightMainCardWrapper>
    ),
  },
  {
    path: `${ALL_APPS.SUPPLIERS.views?.SUPPLIERS?.path}`,
    element: (
      <FullHeightMainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.SUPPLIERS.id }}
        >
          <Suppliers />
        </PermissionsRouteWrapper>
      </FullHeightMainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.SUPPLIERS.path}/:supplierId`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.SUPPLIERS.id,
        }}
      >
        <Supplier />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_APPS.SUPPLIERS.views?.PURCHASE_RULES?.path}`,
    element: (
      <FullHeightMainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.SUPPLIERS.id,
            view: ALL_VIEW_IDS.PURCHASE_RULES,
          }}
        >
          <PurchaseRules />
        </PermissionsRouteWrapper>
      </FullHeightMainCardWrapper>
    ),
  },
];
