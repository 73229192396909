import { useCallback, useState } from 'react';
import _ from 'lodash';
import { FieldErrors } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material';
import { Classification } from 'types/part';
import { useGetClassificationsAutocompleteQuery } from 'store/slices/part';

type ClassificationAutocompleteProps = {
  handleSetValue: (option: Classification | null) => void;
  errors: FieldErrors;
  name: string;
  label: string;
};

const ClassificationAutocomplete = ({
  handleSetValue,
  errors,
  name,
  label,
}: ClassificationAutocompleteProps) => {
  const [debouncedInput, setDebouncedInput] = useState('');
  const [value, setValue] = useState<Classification>({
    label: '',
    id: '',
  } as Classification);

  const { data = [], isFetching } = useGetClassificationsAutocompleteQuery(
    debouncedInput,
    {
      skip: debouncedInput.length === 0,
    }
  );

  const options = [...data];
  !_.find(data, (o) => o.label === debouncedInput) &&
    options.push({ label: debouncedInput, id: 'custom' } as Classification);

  const handleDebounce = useCallback(
    _.debounce((nextValue) => setDebouncedInput(nextValue), 1000),
    []
  );

  return (
    <Autocomplete
      options={options}
      onInputChange={(e, option) => {
        handleDebounce(option);
      }}
      onChange={(__, option) => {
        setValue(option as Classification);
        handleSetValue(option);
      }}
      autoComplete
      autoSelect
      filterSelectedOptions
      value={value}
      getOptionLabel={(option) => option?.label}
      loading={isFetching}
      isOptionEqualToValue={(option) => option.id === value.id}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={Math.random()}>
          <MenuItem
            selected={selected}
            sx={{ fontStyle: option?.id === 'custom' ? 'italic' : 'normal' }}
          >
            {option?.label}
          </MenuItem>
        </li>
      )}
      renderInput={(params) => (
        <Box sx={{ mb: 2 }}>
          <TextField {...params} label={label} fullWidth />
          {errors?.[name]?.message && (
            <FormHelperText error id={`${name}Error`}>
              {errors?.[name]?.message}
            </FormHelperText>
          )}
        </Box>
      )}
    />
  );
};

export default ClassificationAutocomplete;
