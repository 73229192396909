import { PermissionScope } from 'types/apps';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import SegmentInlineFieldButton from 'ui-component/Segment/SegmentInlineFieldButton';
import * as Yup from 'yup';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { handleErr } from 'utils/functions';
import { IconX } from '@tabler/icons-react';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import _ from 'lodash';
import useSnackbar from 'hooks/useSnackbar';
import { Grid } from '@mui/material';

export const EditInlineSx = {
  my: 0,
  marginTop: '0px',
  marginBottom: '0px',
  height: '42px',
  '.MuiOutlinedInput-notchedOutline': {
    borderBottomRightRadius: '0px',
    borderTopRightRadius: '0px',
  },
  '& .MuiOutlinedInput-root': {
    height: '42px',
  },
  '& .MuiInputLabel-root': {
    lineHeight: '1.75em',
  },
  '& .MuiAutocomplete-inputRoot': {
    height: 'auto',
  },
  '& .MuiChip-root': {
    height: 'unset',
  },
  '& .MuiAutocomplete-input': {
    padding: '2px !important',
  },
};

export const EditInlineBoxSx = {
  my: 0,
  height: '42px',
  marginBottom: '0px',
};

export const EditInlineButtonGroupSx = {
  height: '42px',
  marginBottom: '0px',
};

type EditInlineTrackingNamesType = {
  cancel?: string;
  save?: string;
};

// TODO: There's likely a way to make this enforce more specific typing
//  by passing in the type that's being modified, a la ServerSideDatagrid
// But for now this works

interface BaseEditInlineProps {
  name: string;
  validation?: Yup.SchemaOf<unknown, object>;
  value?: unknown;
  /**
   * This should be a single `HookFormComponent`
   */
  children: React.ReactElement;
  permissionScope?: PermissionScope;
  // the `any` here seems to be the type used in the RTK Query type definitions
  useMutation: UseMutation<any>;
  id: string;
  closeEditing?: () => void;
  onSave?: () => void;
  /**
   * Optional pre-processing function to be applied to the property before submitting
   */
  // TODO: This probably isn't the best way to handle this typing but it does enforce things better than using `any`
  preSubmit?: (data: string | null) => string | null | unknown;
  idProp?: string;
  otherProps?: object;
  trackingNames?: EditInlineTrackingNamesType;
  submitAsClientV2Api?: boolean;
}

type EditInlineProps = BaseEditInlineProps &
  (
    | {
        hideLabel?: true;
        label?: string;
      }
    | {
        hideLabel?: false;
        label: string;
      }
  );

const EditInline: FC<EditInlineProps> = ({
  name,
  label,
  hideLabel,
  validation,
  value,
  children,
  permissionScope,
  useMutation,
  id,
  closeEditing,
  onSave,
  preSubmit,
  idProp = 'id',
  otherProps = {},
  trackingNames,
  submitAsClientV2Api,
}) => {
  const { dispatchSuccessSnackbar, dispatchErrorSnackbar } = useSnackbar();

  const [mutate, { isLoading }] = useMutation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      [name]: value || '',
    },
    ...(validation
      ? {
          resolver: yupResolver(
            Yup.object().shape({
              [name]: validation,
            })
          ),
        }
      : {}),
  });

  useEffect(() => {
    reset({
      [name]: value || '',
    });
  }, [value]);

  const onSubmit = async (data: FieldValues) => {
    try {
      const property = _.get(data, name);
      if (mutate) {
        let payload;
        if (preSubmit) {
          const preSubmitResult = preSubmit(property);
          payload = _.isObject(preSubmitResult)
            ? { ...preSubmitResult }
            : { [name]: preSubmitResult };
        } else {
          payload = { [name]: property };
        }
        const mutatePayload = submitAsClientV2Api
          ? { ...otherProps, ...payload, id }
          : { ...otherProps, payload, [idProp]: id };
        await mutate(mutatePayload).unwrap();
        dispatchSuccessSnackbar(`${label ?? 'Record'} updated`);
        onSave && onSave();
      }
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        dispatchErrorSnackbar(`Failed to update ${label}: ${errMessage}`);
      });
    }
    closeEditing && closeEditing();
  };

  const clonedChild = React.cloneElement(children, {
    control,
    name,
    ...(hideLabel ? {} : { label }),
    errors,
    value,
    defaultValue: value,
    setValue,
    size: 'small',
    sx: EditInlineSx,
    boxSx: EditInlineBoxSx,
    buttonGroupSx: EditInlineButtonGroupSx,
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ marginTop: '10px' }}
          container
        >
          <Grid item xs>
            {clonedChild}
          </Grid>
          <Grid item xs="auto">
            <SegmentInlineFieldButton
              loading={isLoading}
              type="submit"
              color="primary"
              permissionScope={permissionScope}
              trackingName={trackingNames?.save}
            />
          </Grid>
          {closeEditing && (
            <Grid item xs="auto">
              <SegmentIconButton
                color="error"
                onClick={closeEditing}
                size="small"
                permissionScope={permissionScope}
                trackingName={trackingNames?.cancel}
              >
                <IconX stroke="1.5px" width={20} height={20} />
              </SegmentIconButton>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
};

export default EditInline;
