import { Box, Grid } from '@mui/material';
import { Tag } from 'types/clientV2/tag';
import Chip from 'ui-component/extended/Chip';

const Tags = ({ tags }: { tags?: Tag[] }) => (
  <Box>
    <Grid
      container
      spacing={1}
      justifyContent="flex-start"
      sx={{ overflow: 'hidden' }}
    >
      {(tags || [])
        .filter((tag) => tag.active)
        .map((tag) => (
          <Grid item key={tag.id} xs="auto">
            <Chip
              label={tag.name}
              title={tag.name}
              chipcolor={tag.color}
              type="tag"
            />
          </Grid>
        ))}
    </Grid>
  </Box>
);

export default Tags;
