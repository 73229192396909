import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import CopyClipboard from 'ui-component/extended/CopyClipboard';
import InlineGoToButton from 'ui-component/shared/InlineGoToButton';

export const RenderWithCopyButton = (
  text: ReactElement | string,
  copyString: string,
  copyLabel: string,
  link?: string,
  linkTooltip?: string
) => {
  if (text) {
    return (
      <Typography>
        {text}
        <CopyClipboard input={copyString} label={copyLabel} />
        {link && (
          <InlineGoToButton link={link} tooltip={linkTooltip || copyLabel} />
        )}
      </Typography>
    );
  }
  return '-';
};
