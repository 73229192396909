import useSnackbar from 'hooks/useSnackbar';
import { useState } from 'react';
import {
  useCreateAllocationsMutation,
  useDeleteAllocationsMutation,
  useUpdateAllocationsMutation,
} from 'store/slices/clientV2/allocations';
import { ALL_APP_IDS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { useLockKitRequestLinesMutation } from 'store/slices/clientV2/kitRequests';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  createKitRequestLineFormConstants,
  createKitRequestLineStockLotValidation,
} from 'views/kitting/Kit/components/constants';
import { handleErr } from 'utils/functions';
import { DecoratedKitRequestLine } from 'hooks/useGetDecoratedKitRequestLines';
import CreateUpdateKitRequestFromStockLot from 'views/kitting/Kit/components/CreateUpdateKitRequestLineForms/CreateUpdateKitRequestFromStockLot';
import * as Yup from 'yup';
import _ from 'lodash';

type AddStockLotToKitRequestLineDialogProps = {
  dialogOpen: boolean;
  onClose: () => void;
  kitRequestLine: DecoratedKitRequestLine;
  quantToAllocate?: number;
};

const AddStockLotToKitRequestLineDialog = ({
  dialogOpen,
  onClose,
  kitRequestLine,
  quantToAllocate,
}: AddStockLotToKitRequestLineDialogProps) => {
  const { dispatchSuccessSnackbar } = useSnackbar();

  const [submitError, setSubmitError] = useState<string | null>(null);

  const [createAllocation, { isLoading: isLoadingCreateAllocation }] =
    useCreateAllocationsMutation();
  const [lockKitRequestLines, { isLoading: isLoadingLockKitRequestLines }] =
    useLockKitRequestLinesMutation();
  const [updateAllocations, { isLoading: isLoadingUpdateAllocations }] =
    useUpdateAllocationsMutation();
  const [deleteAllocation, { isLoading: isLoadingDeleteAllocation }] =
    useDeleteAllocationsMutation();

  const formDefaults = {
    [createKitRequestLineFormConstants.stockLot.id]: '',
    [createKitRequestLineFormConstants.targetQuantityStockLot.id]:
      quantToAllocate ?? '',
  };

  const methods = useForm<FieldValues>({
    defaultValues: formDefaults,
    resolver: yupResolver(
      Yup.object().shape(createKitRequestLineStockLotValidation)
    ),
    mode: 'all',
  });

  const { handleSubmit, reset } = methods;

  const handleClose = () => {
    reset();
    setSubmitError(null);
    onClose();
  };

  const handleUpdateExistingAllocation = async ({
    existingAllocationsForStockLot,
    data,
  }: {
    existingAllocationsForStockLot: DecoratedKitRequestLine['allocations'];
    data: FieldValues;
  }) => {
    const totalQuant =
      existingAllocationsForStockLot.reduce(
        (sum, allocation) => sum + allocation.quant,
        0
      ) + data[createKitRequestLineFormConstants.targetQuantityStockLot.id];

    const sortedAllocations = _.sortBy(
      existingAllocationsForStockLot,
      'createdAt'
    );
    const allocationToKeep = sortedAllocations[0];
    const allocationsToDelete = sortedAllocations.slice(1);

    try {
      deleteAllocation({
        ids: allocationsToDelete.map((allocation) => allocation.id),
      }).unwrap();
      await updateAllocations({
        id: allocationToKeep.id,
        kitRequestLine: kitRequestLine.id,
        quant: totalQuant,
      }).unwrap();
      if (!kitRequestLine.locked) {
        await lockKitRequestLines({ ids: [kitRequestLine.id] }).unwrap();
      }
      dispatchSuccessSnackbar('Stock lot successfully allocated');
      handleClose();
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        setSubmitError(errMessage);
      });
    }
  };

  const handleCreateAllocation = async (data: FieldValues) => {
    try {
      await createAllocation({
        kitRequestLine: kitRequestLine.id,
        quant:
          data[createKitRequestLineFormConstants.targetQuantityStockLot.id],
        stockLot: data[createKitRequestLineFormConstants.stockLot.id].id,
      }).unwrap();
      if (!kitRequestLine.locked) {
        await lockKitRequestLines({ ids: [kitRequestLine.id] }).unwrap();
      }
      dispatchSuccessSnackbar('Stock Lot successfully added to Kit Line');
      handleClose();
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        setSubmitError(errMessage);
      });
    }
  };

  const handleSubmitForm = async (data: FieldValues) => {
    const selectedStockLot =
      data[createKitRequestLineFormConstants.stockLot.id];
    const existingAllocationsForStockLot = kitRequestLine.allocations.filter(
      (allocation) => allocation.stockLot.id === selectedStockLot.id
    );

    if (existingAllocationsForStockLot.length > 0) {
      await handleUpdateExistingAllocation({
        existingAllocationsForStockLot,
        data,
      });
    } else {
      await handleCreateAllocation(data);
    }
  };

  return (
    <ExtendedDialog
      onSubmit={handleSubmit(handleSubmitForm)}
      formSubmitError={submitError}
      submitButtonCopy="Save"
      isSubmitting={
        isLoadingCreateAllocation ||
        isLoadingLockKitRequestLines ||
        isLoadingUpdateAllocations ||
        isLoadingDeleteAllocation
      }
      open={dialogOpen}
      isForm
      onCloseDialog={handleClose}
      title="Update Kit Line"
      permissionScope={{
        app: ALL_APP_IDS.KITTING,
        accessLevel: appPermissionAccessLevels.edit,
      }}
    >
      <FormProvider {...methods}>
        <CreateUpdateKitRequestFromStockLot
          isCreate={false}
          kitRequestLine={kitRequestLine}
          showStockLotSelect
          calculateAvailableQuantWithKitRequestLine={false}
        />
      </FormProvider>
    </ExtendedDialog>
  );
};

export default AddStockLotToKitRequestLineDialog;
