export const DASHBOARD_ROUTE = '/dashboard';
export const ERROR_ROUTE = '*';

// Mobile inventory routes
export const MOBILE_INVENTORY_ROUTES = {
  DASHBOARD: '/mobileinventory/dashboard',
  TRANSFER: '/mobileinventory/transfer',
  COUNTER: '/mobileinventory/counter',
  SETTINGS: '/mobileinventory/settings',
  PICKING: '/mobileinventory/picking',
  HANDLING: '/mobileinventory/handling',
  RECEIVE: '/mobileinventory/receive',
  SHIP: '/mobileinventory/ship',
  STOCK_LOTS: '/mobileinventory/stock-lots',
  STOCK_LOCATIONS: '/mobileinventory/stock-locations',
  ERROR: '*',
};

export const AUTH_ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT: '/forgot',
  AUTH_FAIL: '/auth-fail',
  // This is for setting password from an authenticated email route,
  // changing password when authenticated happens in another UX,
  // and resetting password when unauthenticated happens in another route
  RESET: '/set-password',
};

// NOTE: Should we break these into app related constants.ts files?

// Parts Constants
export const PARTS_TABS = {
  PURCHASING: 'purchasing',
  QUOTES: 'quotes',
  PURCHASE_REQUESTS: 'purchase_requests',
  PURCHASE_ORDERS: 'purchase_orders',
  ORDERS: 'orders',
  WATCHLIST_SUBSCRIPTIONS: 'watchlist-subscriptions',
  BOMS: 'boms',
  INVENTORY: 'inventory',
  ALTERNATIVES: 'alternatives',
  HEALTH: 'health',
  COMPLIANCE: 'compliance',
  SPECIFICATIONS: 'specifications',
  DOWNLOADS: 'downloads',
};

export const PRODUCTION_RUNS_TABS_MAP = {
  STATUS: 'status',
  PARTS: 'parts',
  PURCHASING: 'purchasing',
  RELATED: 'related',
  SETTINGS: 'settings',
};
