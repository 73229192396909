import { Tooltip, Typography } from '@mui/material';

export function renderQuantity(
  quantity: number,
  certain: boolean,
  isOverAllocated: boolean = false
) {
  if (isOverAllocated) {
    return (
      <Tooltip title="This stock has allocated more than there is available for this part">
        <Typography>{quantity.toLocaleString('en-US')}</Typography>
      </Tooltip>
    );
  }

  if (certain) {
    return quantity.toLocaleString('en-US');
  }
  return (
    <Tooltip
      placement="top"
      title="This quantity has not been electronically checked by Cofactr so it may not be accurate"
    >
      <Typography fontStyle="italic">
        {quantity.toLocaleString('en-US')}
      </Typography>
    </Tooltip>
  );
}
