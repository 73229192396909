import { skipToken } from '@reduxjs/toolkit/dist/query';
import useServerSideQuery, {
  ServerSideQueryParams,
} from 'hooks/useServerSideQuery';
import { useMemo } from 'react';
import { useGetAllocationsClientV2Query } from 'store/slices/clientV2/allocations';
import { useGetKitRequestLinesQuery } from 'store/slices/clientV2/kitRequests';
import { useGetHandlingRequestsV2Query } from 'store/slices/clientV2/handlingRequests';
import { useGetShipmentLinesQuery } from 'store/slices/clientV2/shipments';
import { ALL, DISABLE_PAGE_LIMIT, Lite, QueryFilterOperators } from 'types/api';
import { AllocationSchema } from 'types/clientV2/allocations';
import { ShipmentLineSchema, ShipmentSchema } from 'types/clientV2/shipments';
import { KitRequestLineSchema, KitRequestSchema } from 'types/kitting';
import { HandlingRequestSchema } from 'types/clientV2/stock';
import {
  calculateKitRequestStatus,
  CalculateKitRequestStatusReturn,
  KitRequestAllocationStatus,
  KitRequestLineStats,
} from 'hooks/useGetDecoratedKitRequestLines/utils';
import _ from 'lodash';
import { useGetStockLotsServerSideQuery } from 'store/slices/inventory';
import { StockLotQuerySchema, StockLotQuerySchemaLite } from 'types/inventory';

export type KitRequestDataLite = Lite<
  KitRequestSchema,
  'shipTo' | 'sourceLocations' | 'kittedFor' | 'team' | 'org'
>;
export type HandlingRequestDataLite = Lite<HandlingRequestSchema>;

export type KitRequestLineDataLineLite = Lite<
  KitRequestLineSchema,
  'orgPart' | 'kitRequest' | 'customProperties'
>;
export type KitRequestLineDataAllocationLite = Lite<
  AllocationSchema,
  'stockLot'
>;
export type ShipmentLineDataLite = Omit<
  Lite<ShipmentLineSchema>,
  'shipment'
> & {
  shipment: Pick<Lite<ShipmentSchema>, 'id' | 'shipmentCode' | 'shippedAt'>;
};

export type DecoratedKitRequestLine = KitRequestLineDataLineLite & {
  allocations: (KitRequestLineDataAllocationLite & {
    calculatedStatus: KitRequestAllocationStatus;
  })[];
  shipmentLines: ShipmentLineDataLite[];
  handlingRequests: HandlingRequestDataLite[];
  stats: KitRequestLineStats;
  stockLots: Lite<StockLotQuerySchema>[];
};

type UseGetDecoratedKitRequestLinesProps = {
  selectedKitRequestIds: KitRequestSchema['id'][];
};

const getKitRequestLineQueryParams = (
  selectedKitRequestIds: KitRequestSchema['id'][]
): ServerSideQueryParams<KitRequestLineSchema, KitRequestLineDataLineLite> => ({
  schema: [
    ALL,
    {
      orgPart: [],
      kitRequest: ['id', 'approvedBy'],
      customProperties: [],
    },
  ],
  filters: [
    {
      field: 'kitRequest.id',
      operator: QueryFilterOperators.isAnyOf,
      value: selectedKitRequestIds,
    },
  ],
  pageSize: DISABLE_PAGE_LIMIT,
});

const getAllocationQueryParams = (
  selectedKitRequestIds: KitRequestSchema['id'][]
): ServerSideQueryParams<
  AllocationSchema,
  KitRequestLineDataAllocationLite
> => ({
  schema: [
    ALL,
    {
      stockLot: [],
    },
  ],
  filters: [
    {
      field: 'kitRequestLine.kitRequest',
      operator: QueryFilterOperators.isAnyOf,
      value: selectedKitRequestIds,
    },
  ],
  pageSize: DISABLE_PAGE_LIMIT,
});

const getShipmentLineQueryParams = (
  selectedKitRequestIds: KitRequestSchema['id'][]
): ServerSideQueryParams<ShipmentLineSchema, ShipmentLineDataLite> => ({
  schema: [ALL, { shipment: ['id', 'shipmentCode', 'shippedAt'] }],
  filters: [
    {
      field: 'kitRequestLine.kitRequest',
      operator: QueryFilterOperators.isAnyOf,
      value: selectedKitRequestIds,
    },
  ],
  pageSize: DISABLE_PAGE_LIMIT,
});

const getHandlingRequestQueryParams = (
  selectedKitRequestIds: KitRequestSchema['id'][]
): ServerSideQueryParams<HandlingRequestSchema, HandlingRequestDataLite> => ({
  schema: [ALL],
  filters: [
    {
      field: 'kitRequestIdViaLot',
      operator: QueryFilterOperators.isAnyOf,
      value: selectedKitRequestIds,
    },
    {
      field: 'completedAt',
      operator: QueryFilterOperators.isEmpty,
      value: null,
    },
  ],
  pageSize: DISABLE_PAGE_LIMIT,
});

const getStockLotQueryParams = (
  selectedKitRequestIds: KitRequestSchema['id'][]
): ServerSideQueryParams<StockLotQuerySchema, StockLotQuerySchemaLite> => ({
  schema: [ALL],
  filters: [
    {
      // @ts-ignore we have to do this because we are filtering multiple levels deep and that is not supported by the current typing
      field: 'allocations.kitRequestLine.kitRequest',
      operator: QueryFilterOperators.isAnyOf,
      value: selectedKitRequestIds,
    },
  ],
  pageSize: DISABLE_PAGE_LIMIT,
});

export const useGetDecoratedKitRequestLines = ({
  selectedKitRequestIds,
}: UseGetDecoratedKitRequestLinesProps) => {
  const { data: kitRequestLinesData, isLoading: isLoadingKitRequestLines } =
    useServerSideQuery<KitRequestLineSchema, KitRequestLineDataLineLite>(
      useGetKitRequestLinesQuery,
      selectedKitRequestIds.length > 0
        ? getKitRequestLineQueryParams(selectedKitRequestIds)
        : skipToken
    );

  const { data: allocationsData, isLoading: isLoadingAllocations } =
    useServerSideQuery<AllocationSchema, KitRequestLineDataAllocationLite>(
      useGetAllocationsClientV2Query,
      selectedKitRequestIds.length > 0
        ? getAllocationQueryParams(selectedKitRequestIds)
        : skipToken
    );

  const { data: shipmentLinesData, isLoading: isLoadingShipmentLines } =
    useServerSideQuery<ShipmentLineSchema, ShipmentLineDataLite>(
      useGetShipmentLinesQuery,
      selectedKitRequestIds.length > 0
        ? getShipmentLineQueryParams(selectedKitRequestIds)
        : skipToken
    );

  const { data: handlingRequestsData, isLoading: isLoadingHandlingRequests } =
    useServerSideQuery<HandlingRequestSchema, HandlingRequestDataLite>(
      useGetHandlingRequestsV2Query,
      selectedKitRequestIds.length > 0
        ? getHandlingRequestQueryParams(selectedKitRequestIds)
        : skipToken
    );

  const { data: stockLotsData, isLoading: isLoadingStockLots } =
    useServerSideQuery<StockLotQuerySchema, Lite<StockLotQuerySchema>>(
      useGetStockLotsServerSideQuery,
      selectedKitRequestIds.length > 0
        ? getStockLotQueryParams(selectedKitRequestIds)
        : skipToken
    );

  const kitRequestLines =
    selectedKitRequestIds.length > 0 ? kitRequestLinesData?.data : undefined;

  const allocations =
    selectedKitRequestIds.length > 0 ? allocationsData?.data : undefined;

  const shipmentLines =
    selectedKitRequestIds.length > 0 ? shipmentLinesData?.data : undefined;

  const handlingRequests =
    selectedKitRequestIds.length > 0
      ? _.uniqBy(handlingRequestsData?.data, 'id')
      : undefined;

  const stockLots =
    selectedKitRequestIds.length > 0 ? stockLotsData?.data : undefined;

  const decoratedKitRequestLines = useMemo(
    () =>
      kitRequestLines
        ? (kitRequestLines.map((line) => ({
            ...line,
            allocations:
              allocations?.filter(
                (allocation) => allocation.kitRequestLine === line.id
              ) ?? [],
            shipmentLines:
              shipmentLines?.filter(
                (shipmentLine) => shipmentLine.kitRequestLine === line.id
              ) ?? [],
            handlingRequests:
              handlingRequests?.filter(
                (handlingRequest) => handlingRequest.kitRequestLine === line.id
              ) ?? [],
            stockLots:
              stockLots?.filter((stockLot) =>
                _.chain(allocations ?? [])
                  .map('stockLot.id')
                  .includes(stockLot.id)
                  .value()
              ) ?? [],
          })) as DecoratedKitRequestLine[])
        : undefined,
    [kitRequestLines, allocations, shipmentLines, handlingRequests, stockLots]
  );

  const kitRequestStatuses: CalculateKitRequestStatusReturn | undefined =
    useMemo(() => {
      if (!decoratedKitRequestLines) return undefined;

      return calculateKitRequestStatus(decoratedKitRequestLines);
    }, [decoratedKitRequestLines]);

  const decoratedKitRequestLinesWithStatuses = useMemo(() => {
    if (!decoratedKitRequestLines || !kitRequestStatuses)
      return decoratedKitRequestLines;

    return decoratedKitRequestLines.map((line) => ({
      ...line,
      stats: kitRequestStatuses.lineStats[line.id],
      allocations: line.allocations.map((allocation) => ({
        ...allocation,
        calculatedStatus: kitRequestStatuses.allocationStats[allocation.id],
      })),
    }));
  }, [decoratedKitRequestLines, kitRequestStatuses]);

  return {
    kitRequestLines,
    kitRequestStatuses,
    allocations,
    stockLots,
    decoratedKitRequestLines: decoratedKitRequestLinesWithStatuses,
    isLoadingKitRequestLines,
    isLoadingAllocations,
    isLoadingShipmentLines,
    isLoadingHandlingRequests,
    isLoadingStockLots,
  };
};
