import { Box, Stack, Tooltip } from '@mui/material';
import { FC } from 'react';
import { COLOR_OPTIONS } from 'types';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';

export interface ProgressStackProps {
  bars: {
    key: string;
    label: string;
    sections: {
      key: string;
      label: string;
      value: number;
      color: COLOR_OPTIONS;
    }[];
  }[];
}

const ProgressStack: FC<ProgressStackProps> = ({ bars }) => {
  const theme = useTheme();
  const filteredBars = bars.filter((bar) => bar.sections.length > 0);
  if (filteredBars.length === 0) {
    return <></>;
  }
  return (
    <Stack
      direction="column"
      spacing={0}
      sx={{
        justifyContent: 'flex-end',
        alignItems: 'stretch',
        width: '100%',
        height: `${filteredBars.length * 8 + 8}px`,
      }}
    >
      {filteredBars.map((bar) => {
        const total = _.sumBy(bar.sections, 'value');
        return (
          <Stack
            key={bar.key}
            direction="row"
            spacing={0}
            sx={{ width: '100%', alignItems: 'flex-end', mt: '1px' }}
          >
            {bar.sections.map((section) => (
              <Tooltip
                key={section.key}
                title={`${bar.label}: ${
                  section.label
                } (${section.value.toLocaleString(
                  'en-US'
                )}/${total.toLocaleString('en-US')})`}
                placement="top"
              >
                <Box
                  sx={{
                    width: `${(section.value / total) * 100}%`,
                    height: '8px',
                    transition: 'height 0.3s ease',
                    '&:hover': {
                      height: '16px',
                    },
                    backgroundColor: _.get(theme.palette, [
                      section.color,
                      'main',
                    ]),
                  }}
                />
              </Tooltip>
            ))}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default ProgressStack;
