import {
  HandlingRequestDataLite,
  KitRequestLineDataAllocationLite,
} from 'hooks/useGetDecoratedKitRequestLines';
import ExtendedDatagrid from 'ui-component/DataGrid/ExtendedDatagrid';
import { DatagridNames } from 'types/datagrid';
import { useKitRequestContext } from 'views/kitting/Kit/KitRequestContext';
import { columns } from 'views/kitting/Kit/components/KitRequestLineDetailPanel/KitRequestsHandlingRequestsTable/columns';
import _ from 'lodash';
import { useMemo } from 'react';

type HandlingRequestWithStockLotsType = HandlingRequestDataLite & {
  stockLot: KitRequestLineDataAllocationLite['stockLot'];
};

const KitRequestsHandlingRequestsTable = ({
  handlingRequestsForKitRequestLine,
}: {
  handlingRequestsForKitRequestLine: HandlingRequestDataLite[];
}) => {
  const { kitRequest, isLoadingKitRequestLines, stockLots } =
    useKitRequestContext();

  const decoratedHandlingRequestsWithStockLots = useMemo(
    () =>
      _.map(handlingRequestsForKitRequestLine, (hrl) => ({
        ...hrl,
        stockLot: _.find(stockLots, { id: hrl.stockLot }),
      })) as HandlingRequestWithStockLotsType[],
    [stockLots, handlingRequestsForKitRequestLine]
  );

  return (
    <ExtendedDatagrid<HandlingRequestDataLite>
      loading={isLoadingKitRequestLines}
      exportFileNamePrefix={`${kitRequest?.name}-KitRequestHandlingRequests-StockLots`}
      noRows="No Handling Requests are associated with this kit line."
      gridName={DatagridNames.inventoryShipmentStockLotTab}
      rows={decoratedHandlingRequestsWithStockLots}
      columns={columns}
      initialVisibleColumns={[
        'status',
        'requestType',
        'description',
        'submittedAt',
        'completedAt',
        'notes',
        'relatedRecords',
      ]}
      // TODO: Add Stock Lot will be implemented with UI-3873 + UI-3874
      initialSorting={{
        sortModel: [{ field: 'requested', sort: 'desc' }],
      }}
    />
  );
};

export default KitRequestsHandlingRequestsTable;
