import useSnackbar from 'hooks/useSnackbar';
import { useEffect, useMemo, useState } from 'react';
import {
  useCreateAllocationsMutation,
  useDeleteAllocationsMutation,
  useUpdateAllocationsMutation,
} from 'store/slices/clientV2/allocations';
import { useUpdateKitRequestLinesMutation } from 'store/slices/clientV2/kitRequests';
import {
  createKitRequestLineFormConstants,
  createKitRequestLineStockLotValidation,
  updateKitRequestLineQuantOnly,
} from 'views/kitting/Kit/components/constants';
import * as Yup from 'yup';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { handleErr } from 'utils/functions';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { ALL_APP_IDS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import CreateUpdateKitRequestFromStockLot from 'views/kitting/Kit/components/CreateUpdateKitRequestLineForms/CreateUpdateKitRequestFromStockLot';
import { DecoratedKitRequestLine } from 'hooks/useGetDecoratedKitRequestLines';
import useGetCustomPropertyDefinitions from 'hooks/useGetCustomPropertyDefinitions';
import {
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';
import { ClientV2PATCHRequest } from 'types/api';
import { KitRequestLineSchema } from 'types/kitting';
import _ from 'lodash';

type UpdateKitRequestLineDialogProps = {
  dialogOpen: boolean;
  onClose: () => void;
  kitRequestLine: DecoratedKitRequestLine;
};

const UpdateKitRequestLineDialog = ({
  dialogOpen,
  onClose,
  kitRequestLine,
}: UpdateKitRequestLineDialogProps) => {
  const { dispatchSuccessSnackbar } = useSnackbar();

  const [submitError, setSubmitError] = useState<string | null>(null);
  const [disableUpdate, setDisableUpdate] = useState(false);

  const [updateKitRequestLines, { isLoading: isLoadingUpdateKitRequestLine }] =
    useUpdateKitRequestLinesMutation();
  const [createAllocation, { isLoading: isLoadingCreateAllocation }] =
    useCreateAllocationsMutation();
  const [updateAllocations, { isLoading: isLoadingUpdateAllocations }] =
    useUpdateAllocationsMutation();
  const [deleteAllocation, { isLoading: isLoadingDeleteAllocation }] =
    useDeleteAllocationsMutation();

  const allocationForLine = kitRequestLine.allocations[0];

  const {
    customProperties,
    getDefaultValuesForCustomProperties,
    customPropertiesFieldName,
  } = useGetCustomPropertyDefinitions({
    model: CustomPropertyModel.KIT_REQUEST_LINE,
    visibilityContext: CustomPropertyVisibility.EDIT_RECORD_DIALOG,
  });

  const formDefaults = useMemo(
    () => ({
      [createKitRequestLineFormConstants.part.id]: kitRequestLine?.part,
      [createKitRequestLineFormConstants.stockLot.id]: kitRequestLine?.locked
        ? {
            id: allocationForLine?.stockLot?.id,
            quant: allocationForLine?.quant,
          }
        : '',
      [createKitRequestLineFormConstants.targetQuantityStockLot.id]:
        kitRequestLine?.targetQuantity,
      [customPropertiesFieldName]:
        getDefaultValuesForCustomProperties<DecoratedKitRequestLine>(
          kitRequestLine
        ),
    }),
    [kitRequestLine, customPropertiesFieldName]
  );

  const validationSchema = kitRequestLine?.locked
    ? Yup.object().shape(createKitRequestLineStockLotValidation)
    : Yup.object().shape(updateKitRequestLineQuantOnly);

  const methods = useForm<FieldValues>({
    defaultValues: formDefaults,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const {
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = methods;

  useEffect(() => {
    reset(formDefaults);
  }, [formDefaults]);

  const handleClose = () => {
    onClose();
    reset();
    setDisableUpdate(false);
    setSubmitError(null);
  };

  const handleDeleteAndCreateAllocation = async (data: FieldValues) => {
    try {
      if (
        allocationForLine.stockLot.id ===
        data[createKitRequestLineFormConstants.stockLot.id].id
      ) {
        await updateAllocations({
          id: kitRequestLine.allocations[0].id,
          kitRequestLine: kitRequestLine.id,
          quant:
            data[createKitRequestLineFormConstants.targetQuantityStockLot.id],
        }).unwrap();
      } else {
        await deleteAllocation({
          ids: [allocationForLine.id],
        }).unwrap();
        await createAllocation({
          kitRequestLine: kitRequestLine.id,
          quant:
            data[createKitRequestLineFormConstants.targetQuantityStockLot.id],
          stockLot: data[createKitRequestLineFormConstants.stockLot.id].id,
        }).unwrap();
      }
      dispatchSuccessSnackbar('Kit Line updated successfully');
      handleClose();
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        setSubmitError(errMessage);
      });
    }
  };

  const handleUpdateKitRequestLine = async (data: FieldValues) => {
    try {
      if (kitRequestLine?.locked) {
        await handleDeleteAndCreateAllocation(data);
      }
      // Update kit request line for both locked and unlocked kit request lines
      const payload = {
        id: kitRequestLine.id,
        targetQuantity:
          data[createKitRequestLineFormConstants.targetQuantityStockLot.id],
        minimumQuantity:
          data[createKitRequestLineFormConstants.targetQuantityStockLot.id],
      } as ClientV2PATCHRequest<KitRequestLineSchema>;
      if (Object.keys(dirtyFields).includes(customPropertiesFieldName)) {
        _.set(payload, 'customProperties', data[customPropertiesFieldName]);
      }
      await updateKitRequestLines(payload).unwrap();
      dispatchSuccessSnackbar('Kit Line updated successfully');
      handleClose();
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        setSubmitError(errMessage);
      });
    }
  };

  return (
    <ExtendedDialog
      onSubmit={handleSubmit(handleUpdateKitRequestLine)}
      formSubmitError={submitError}
      submitButtonCopy="Save"
      isSubmitting={
        isLoadingUpdateKitRequestLine ||
        isLoadingUpdateAllocations ||
        isLoadingCreateAllocation ||
        isLoadingDeleteAllocation
      }
      open={dialogOpen}
      isForm
      onCloseDialog={handleClose}
      title="Update Kit Line"
      permissionScope={{
        app: ALL_APP_IDS.KITTING,
        accessLevel: appPermissionAccessLevels.edit,
      }}
      submitDisabled={disableUpdate}
    >
      <FormProvider {...methods}>
        <CreateUpdateKitRequestFromStockLot
          isCreate={false}
          kitRequestLine={kitRequestLine}
          customProperties={customProperties}
        />
      </FormProvider>
    </ExtendedDialog>
  );
};

export default UpdateKitRequestLineDialog;
