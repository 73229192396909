import useUpdateRecordDialog from 'hooks/useUpdateRecordDialog';
import {
  createUpdateKitRequestFormConstants,
  createUpdateKitRequestValidationSchema,
} from 'views/kitting/components/constants';
import { useUpdateKitRequestsMutation } from 'store/slices/clientV2/kitRequests';
import CreateUpdateKitRequestForm, {
  KitRequestFormDefaultValuesType,
} from 'views/kitting/components/CreateUpdateKitRequestForm';
import { createUpdateKitRequestPreSubmit } from 'views/kitting/components/utils';
import _ from 'lodash';
import { getDefaultShippingMethod } from 'utils/functions';
import { useSelector } from 'store';
import { useGetOrgQuery } from 'store/slices/org';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { formatToUniversalDate } from 'utils/dates';
import { FormProvider } from 'react-hook-form';
import { KitRequestSelectedSubtype } from 'views/kitting/Kits';
import useGetCustomPropertyDefinitions from 'hooks/useGetCustomPropertyDefinitions';
import {
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';

type UpdateKitRequestDialogProps = {
  dialogOpen: boolean;
  onClose: () => void;
  kitRequestToUpdate: KitRequestSelectedSubtype;
};

const UpdateKitRequestDialog = ({
  dialogOpen,
  onClose,
  kitRequestToUpdate,
}: UpdateKitRequestDialogProps) => {
  const { activeOrgId } = useSelector((state) => state.org);
  const { data: org } = useGetOrgQuery(activeOrgId ?? skipToken);

  const {
    customProperties,
    getDefaultValuesForCustomProperties,
    customPropertiesFieldName,
  } = useGetCustomPropertyDefinitions({
    model: CustomPropertyModel.KIT_REQUEST,
    visibilityContext: CustomPropertyVisibility.EDIT_RECORD_DIALOG,
  });

  const formDefaults = {
    [createUpdateKitRequestFormConstants.name.id]: _.get(
      kitRequestToUpdate,
      createUpdateKitRequestFormConstants.name.id,
      ''
    ),
    [createUpdateKitRequestFormConstants.sourceLocations.id]: _.map(
      kitRequestToUpdate?.sourceLocations,
      'id'
    ),
    [createUpdateKitRequestFormConstants.productionRun.id]:
      kitRequestToUpdate?.productionRun?.id ?? '',
    [createUpdateKitRequestFormConstants.team.id]: _.get(
      kitRequestToUpdate,
      createUpdateKitRequestFormConstants.team.id,
      ''
    ),
    [createUpdateKitRequestFormConstants.shipTo.id]:
      kitRequestToUpdate?.shipTo?.id ?? '',
    [createUpdateKitRequestFormConstants.shipMethod.id]:
      kitRequestToUpdate?.carrier && kitRequestToUpdate?.service
        ? `${kitRequestToUpdate.carrier}_${kitRequestToUpdate.service}`
        : getDefaultShippingMethod({ org }),
    [createUpdateKitRequestFormConstants.shipPartial.id]: _.get(
      kitRequestToUpdate,
      createUpdateKitRequestFormConstants.shipPartial.id,
      false
    ),
    [createUpdateKitRequestFormConstants.targetShipDate.id]:
      kitRequestToUpdate?.targetShipDate
        ? formatToUniversalDate(kitRequestToUpdate?.targetShipDate)
        : null,
    [customPropertiesFieldName]:
      getDefaultValuesForCustomProperties<KitRequestSelectedSubtype>(
        kitRequestToUpdate
      ),
  } as KitRequestFormDefaultValuesType;

  const { methods, BaseUpdateRecordDialog, dialogProps } =
    useUpdateRecordDialog({
      formConstants: createUpdateKitRequestFormConstants,
      validationSchema: createUpdateKitRequestValidationSchema,
      useUpdateMutation: useUpdateKitRequestsMutation,
      newRecordDefaults: formDefaults,
      recordName: 'Kit',
      selectedRecord: kitRequestToUpdate,
      preSubmit: createUpdateKitRequestPreSubmit,
      submitAsClientV2Api: true,
    });

  return (
    <BaseUpdateRecordDialog
      open={dialogOpen}
      onClose={onClose}
      dialogProps={dialogProps}
    >
      <FormProvider {...methods}>
        <CreateUpdateKitRequestForm
          defaultValues={formDefaults}
          customProperties={customProperties}
        />
      </FormProvider>
    </BaseUpdateRecordDialog>
  );
};

export default UpdateKitRequestDialog;
