import { useSelector } from 'store';
import { useGetUserQuery } from 'store/slices/user';
import { isCofactrUser } from 'utils/userPermissions';
import { useMemo } from 'react';

export const useIsCofactr = () => {
  const { viewAsStaff } = useSelector((state) => state.user);
  const { data: user } = useGetUserQuery(undefined);
  const isCofactr = useMemo(
    () => isCofactrUser({ user, viewAsStaff }),
    [viewAsStaff, user?.id]
  );
  return {
    isCofactr,
    user,
    viewAsStaff,
  };
};
