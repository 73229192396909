import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import Tags from 'ui-component/Tags';

interface TagsRenderCellProps extends GridRenderCellParams {
  onOpenEditTags?: () => void;
}

export const TagsRenderCell = ({
  value,
  onOpenEditTags,
}: TagsRenderCellProps) => <Tags tags={value} />;
