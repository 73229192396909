import * as Yup from 'yup';
import { StockLotQuerySchema } from 'types/inventory';

interface KitRequestLineFormValues {
  stockLot: Pick<StockLotQuerySchema, 'quant' | 'id' | 'part'>;
  targetQuantity: number;
}

export const createKitRequestLineFormConstants = {
  part: { label: 'Part', id: 'part' },
  targetQuantityPart: { label: 'Quantity', id: 'targetQuantityPart' },
  targetQuantityStockLot: { label: 'Quantity', id: 'targetQuantityStockLot' },
  stockLot: { label: 'Stock Lot', id: 'stockLot' },
};

function validateQuantity(
  this: { parent: KitRequestLineFormValues },
  value: number | undefined
) {
  const { stockLot } = this.parent;
  return Boolean(value && value <= stockLot?.quant);
}

export const updateKitRequestLineQuantOnly = {
  [createKitRequestLineFormConstants.targetQuantityStockLot.id]: Yup.number()
    .required(
      `${createKitRequestLineFormConstants.targetQuantityStockLot.label} is required`
    )
    .typeError(
      `${createKitRequestLineFormConstants.targetQuantityStockLot.label} is required`
    ),
};

export const createKitRequestLineStockLotValidation = {
  [createKitRequestLineFormConstants.stockLot.id]: Yup.object()
    .required('Stock Lot is required')
    .typeError('Stock Lot is required'),
  [createKitRequestLineFormConstants.targetQuantityStockLot.id]: Yup.number()
    .required(
      `${createKitRequestLineFormConstants.targetQuantityStockLot.label} is required`
    )
    .typeError(
      `${createKitRequestLineFormConstants.targetQuantityStockLot.label} is required`
    )
    .test(
      'is-valid-quantity',
      'Target Quantity must be less than or equal to the available stock lot quantity',
      validateQuantity
    ),
};

export const createKitRequestLinePartValidation = {
  [createKitRequestLineFormConstants.part.id]:
    Yup.string().required('Part is required'),
  [createKitRequestLineFormConstants.targetQuantityPart.id]: Yup.number()
    .required(
      `${createKitRequestLineFormConstants.targetQuantityPart.label} is required`
    )
    .typeError(
      `${createKitRequestLineFormConstants.targetQuantityPart.label} is required`
    ),
};
