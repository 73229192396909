import _ from 'lodash';
import {
  ReferencePrice,
  ReferencePriceLite,
  Part,
  PartCore,
  PartSupply,
  PartPrice,
  PriceBreak,
  PartOffer,
  Offer,
  OfferWithPartId,
  OfferType,
} from 'types/part';
import { PartRuleSet } from 'types/partRules';
import jsonLogic from 'json-logic-js';
import { camelizeVars } from 'utils/functions';
import { PurchaseRule } from 'types/inventory';
import {
  DecoratedOffer,
  DecoratedOfferGroup,
  OptimizePreferences,
} from 'types/offerOptimizer';

export const calcReferenceBreakPrice = (
  referencePrices: ReferencePrice[] | ReferencePriceLite[],
  quant: number
) => {
  // normalize the reference price types
  const normalizedReferencePrices = _.map(referencePrices, (p) => ({
    quantity: p.quantity,
    price: ('price' in p
      ? _.toNumber(p.price)
      : _.toNumber(p.convertedPrice)) as number,
  }));

  if (!normalizedReferencePrices.length) return 0;
  const sortedPrices = _.chain(normalizedReferencePrices)
    .sortBy('quantity')
    .reverse()
    .value();

  let price: number | null = null;
  for (const p of sortedPrices) {
    if (quant >= p.quantity) {
      price = _.toNumber(p.price);
      break;
    }
  }
  if (price === null) {
    const first = _.first(sortedPrices);
    if (!first) return 0;
    price = _.toNumber(first.price);
  }

  if (!price) return 0;

  return price;
};

export const calcOfferBreakPrice = (
  prices: PartPrice[] | PriceBreak[],
  quant: number
) => {
  if (!prices.length) return 0;
  const sortedPrices = _.chain(prices)
    .sortBy('priceBreak')
    .reverse()
    .value() as PartPrice[] | PriceBreak[];

  let price: number | null = null;
  for (const p of sortedPrices) {
    if (quant >= p.priceBreak) {
      price = _.toNumber(p.price);
      break;
    }
  }
  if (price === null) {
    const first = _.first(_.reverse(sortedPrices));
    if (!first) return 0;
    price = _.toNumber(first.price);
  }

  if (!price) return 0;

  return price;
};

export const calcQuantWithMoqAndMultiple = (
  quant: number,
  moq: number,
  multiple: number
) => {
  // apply moq/multiple
  const quantToBuy = Math.max(Math.ceil(quant / multiple) * multiple, moq);
  return quantToBuy;
};

export const calcBestOfferBreakPrice = (
  offer: PartOffer | Offer,
  quant: number,
  allowBackorder: boolean
) => {
  if (!offer.prices.length) return null;

  // apply moq/multiple
  const quantToBuy = Math.max(
    Math.ceil(quant / offer.multiple) * offer.multiple,
    offer.moq
  );

  // calculate total price for each priceBreak that is less than the offer available stock
  const bestPriceBreak = _.chain(offer.prices)
    .filter((p) => p.priceBreak <= offer.reportedStock || allowBackorder)
    .map((p) => ({
      priceBreak: p.priceBreak,
      unitPrice: p.price,
      totalPrice: p.price * Math.max(quantToBuy, p.priceBreak),
      purchaseQuant: Math.max(quantToBuy, p.priceBreak),
    }))
    .sortBy('totalPrice')
    .first()
    .value();

  return bestPriceBreak;
};

export const calcOverage = (request: {
  part: Part | PartCore;
  supply?: PartSupply;
  quant: number;
  returnCalculationProps?: boolean;
}) => {
  let minimum = 0; // defines the fewest total number of allowable parts
  let minOverage = 0; // defines the fewest number of overage parts
  let percentOverage = 0; // defines overage percentage

  // calculate the reference price
  let price = calcReferenceBreakPrice(
    request.supply?.referencePrices ||
      (request?.part && 'referencePrices' in request.part
        ? request.part.referencePrices
        : []) ||
      [],
    request.quant
  );
  if (!price) {
    price = 4; // parts with no price data are usually not super cheap in practice so this is hardcoded to produce a generally correct result
  }

  // handle through hole parts
  if (_.toLower(request.part.terminationType || '') !== 'smt') {
    if (price < 1) {
      minimum = 2;
      minOverage = 2;
      percentOverage = 0.05;
    } else if (price >= 1 && price < 5) {
      minimum = 2;
      minOverage = 2;
      percentOverage = 0.02;
    } else {
      minimum = 2;
      minOverage = 1;
      percentOverage = 0.02;
    }
  }

  // handle passives based on footprint
  if (request.part.package) {
    if (_.includes(['01005', '0201', '0402'], request.part.package)) {
      minimum = 200;
      minOverage = 100;
      percentOverage = 0.5;
    } else if (_.includes(['0603', '0805'], request.part.package)) {
      minimum = 50;
      minOverage = 25;
      percentOverage = 0.1;
    } else if (
      _.includes(
        [
          '1008',
          '1206',
          '1210',
          '1806',
          '1812',
          '1825',
          '2010',
          '2512',
          '2725',
          '2920',
        ],
        request.part.package
      )
    ) {
      minimum = 20;
      minOverage = 10;
      percentOverage = 0.1;
    }
  }

  // handle everything else based on price
  if (!minimum) {
    if (price < 1) {
      minimum = 10;
      minOverage = 10;
      percentOverage = 0.15;
    } else if (price >= 1 && price < 5) {
      minimum = 5;
      minOverage = 5;
      percentOverage = 0.1;
    } else {
      minimum = 2;
      minOverage = 2;
      percentOverage = 0.05;
    }
  }

  if (request.returnCalculationProps) {
    return {
      minimum,
      minOverage,
      percentOverage,
    };
  }

  // apply overage calculation
  const overage = Math.max(request.quant * percentOverage, minOverage);
  const quant = Math.ceil(Math.max(request.quant + overage, minimum));
  return quant - request.quant;
};

export const applyOverage = (request: {
  minimum: number;
  minOverage: number;
  percentOverage: number;
  quant: number;
}) => {
  const overage = Math.max(
    request.quant * request.percentOverage,
    request.minOverage
  );
  const quant = Math.ceil(Math.max(request.quant + overage, request.minimum));
  return quant - request.quant;
};

export const calcOverageFromPartRuleSet = (request: {
  part: Part | PartCore;
  supply?: PartSupply;
  quant: number;
  partRuleSet?: PartRuleSet | null;
  returnMinimum?: boolean;
}) => {
  if (!request.partRuleSet) {
    return calcOverage(request);
  }

  let minimum = 0; // defines the fewest total number of allowable parts
  let minOverage = 0; // defines the fewest number of overage parts
  let percentOverage = 0; // defines overage percentage

  // calculate the reference price
  let price = calcReferenceBreakPrice(
    request.supply?.referencePrices ||
      (request?.part && 'referencePrices' in request.part
        ? request.part.referencePrices
        : []) ||
      [],
    request.quant
  );
  if (!price) {
    price = 4; // parts with no price data are usually not super cheap in practice so this is hardcoded to produce a generally correct result
  }

  const sortedRules = _.sortBy(request.partRuleSet.rules, 'evaluationOrder');

  const variables = { ...request.part, price };

  _.every(sortedRules, (rule) => {
    const ruleQuery = camelizeVars(JSON.parse(rule.query));
    if (jsonLogic.apply(ruleQuery, variables)) {
      minimum = request.returnMinimum
        ? rule.minimumAttritionMinTotal
        : rule.attritionMinTotal;
      minOverage = request.returnMinimum
        ? rule.minimumAttritionQuantAdditional
        : rule.attritionQuantAdditional;
      percentOverage = request.returnMinimum
        ? rule.minimumAttritionPercentAdditional
        : rule.attritionPercentAdditional;
      return false;
    }
    return true;
  });

  // apply overage calculation
  const overage = Math.max(request.quant * percentOverage, minOverage);
  const quant = Math.ceil(Math.max(request.quant + overage, minimum));
  return quant - request.quant;
};

const calcSortedBids = (
  offers: (Offer | PartOffer | DecoratedOffer)[],
  quant: number,
  addReeling?: boolean
) =>
  _.chain(offers)
    .filter(
      (o) =>
        (!!o.supplier.buyable || o.offerType === OfferType.QUOTE) &&
        o.reportedStock > 0 &&
        o.prices.length > 0
    )
    .map((o) => {
      const addReelingPrice =
        addReeling && o.customReelSku
          ? _.toNumber((o as DecoratedOffer).orgSupplier?.customReelPrice || 0)
          : 0;
      if (o.reportedStock >= quant) {
        // handle moq/multiple
        const bidQuant =
          Math.min(
            Math.ceil(Math.max(o.moq, quant) / o.multiple),
            o.reportedStock
          ) * o.multiple;

        // handle round to higher price break to save money
        const breakPrices = [
          {
            priceBreak: bidQuant,
            price: calcOfferBreakPrice(o.prices, bidQuant) * bidQuant,
          },
          ..._.chain(o.prices)
            .filter((p) => p.priceBreak > bidQuant)
            .map((p) => ({
              priceBreak: p.priceBreak,
              price: _.toNumber(p.price) * p.priceBreak,
            }))
            .value(),
        ];
        const bestPriceBreak = _.chain(breakPrices)
          .sortBy('price')
          .first()
          .value();

        return {
          bidQuant: bestPriceBreak.priceBreak,
          bidPrice: _.toNumber(bestPriceBreak.price) + addReelingPrice,
          unitPrice:
            (_.toNumber(bestPriceBreak.price) + addReelingPrice) /
            Math.min(bidQuant, quant),
          offer: o,
        };
      }

      const bidPrice =
        calcOfferBreakPrice(o.prices, o.reportedStock) * o.reportedStock;
      return {
        bidQuant: o.reportedStock,
        bidPrice: bidPrice + addReelingPrice,
        unitPrice: (bidPrice + addReelingPrice) / o.reportedStock,
        offer: o,
      };
    })
    .sortBy('unitPrice')
    .value();

export const calcBestUnitPrice = (request: {
  part: Part | PartCore;
  supply?: PartSupply;
  altsSupply?: PartSupply[];
  quant: number;
  ignoreReportedStock?: boolean;
}) => {
  // collapse alt offers
  const offers = _.compact([
    ...(request.supply?.offers || []),
    ..._.chain(request.altsSupply || [])
      .map('offers')
      .flatten()
      .value(),
  ]);

  // calculate using reference price if supply is not provided or no offers are in supply
  if (!request.supply || !offers.length) {
    const referencePrice = calcReferenceBreakPrice(
      request.supply?.referencePrices ||
        (request?.part && 'buyableReferencePrices' in request.part
          ? request.part.buyableReferencePrices
          : []) ||
        [],
      request.quant
    );

    if (!referencePrice) {
      return calcReferenceBreakPrice(
        request.supply?.referencePrices ||
          (request?.part && 'referencePrices' in request.part
            ? request.part.referencePrices
            : []) ||
          [],
        request.quant
      );
    }
    return referencePrice;
  }

  if (request.ignoreReportedStock) {
    const cheapestOffer = _.chain(offers)
      .compact()
      .filter(
        (o) =>
          (o.supplier.buyable || o.offerType === OfferType.QUOTE) &&
          o.prices.length > 0
      )
      .sortBy((o) => calcOfferBreakPrice(o.prices, request.quant))
      .first()
      .value();

    if (cheapestOffer) {
      return calcOfferBreakPrice(cheapestOffer.prices, request.quant);
    }

    return 0;
  }

  // calculate using buyable offers
  const sortedBids = calcSortedBids(offers, request.quant);

  let quantToCover = request.quant; // this will track how much of the request quant we still need to price a source for
  let totalPrice = 0;
  const usedBids = []; // this will track the bids used to calculate the total price
  for (const bid of sortedBids) {
    if (quantToCover > 0) {
      if (bid.bidQuant <= quantToCover) {
        quantToCover -= bid.bidQuant;
        totalPrice += bid.bidPrice;
        usedBids.push(bid);
      } else {
        const rebidQuant = Math.max(quantToCover, bid.offer.moq);
        const rebidPrice =
          calcOfferBreakPrice(bid.offer.prices, rebidQuant) * rebidQuant;
        totalPrice += rebidPrice;
        quantToCover = 0;
        usedBids.push(bid);
      }
    } else {
      break;
    }
  }
  if (!quantToCover) {
    // check if a single bid could have covered the required quantity at a lower price
    for (const bid of usedBids) {
      if (bid.bidQuant >= request.quant) {
        totalPrice = bid.bidPrice;
      }
    }

    return totalPrice / request.quant;
  }

  // fall back on using backorder offers
  const cheapestBackorderOffer = _.chain(offers)
    .compact()
    .filter(
      (o) =>
        (o.supplier.buyable || o.offerType === OfferType.QUOTE) &&
        o.prices.length > 0 &&
        o.factoryLeadDays > 0
    )
    .sortBy((o) => calcOfferBreakPrice(o.prices, request.quant))
    .first()
    .value();

  if (cheapestBackorderOffer) {
    return calcOfferBreakPrice(cheapestBackorderOffer.prices, request.quant);
  }

  return 0;
};

export const calcMinLead = (request: {
  part: Part | PartCore;
  supply?: PartSupply;
  altsSupply?: PartSupply[];
  quant: number;
}) => {
  // filter and sort offers
  const sortedOffers = _.chain([
    ...(request.supply?.offers || []),
    ..._.chain(request.altsSupply || [])
      .map('offers')
      .flatten()
      .value(),
  ])
    .compact()
    .filter(
      (o) =>
        (o.supplier.buyable || o.offerType === OfferType.QUOTE) &&
        o.reportedStock > 0 &&
        o.prices.length > 0
    )
    .sortBy('lead')
    .value();

  let quantToCover = request.quant; // this will track how much of the request quant we still need to cover
  let minLead = 0;
  for (const offer of sortedOffers) {
    if (quantToCover > 0) {
      minLead = offer.lead;
      quantToCover -= offer.reportedStock;
    } else {
      break;
    }
  }

  if (quantToCover > 0) {
    return 0;
  }
  return minLead;
};

export const calcSolicitationTargetPrice = (request: {
  part: Part | PartCore;
  supply?: PartSupply;
  quant: number;
}) => {
  // check if buyable quant is more than quant
  const buyable = request.supply?.buyable || 0;
  if (buyable > request.quant) {
    return (
      calcReferenceBreakPrice(
        request.supply?.buyableReferencePrices || [],
        request.quant
      ) * 0.9
    ).toFixed(4);
  }

  return (
    calcReferenceBreakPrice(
      request.supply?.referencePrices || [],
      request.quant
    ) * 1.5
  ).toFixed(4);
};

export const calcBuyableStock = (request: {
  offers: (PartOffer | Offer)[];
  overlap: boolean;
}): number => {
  let buyableStock = 0;
  if (request.overlap) {
    for (const offer of request.offers) {
      if (
        (offer.supplier.buyable || offer.offerType === OfferType.QUOTE) &&
        offer.reportedStock > buyableStock
      ) {
        buyableStock = offer.reportedStock;
      }
    }
  } else {
    for (const offer of request.offers) {
      if (
        (offer.supplier.buyable || offer.offerType === OfferType.QUOTE) &&
        offer.reportedStock
      ) {
        buyableStock += offer.reportedStock;
      }
    }
  }
  return buyableStock;
};

export const calcOffersToUse = (request: {
  offers: (PartOffer | Offer | DecoratedOffer | OfferWithPartId)[];
  quant: number;
  addReelingPrice: number;
}) => {
  const sortedBids = calcSortedBids(request.offers, request.quant);

  let quantToCover = request.quant; // this will track how much of the request quant we still need to price a source for
  let totalPrice = 0;
  let usedBids = []; // this will track the bids used to calculate the total price
  let actualPurchaseQuant = 0;
  for (const bid of sortedBids) {
    if (quantToCover > 0) {
      if (bid.bidQuant <= quantToCover) {
        quantToCover -= bid.bidQuant;
        actualPurchaseQuant += bid.bidQuant;
        totalPrice += bid.bidPrice;
        usedBids.push(bid);
      } else {
        const rebidBreak = calcBestOfferBreakPrice(
          bid.offer,
          quantToCover,
          false
        );
        const rebidQuant = rebidBreak?.purchaseQuant || 0;
        const rebidPrice = rebidBreak?.totalPrice || 0;
        totalPrice += rebidPrice;
        actualPurchaseQuant += rebidQuant;
        quantToCover = 0;
        usedBids.push(bid);
      }
    } else {
      break;
    }
  }
  if (!quantToCover) {
    // check if a single bid could have covered the required quantity at a lower price
    for (const bid of usedBids) {
      if (bid.bidQuant >= request.quant) {
        totalPrice = bid.bidPrice;
        actualPurchaseQuant = bid.bidQuant;
        usedBids = [bid];
      }
    }

    const customReelingPrice = _.some(usedBids, 'offer.customReelSku')
      ? request.addReelingPrice
      : 0;
    return {
      totalPrice: totalPrice + customReelingPrice,
      offers: _.map(usedBids, 'offer'),
      actualPurchaseQuant,
      unitPrice: (totalPrice + customReelingPrice) / actualPurchaseQuant,
      isBackordered: _.first(_.map(usedBids, 'offer'))?.isBackordered,
    };
  }

  // fall back on using backorder offers
  const cheapestBackorderOffer = _.chain(request.offers)
    .compact()
    .filter(
      (o) =>
        (!!o.supplier.buyable || o.offerType === OfferType.QUOTE) &&
        o.prices.length > 0 &&
        (o.factoryLeadDays || 0) > 0
    )
    .sortBy((o) => calcOfferBreakPrice(o.prices, request.quant))
    .first()
    .value();

  if (cheapestBackorderOffer) {
    const bestBreak = calcBestOfferBreakPrice(
      cheapestBackorderOffer,
      request.quant,
      true
    );
    if (!bestBreak) return null;
    const customReelingPrice = cheapestBackorderOffer.customReelSku
      ? request.addReelingPrice
      : 0;
    return {
      totalPrice: bestBreak.totalPrice + customReelingPrice,
      offers: [cheapestBackorderOffer],
      actualPurchaseQuant: bestBreak.purchaseQuant,
      unitPrice:
        bestBreak.unitPrice + customReelingPrice / bestBreak.purchaseQuant,
      isBackordered: true,
    };
  }
  return null;
};

const offerLead = (o: DecoratedOffer) => {
  if (o.offerType === OfferType.QUOTE) {
    return o.lead;
  }
  return (
    o.lead -
    (o.supplier.lead || 0) +
    (o.orgSupplier?.shipsInLead || 0) +
    (_.min(
      _.compact([
        ..._.map(o.orgSupplier?.shippingOptions || [], 'shippingLead'),
        o.orgSupplier?.freeShip?.shippingLead,
      ])
    ) || 0)
  );
};

export const groupOverlappingOffers = (offers: DecoratedOffer[]) => {
  if (!offers.length) {
    return [];
  }

  const groups: DecoratedOfferGroup[] = [];

  const uniqueOrgSuppliers = _.chain(offers)
    .map('orgSupplier')
    .uniqBy('objectId')
    .compact()
    .value();

  const uniqueSupplierPartHash = _.chain(offers)
    .map((o) => ({
      supplierObjectId: o.orgSupplier?.objectId,
      partId: o.part?.id,
      offerType: o.offerType,
    }))
    .uniqBy((sp) => `${sp.supplierObjectId}-${sp.partId}-${sp.offerType}`)
    .value();

  _.forEach(uniqueSupplierPartHash, (sp) => {
    const relatedOffers = _.filter(
      offers,
      (o) =>
        o.orgSupplier?.objectId === sp.supplierObjectId &&
        o.part?.id === sp.partId
    );

    const orgSupplier = _.find(uniqueOrgSuppliers, {
      objectId: sp.supplierObjectId,
    });

    if (orgSupplier?.overlap) {
      groups.push(
        ..._.chain(relatedOffers)
          .groupBy((o) => offerLead(o))
          .values()
          .map(
            (og) =>
              ({
                orgSupplier,
                part: _.first(og)?.part,
                offerType: _.first(og)?.offerType,
                offers: og,
                reportedStock: _.maxBy(og, 'reportedStock')?.reportedStock,
                lead: offerLead(_.first(og) as DecoratedOffer),
              } as DecoratedOfferGroup)
          )
          .value()
      );
    } else {
      groups.push(
        ..._.chain(relatedOffers)
          .map(
            (o) =>
              ({
                orgSupplier,
                part: o.part,
                offerType: o.offerType,
                offers: [o],
                reportedStock: o.reportedStock,
                lead: offerLead(o),
              } as DecoratedOfferGroup)
          )
          .value()
      );
    }
  });

  return groups;
};

export const filterOffersByPurchaseRule = (
  offerGroups: DecoratedOfferGroup[],
  quant: number,
  purchaseRule: PurchaseRule | null | undefined,
  preferences: OptimizePreferences | undefined
) => {
  let filteredOfferGroups = offerGroups;

  // filter out offers based on backorder preferences, maxLead, and buyability
  filteredOfferGroups = _.filter(filteredOfferGroups, (o) => {
    if (!o.orgSupplier?.buyable && o.offerType !== OfferType.QUOTE)
      return false;

    if (!o.reportedStock && !_.first(o.offers)?.factoryLeadDays) return false;

    if (
      preferences?.noBackorders &&
      (_.first(o.offers)?.isBackordered || !o.reportedStock)
    )
      return false;

    if (preferences?.maxLead && o.lead > preferences.maxLead) return false;

    return true;
  });

  // filter out offers based on allow and banned suppliers in purchase rule
  if (purchaseRule) {
    filteredOfferGroups = _.filter(filteredOfferGroups, (o) => {
      if (
        preferences?.quotesOverridePurchaseRule &&
        o.offerType === OfferType.QUOTE
      )
        return true;

      if (!_.first(o.offers)?.authorized && purchaseRule.authorizedOnly)
        return false;

      // offers from preferred suppliers are always allowed
      if (
        _.chain(purchaseRule.preferredOrgSuppliers || [])
          .map('objectId')
          .includes(o.orgSupplier?.objectId || '')
          .value()
      )
        return true;

      // if allowed suppliers are defined, only allow offers from those suppliers
      if (
        purchaseRule.allowedOrgSuppliers &&
        !_.chain(purchaseRule.allowedOrgSuppliers || [])
          .map('objectId')
          .includes(o.orgSupplier?.objectId || '')
          .value()
      )
        return false;

      // banned suppliers are never allowed
      if (
        _.chain(purchaseRule.bannedOrgSuppliers || [])
          .map('objectId')
          .includes(o.orgSupplier?.objectId || '')
          .value()
      )
        return false;

      return true;
    });
  }

  // filter out backordered offers based on avoidBackorders preference
  if (preferences?.avoidBackorders) {
    const nonBackorderStock = _.chain(filteredOfferGroups)
      .filter((o) => !_.first(o.offers)?.isBackordered)
      .sumBy('reportedStock')
      .value();
    if (nonBackorderStock > quant) {
      filteredOfferGroups = _.filter(
        filteredOfferGroups,
        (o) => !_.first(o.offers)?.isBackordered && o.reportedStock > 0
      );
    }
  }

  // filter out offers based on targetLead preference
  if (preferences?.targetLead && preferences.targetLead > 0) {
    const fastEnoughStock = _.chain(filteredOfferGroups)
      .filter((o) => o.lead <= (preferences.targetLead || 0))
      .sumBy('reportedStock')
      .value();
    if (fastEnoughStock > quant) {
      filteredOfferGroups = _.filter(
        filteredOfferGroups,
        (o) => o.lead <= (preferences.targetLead || 0)
      );
    }
  }

  // filter out offers based on preferred/last-resort purchase rule preferences
  if (purchaseRule) {
    const preferredStock = _.chain(filteredOfferGroups)
      .filter((o) =>
        _.chain(purchaseRule.preferredOrgSuppliers || [])
          .map('objectId')
          .includes(o.orgSupplier?.objectId || '')
          .value()
      )
      .sumBy('reportedStock')
      .value();
    if (preferredStock > quant) {
      filteredOfferGroups = _.filter(filteredOfferGroups, (o) =>
        _.chain(purchaseRule.preferredOrgSuppliers || [])
          .map('objectId')
          .includes(o.orgSupplier?.objectId || '')
          .value()
      );
    }

    const nonLastResortStock = _.chain(filteredOfferGroups)
      .filter(
        (o) =>
          !_.chain(purchaseRule.lastResortOrgSuppliers || [])
            .map('objectId')
            .includes(o.orgSupplier?.objectId || '')
            .value()
      )
      .sumBy('reportedStock')
      .value();

    if (nonLastResortStock > quant) {
      filteredOfferGroups = _.filter(
        filteredOfferGroups,
        (o) =>
          !_.chain(purchaseRule.lastResortOrgSuppliers || [])
            .map('objectId')
            .includes(o.orgSupplier?.objectId || '')
            .value()
      );
    }
  }

  return filteredOfferGroups;
};

const calcSortedBidsFromGroups = (
  offerGroups: DecoratedOfferGroup[],
  quant: number,
  addReeling: boolean
) =>
  _.chain(offerGroups)
    .map((og) => {
      if (og.offers.length < 2)
        return calcSortedBids(og.offers, quant, addReeling);

      const sortedBids = calcSortedBids(og.offers, quant);
      let quantToCover = Math.min(quant, og.reportedStock);
      let combinedPrice = 0;
      let combinedQuant = 0;
      let usedBids = [];
      for (const bid of sortedBids) {
        if (quantToCover > 0) {
          if (bid.bidQuant <= quantToCover) {
            quantToCover -= bid.bidQuant;
            combinedQuant += bid.bidQuant;
            combinedPrice += _.toNumber(bid.bidPrice);
            usedBids.push(bid);
          } else {
            const rebidBreak = calcBestOfferBreakPrice(
              bid.offer,
              quantToCover,
              false
            );
            const rebidQuant = rebidBreak?.purchaseQuant || 0;
            const rebidPrice = _.toNumber(rebidBreak?.totalPrice || 0);
            combinedPrice += _.toNumber(rebidPrice);
            combinedQuant += rebidQuant;
            quantToCover = 0;
            usedBids.push(bid);
          }
        } else {
          break;
        }
      }
      if (!quantToCover) {
        // check if a single offer could have covered the required quantity at a lower price
        for (const bid of usedBids) {
          if (bid.bidQuant >= quant) {
            combinedPrice = _.toNumber(bid.bidPrice);
            combinedQuant = bid.bidQuant;
            usedBids = [bid];
          }
        }
      }

      const addReelingPrice =
        addReeling && _.first(og.offers)?.customReelSku
          ? _.toNumber(og.orgSupplier?.customReelPrice || 0)
          : 0;

      return {
        bidQuant: combinedQuant,
        bidPrice: _.toNumber(combinedPrice) + addReelingPrice,
        unitPrice:
          (_.toNumber(combinedPrice) + addReelingPrice) / combinedQuant,
        offer: _.first(usedBids)?.offer as DecoratedOffer,
      };
    })
    .flatten()
    .sortBy('unitPrice')
    .value();

export const calcOffersToUseFromGroups = (request: {
  offerGroups: DecoratedOfferGroup[];
  quant: number;
  addReeling: boolean;
}) => {
  const sortedBids = calcSortedBidsFromGroups(
    request.offerGroups,
    request.quant,
    request.addReeling
  );

  let quantToCover = request.quant; // this will track how much of the request quant we still need to price a source for
  let totalPrice = 0;
  let usedBids = []; // this will track the bids used to calculate the total price
  let actualPurchaseQuant = 0;
  for (const bid of sortedBids) {
    if (quantToCover > 0) {
      if (bid.bidQuant <= quantToCover) {
        quantToCover -= bid.bidQuant;
        actualPurchaseQuant += bid.bidQuant;
        totalPrice += bid.bidPrice;
        usedBids.push(bid);
      } else {
        const rebidBreak = calcBestOfferBreakPrice(
          bid.offer,
          quantToCover,
          false
        );
        const rebidQuant = rebidBreak?.purchaseQuant || 0;
        const rebidPrice = rebidBreak?.totalPrice || 0;
        totalPrice += rebidPrice;
        actualPurchaseQuant += rebidQuant;
        quantToCover = 0;
        usedBids.push(bid);
      }
    } else {
      break;
    }
  }
  if (!quantToCover) {
    // check if a single bid could have covered the required quantity at a lower price
    for (const bid of usedBids) {
      if (bid.bidQuant >= request.quant) {
        totalPrice = bid.bidPrice;
        actualPurchaseQuant = bid.bidQuant;
        usedBids = [bid];
      }
    }

    return {
      totalPrice,
      offers: _.map(usedBids, 'offer'),
      actualPurchaseQuant,
      unitPrice: totalPrice / actualPurchaseQuant,
      isBackordered: _.first(_.map(usedBids, 'offer'))?.isBackordered,
    };
  }

  // fall back on using backorder offers
  const cheapestBackorderOffer = _.chain(request.offerGroups)
    .map('offers')
    .flatten()
    .compact()
    .filter(
      (o) =>
        (!!o.supplier.buyable || o.offerType === OfferType.QUOTE) &&
        o.prices.length > 0 &&
        (o.factoryLeadDays || 0) > 0
    )
    .sortBy((o) => calcOfferBreakPrice(o.prices, request.quant))
    .first()
    .value();

  if (cheapestBackorderOffer) {
    const bestBreak = calcBestOfferBreakPrice(
      cheapestBackorderOffer,
      request.quant,
      true
    );
    if (!bestBreak) return null;

    return {
      totalPrice: bestBreak.totalPrice,
      offers: [cheapestBackorderOffer],
      actualPurchaseQuant: bestBreak.purchaseQuant,
      unitPrice: bestBreak.unitPrice / bestBreak.purchaseQuant,
      isBackordered: true,
    };
  }
  return null;
};

export const calcFactoryLead = (request: { offers: (PartOffer | Offer)[] }) =>
  _.chain(request.offers).map('factoryLeadDays').min().value() || 0;
