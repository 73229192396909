import { URLS } from 'store/constant';
import { apiSlice } from 'store/slices/api';
import { DateString } from 'types';
import { StockDocument } from 'types/inventory';
import {
  CofactrPurchaseCharge,
  CreateUpdateCofactrPurchaseCharge,
  CreateUpdateNoPartPurchaseLine,
  CreateUpdatePurchaseOrder,
  CreateUpdatePurchaseOrderLine,
  CreateUpdatePurchaseRequest,
  GenerateDocumentPdf,
  NoPartPurchaseLine,
  PurchaseEvent,
  PurchaseOrder,
  PurchaseOrderLine,
  PurchaseOrderLineWithPurchase,
  PurchaseRequest,
  SendPurchaseOrder,
  SendPurchaseOrderResponse,
} from 'types/procurement';
import { ExpandedPurchaseLine, PurchaseLite } from 'types/purchasing';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPurchaseRequests: builder.query<
      PurchaseRequest[],
      {
        productionRunId?: string;
        part?: string;
        voided?: boolean;
        open?: boolean;
        archived?: boolean;
        requested?: boolean;
        purchased?: boolean;
        fromDate?: DateString;
        toDate?: DateString;
      }
    >({
      query: ({
        productionRunId,
        part,
        voided,
        open,
        requested,
        archived,
        purchased,
        fromDate,
        toDate,
      }) =>
        URLS.PURCHASE_REQUESTS({
          productionRunId,
          part,
          voided,
          open,
          requested,
          archived,
          purchased,
          fromDate,
          toDate,
        }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: PurchaseRequest) =>
                  ({ type: 'PurchaseRequests', id } as const)
              ),
              { type: 'PurchaseRequests', id: 'LIST' },
            ]
          : [{ type: 'PurchaseRequests', id: 'LIST' }],
    }),
    getPurchaseRequest: builder.query<
      PurchaseRequest,
      {
        purchaseRequestId: PurchaseRequest['id'];
      }
    >({
      query: ({ purchaseRequestId }) =>
        URLS.PURCHASE_REQUEST(purchaseRequestId),
      providesTags: (result) =>
        result
          ? [{ type: 'PurchaseRequests', id: result.id }]
          : [{ type: 'PurchaseRequests', id: 'LIST' }],
    }),
    createPurchaseRequests: builder.mutation<
      PurchaseRequest[],
      CreateUpdatePurchaseRequest[]
    >({
      query: (payload) => ({
        url: URLS.PURCHASE_REQUESTS({}),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'PurchaseRequests', id: 'LIST' }],
    }),
    updatePurchaseRequests: builder.mutation<
      PurchaseRequest,
      {
        purchaseRequestId: PurchaseRequest['id'];
        payload: CreateUpdatePurchaseRequest;
      }
    >({
      query: ({ purchaseRequestId, payload }) => ({
        url: URLS.PURCHASE_REQUEST(purchaseRequestId),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { purchaseRequestId }) => [
        {
          type: 'PurchaseRequests',
          id: purchaseRequestId,
        },
      ],
    }),
    bulkUpdatePurchaseRequests: builder.mutation<
      PurchaseRequest[],
      {
        payload: CreateUpdatePurchaseRequest[];
      }
    >({
      query: ({ payload }) => ({
        url: URLS.PURCHASE_REQUESTS({}),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error) => [
        ...(result
          ? result.map(({ id }: PurchaseRequest) => ({
              type: 'PurchaseRequests' as 'PurchaseRequests',
              id,
            }))
          : [{ type: 'PurchaseRequests' as 'PurchaseRequests', id: 'LIST' }]),
      ],
    }),
    getPurchaseOrders: builder.query<
      PurchaseOrder[],
      { draft: boolean; orgSupplierId?: string }
    >({
      query: ({ draft, orgSupplierId }) =>
        URLS.PURCHASE_ORDERS(draft, orgSupplierId),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: PurchaseOrder) =>
                  ({ type: 'PurchaseOrders', id } as const)
              ),
              { type: 'PurchaseOrders', id: 'LIST' },
            ]
          : [{ type: 'PurchaseOrders', id: 'LIST' }],
    }),
    getPurchaseOrder: builder.query<
      PurchaseOrder,
      {
        purchaseOrderId: PurchaseOrder['id'];
      }
    >({
      query: ({ purchaseOrderId }) => URLS.PURCHASE_ORDER(purchaseOrderId),
      providesTags: (result) =>
        result
          ? [{ type: 'PurchaseOrders', id: result.id }]
          : [{ type: 'PurchaseOrders', id: 'LIST' }],
    }),
    createPurchaseOrder: builder.mutation<
      PurchaseOrder,
      CreateUpdatePurchaseOrder
    >({
      query: (payload) => ({
        url: URLS.PURCHASE_ORDERS(false),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'PurchaseOrders', id: 'LIST' }],
    }),
    updatePurchaseOrder: builder.mutation<
      PurchaseOrder,
      {
        purchaseOrderId: PurchaseOrder['id'];
        payload: CreateUpdatePurchaseOrder;
      }
    >({
      query: ({ purchaseOrderId, payload }) => ({
        url: URLS.PURCHASE_ORDER(purchaseOrderId),
        method: 'PATCH',
        body: payload,
      }),
      // pessimistic update
      async onQueryStarted(
        { purchaseOrderId, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const { data: updatedRecord } = await queryFulfilled;
        dispatch(
          extendedApiSlice.util.updateQueryData(
            'getPurchaseOrder',
            { purchaseOrderId },
            (draft) => {
              Object.assign(draft, updatedRecord);
            }
          )
        );
      },
      invalidatesTags: (result, error, { purchaseOrderId }) => [
        {
          type: 'PurchaseOrders',
          id: purchaseOrderId,
        },
        { type: 'PurchaseOrderLines', id: 'LIST' },
        { type: 'PurchaseOrderLinesWithPurchase', id: 'LIST' },
      ],
    }),
    sendPurchaseOrder: builder.mutation<
      SendPurchaseOrderResponse,
      {
        purchaseOrderId: PurchaseOrder['id'];
        payload: SendPurchaseOrder;
      }
    >({
      query: ({ purchaseOrderId, payload }) => ({
        url: URLS.PURCHASE_ORDER_SEND(purchaseOrderId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { purchaseOrderId }) => [
        {
          type: 'PurchaseOrders',
          id: purchaseOrderId,
        },
        { type: 'PurchaseOrderEvents', id: 'LIST' },
        { type: 'PurchaseOrderLines', id: 'LIST' },
        { type: 'PurchaseOrderLinesWithPurchase', id: 'LIST' },
      ],
    }),
    generatePurchaseOrderPdf: builder.mutation<
      StockDocument,
      {
        purchaseOrderId?: PurchaseOrder['id'];
        payload: GenerateDocumentPdf;
      }
    >({
      query: ({ purchaseOrderId, payload }) => ({
        url: URLS.PURCHASE_ORDER_GENERATE_PDF(purchaseOrderId || ''),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { purchaseOrderId }) => [
        {
          type: 'PurchaseOrders',
          id: purchaseOrderId,
        },
        {
          type: 'Document',
          id: 'LIST',
        },
      ],
    }),
    deletePurchaseOrder: builder.mutation<string, PurchaseOrder['id']>({
      query: (purchaseOrderId) => ({
        url: URLS.PURCHASE_ORDER(purchaseOrderId),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, purchaseOrderId) => [
        { type: 'PurchaseOrders', id: purchaseOrderId },
        { type: 'PurchaseOrders', id: 'LIST' },
        { type: 'PurchaseOrders', id: 'PARTIAL-LIST' },
      ],
    }),
    getAllPurchaseOrderLines: builder.query<
      PurchaseOrderLineWithPurchase[],
      {
        active?: boolean;
        part?: string;
        productionRunId?: string;
      }
    >({
      query: ({ active, part, productionRunId }) =>
        URLS.ALL_PURCHASE_LINES({
          part,
          productionRunId,
          active: active === undefined ? true : active,
        }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: PurchaseOrderLineWithPurchase) =>
                  ({ type: 'PurchaseOrderLinesWithPurchase', id } as const)
              ),
              { type: 'PurchaseOrderLinesWithPurchase', id: 'LIST' },
            ]
          : [{ type: 'PurchaseOrderLinesWithPurchase', id: 'LIST' }],
    }),
    getPurchaseOrderLines: builder.query<
      PurchaseOrderLine[],
      {
        purchaseOrderId: PurchaseOrder['id'];
      }
    >({
      query: ({ purchaseOrderId }) =>
        URLS.PURCHASE_ORDER_LINES(purchaseOrderId),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: PurchaseOrderLine) =>
                  ({ type: 'PurchaseOrderLines', id } as const)
              ),
              { type: 'PurchaseOrderLines', id: 'LIST' },
            ]
          : [{ type: 'PurchaseOrderLines', id: 'LIST' }],
    }),
    markPurchaseLineUnshipped: builder.mutation<
      ExpandedPurchaseLine,
      {
        purchaseId: PurchaseLite['id'];
        purchaseLineId: ExpandedPurchaseLine['id'];
      }
    >({
      query: ({ purchaseId, purchaseLineId }) => ({
        url: URLS.PURCHASE_LINE_UNSHIP(purchaseId, purchaseLineId),
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'PurchaseOrderLines', id: 'LIST' }],
    }),
    createPurchaseOrderLines: builder.mutation<
      PurchaseOrderLine[],
      {
        purchaseOrderId: PurchaseOrder['id'];
        payload: CreateUpdatePurchaseOrderLine[];
      }
    >({
      query: ({ purchaseOrderId, payload }) => ({
        url: URLS.PURCHASE_ORDER_LINES(purchaseOrderId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { purchaseOrderId }) => [
        { type: 'PurchaseOrders', id: purchaseOrderId },
        { type: 'PurchaseOrderLines', id: 'LIST' },
        { type: 'PurchaseOrderLinesWithPurchase', id: 'LIST' },
        { type: 'PurchaseRequests', id: 'LIST' },
      ],
    }),
    getPurchaseOrderLine: builder.query<
      PurchaseOrderLine,
      {
        purchaseOrderId: PurchaseOrder['id'];
        purchaseOrderLineId: PurchaseOrderLine['id'];
      }
    >({
      query: ({ purchaseOrderId, purchaseOrderLineId }) =>
        URLS.PURCHASE_ORDER_LINE(purchaseOrderId, purchaseOrderLineId),
      providesTags: (result) =>
        result
          ? [{ type: 'PurchaseOrderLines', id: result.id }]
          : [{ type: 'PurchaseOrderLines', id: 'LIST' }],
    }),
    updatePurchaseOrderLine: builder.mutation<
      PurchaseOrderLine,
      {
        purchaseOrderId: PurchaseOrder['id'];
        purchaseOrderLineId: PurchaseOrderLine['id'];
        payload: CreateUpdatePurchaseOrder;
      }
    >({
      query: ({ purchaseOrderId, purchaseOrderLineId, payload }) => ({
        url: URLS.PURCHASE_ORDER_LINE(purchaseOrderId, purchaseOrderLineId),
        method: 'PATCH',
        body: payload,
      }),
      // pessimistic update
      async onQueryStarted(
        { purchaseOrderId, purchaseOrderLineId, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const { data: updatedRecord } = await queryFulfilled;
        dispatch(
          extendedApiSlice.util.updateQueryData(
            'getPurchaseOrderLine',
            { purchaseOrderId, purchaseOrderLineId },
            (draft) => {
              Object.assign(draft, updatedRecord);
            }
          )
        );
      },
      invalidatesTags: (
        result,
        error,
        { purchaseOrderId, purchaseOrderLineId }
      ) => [
        { type: 'PurchaseOrders', id: purchaseOrderId },
        {
          type: 'PurchaseOrderLines',
          id: purchaseOrderLineId,
        },
        { type: 'PurchaseOrderLinesWithPurchase', id: 'LIST' },
        { type: 'PurchaseRequests', id: 'LIST' },
      ],
    }),
    bulkUpdatePurchaseLines: builder.mutation<
      PurchaseOrderLine[],
      {
        purchaseOrderId: PurchaseOrder['id'];
        payload: CreateUpdatePurchaseOrderLine[];
      }
    >({
      query: ({ purchaseOrderId, payload }) => ({
        url: URLS.PURCHASE_ORDER_LINES(purchaseOrderId),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { purchaseOrderId }) => [
        { type: 'PurchaseOrders', id: purchaseOrderId },
        { type: 'PurchaseOrderLinesWithPurchase', id: 'LIST' },
        { type: 'PurchaseRequests', id: 'LIST' },
        ...(result
          ? result.map(({ id }: PurchaseOrderLine) => ({
              type: 'PurchaseOrderLines' as 'PurchaseOrderLines',
              id,
            }))
          : [
              {
                type: 'PurchaseOrderLines' as 'PurchaseOrderLines',
                id: 'LIST',
              },
            ]),
      ],
    }),
    deletePurchaseOrderLine: builder.mutation<
      string,
      {
        purchaseOrderId: PurchaseOrder['id'];
        purchaseOrderLineId: PurchaseOrderLine['id'];
      }
    >({
      query: ({ purchaseOrderId, purchaseOrderLineId }) => ({
        url: URLS.PURCHASE_ORDER_LINE(purchaseOrderId, purchaseOrderLineId),
        method: 'DELETE',
      }),
      invalidatesTags: (
        result,
        error,
        { purchaseOrderId, purchaseOrderLineId }
      ) => [
        { type: 'PurchaseOrders', id: purchaseOrderId },
        { type: 'PurchaseOrderLines', id: purchaseOrderLineId },
        { type: 'PurchaseOrderLines', id: 'LIST' },
        { type: 'PurchaseOrderLines', id: 'PARTIAL-LIST' },
        { type: 'PurchaseOrderLinesWithPurchase', id: 'LIST' },
        { type: 'PurchaseRequests', id: 'LIST' },
      ],
    }),
    getPurchaseOrderNoPartLines: builder.query<
      NoPartPurchaseLine[],
      {
        purchaseOrderId: PurchaseOrder['id'];
      }
    >({
      query: ({ purchaseOrderId }) =>
        URLS.PURCHASE_ORDER_NO_PART_LINES(purchaseOrderId),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: NoPartPurchaseLine) =>
                  ({ type: 'PurchaseOrderNoPartLines', id } as const)
              ),
              { type: 'PurchaseOrderNoPartLines', id: 'LIST' },
            ]
          : [{ type: 'PurchaseOrderNoPartLines', id: 'LIST' }],
    }),
    createPurchaseOrderNoPartLines: builder.mutation<
      NoPartPurchaseLine[],
      {
        purchaseOrderId: PurchaseOrder['id'];
        payload: CreateUpdateNoPartPurchaseLine[];
      }
    >({
      query: ({ purchaseOrderId, payload }) => ({
        url: URLS.PURCHASE_ORDER_NO_PART_LINES(purchaseOrderId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { purchaseOrderId }) => [
        { type: 'PurchaseOrders', id: purchaseOrderId },
        { type: 'PurchaseOrderNoPartLines', id: 'LIST' },
      ],
    }),
    getPurchaseOrderNoPartLine: builder.query<
      NoPartPurchaseLine,
      {
        purchaseOrderId: PurchaseOrder['id'];
        purchaseOrderNoPartLineId: NoPartPurchaseLine['id'];
      }
    >({
      query: ({ purchaseOrderId, purchaseOrderNoPartLineId }) =>
        URLS.PURCHASE_ORDER_NO_PART_LINE(
          purchaseOrderId,
          purchaseOrderNoPartLineId
        ),
      providesTags: (result) =>
        result
          ? [{ type: 'PurchaseOrderNoPartLines', id: result.id }]
          : [{ type: 'PurchaseOrderNoPartLines', id: 'LIST' }],
    }),
    updatePurchaseOrderNoPartLine: builder.mutation<
      NoPartPurchaseLine,
      {
        purchaseOrderId: PurchaseOrder['id'];
        purchaseOrderNoPartLineId: NoPartPurchaseLine['id'];
        payload: CreateUpdateNoPartPurchaseLine;
      }
    >({
      query: ({ purchaseOrderId, purchaseOrderNoPartLineId, payload }) => ({
        url: URLS.PURCHASE_ORDER_NO_PART_LINE(
          purchaseOrderId,
          purchaseOrderNoPartLineId
        ),
        method: 'PATCH',
        body: payload,
      }),
      // pessimistic update
      async onQueryStarted(
        { purchaseOrderId, purchaseOrderNoPartLineId, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const { data: updatedRecord } = await queryFulfilled;
        dispatch(
          extendedApiSlice.util.updateQueryData(
            'getPurchaseOrderNoPartLine',
            { purchaseOrderId, purchaseOrderNoPartLineId },
            (draft) => {
              Object.assign(draft, updatedRecord);
            }
          )
        );
      },
      invalidatesTags: (
        result,
        error,
        { purchaseOrderId, purchaseOrderNoPartLineId }
      ) => [
        { type: 'PurchaseOrders', id: purchaseOrderId },
        {
          type: 'PurchaseOrderNoPartLines',
          id: purchaseOrderNoPartLineId,
        },
      ],
    }),
    bulkUpdatePurchaseNoPartLines: builder.mutation<
      NoPartPurchaseLine[],
      {
        purchaseOrderId: PurchaseOrder['id'];
        payload: CreateUpdateNoPartPurchaseLine[];
      }
    >({
      query: ({ purchaseOrderId, payload }) => ({
        url: URLS.PURCHASE_ORDER_NO_PART_LINES(purchaseOrderId),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { purchaseOrderId }) => [
        { type: 'PurchaseOrders', id: purchaseOrderId },
        ...(result
          ? result.map(({ id }: NoPartPurchaseLine) => ({
              type: 'PurchaseOrderNoPartLines' as 'PurchaseOrderNoPartLines',
              id,
            }))
          : [
              {
                type: 'PurchaseOrderNoPartLines' as 'PurchaseOrderNoPartLines',
                id: 'LIST',
              },
            ]),
      ],
    }),
    deletePurchaseOrderNoPartLine: builder.mutation<
      string,
      {
        purchaseOrderId: PurchaseOrder['id'];
        purchaseOrderNoPartLineId: NoPartPurchaseLine['id'];
      }
    >({
      query: ({ purchaseOrderId, purchaseOrderNoPartLineId }) => ({
        url: URLS.PURCHASE_ORDER_NO_PART_LINE(
          purchaseOrderId,
          purchaseOrderNoPartLineId
        ),
        method: 'DELETE',
      }),
      invalidatesTags: (
        result,
        error,
        { purchaseOrderId, purchaseOrderNoPartLineId }
      ) => [
        { type: 'PurchaseOrders', id: purchaseOrderId },
        { type: 'PurchaseOrderNoPartLines', id: purchaseOrderNoPartLineId },
        { type: 'PurchaseOrderNoPartLines', id: 'LIST' },
        { type: 'PurchaseOrderNoPartLines', id: 'PARTIAL-LIST' },
      ],
    }),
    getPurchaseOrderCharges: builder.query<
      CofactrPurchaseCharge[],
      {
        purchaseOrderId: PurchaseOrder['id'];
      }
    >({
      query: ({ purchaseOrderId }) =>
        URLS.PURCHASE_ORDER_PURCHASE_CHARGES(purchaseOrderId),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: CofactrPurchaseCharge) =>
                  ({ type: 'PurchaseOrderCofactrCharges', id } as const)
              ),
              { type: 'PurchaseOrderCofactrCharges', id: 'LIST' },
            ]
          : [{ type: 'PurchaseOrderCofactrCharges', id: 'LIST' }],
    }),
    createPurchaseOrderCharges: builder.mutation<
      CofactrPurchaseCharge[],
      {
        purchaseOrderId: PurchaseOrder['id'];
        payload: CreateUpdateCofactrPurchaseCharge[];
      }
    >({
      query: ({ purchaseOrderId, payload }) => ({
        url: URLS.PURCHASE_ORDER_PURCHASE_CHARGES(purchaseOrderId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { purchaseOrderId }) => [
        { type: 'PurchaseOrders', id: purchaseOrderId },
        { type: 'PurchaseOrderCofactrCharges', id: 'LIST' },
      ],
    }),
    getPurchaseOrderCharge: builder.query<
      CofactrPurchaseCharge,
      {
        purchaseOrderId: PurchaseOrder['id'];
        purchaseOrderPurchaseChargeId: CofactrPurchaseCharge['id'];
      }
    >({
      query: ({ purchaseOrderId, purchaseOrderPurchaseChargeId }) =>
        URLS.PURCHASE_ORDER_PURCHASE_CHARGE(
          purchaseOrderId,
          purchaseOrderPurchaseChargeId
        ),
      providesTags: (result) =>
        result
          ? [{ type: 'PurchaseOrderCofactrCharges', id: result.id }]
          : [{ type: 'PurchaseOrderCofactrCharges', id: 'LIST' }],
    }),
    updatePurchaseOrderCharge: builder.mutation<
      CofactrPurchaseCharge,
      {
        purchaseOrderId: PurchaseOrder['id'];
        purchaseOrderPurchaseChargeId: CofactrPurchaseCharge['id'];
        payload: CreateUpdateCofactrPurchaseCharge;
      }
    >({
      query: ({ purchaseOrderId, purchaseOrderPurchaseChargeId, payload }) => ({
        url: URLS.PURCHASE_ORDER_PURCHASE_CHARGE(
          purchaseOrderId,
          purchaseOrderPurchaseChargeId
        ),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (
        result,
        error,
        { purchaseOrderId, purchaseOrderPurchaseChargeId }
      ) => [
        { type: 'PurchaseOrders', id: purchaseOrderId },
        {
          type: 'PurchaseOrderCofactrCharges',
          id: purchaseOrderPurchaseChargeId,
        },
      ],
    }),
    bulkUpdatePurchaseCharges: builder.mutation<
      CofactrPurchaseCharge[],
      {
        purchaseOrderId: PurchaseOrder['id'];
        payload: CreateUpdateCofactrPurchaseCharge[];
      }
    >({
      query: ({ purchaseOrderId, payload }) => ({
        url: URLS.PURCHASE_ORDER_PURCHASE_CHARGES(purchaseOrderId),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { purchaseOrderId }) => [
        { type: 'PurchaseOrders', id: purchaseOrderId },
        ...(result
          ? result.map(({ id }: CofactrPurchaseCharge) => ({
              type: 'PurchaseOrderCofactrCharges' as 'PurchaseOrderCofactrCharges',
              id,
            }))
          : [
              {
                type: 'PurchaseOrderCofactrCharges' as 'PurchaseOrderCofactrCharges',
                id: 'LIST',
              },
            ]),
      ],
    }),
    deletePurchaseOrderCharge: builder.mutation<
      string,
      {
        purchaseOrderId: PurchaseOrder['id'];
        purchaseOrderPurchaseChargeId: CofactrPurchaseCharge['id'];
      }
    >({
      query: ({ purchaseOrderId, purchaseOrderPurchaseChargeId }) => ({
        url: URLS.PURCHASE_ORDER_PURCHASE_CHARGE(
          purchaseOrderId,
          purchaseOrderPurchaseChargeId
        ),
        method: 'DELETE',
      }),
      invalidatesTags: (
        result,
        error,
        { purchaseOrderId, purchaseOrderPurchaseChargeId }
      ) => [
        { type: 'PurchaseOrders', id: purchaseOrderId },
        {
          type: 'PurchaseOrderCofactrCharges',
          id: purchaseOrderPurchaseChargeId,
        },
        { type: 'PurchaseOrderCofactrCharges', id: 'LIST' },
        { type: 'PurchaseOrderCofactrCharges', id: 'PARTIAL-LIST' },
      ],
    }),
    getPurchaseOrderEvents: builder.query<
      PurchaseEvent[],
      {
        purchaseOrderId: PurchaseOrder['id'];
      }
    >({
      query: ({ purchaseOrderId }) =>
        URLS.PURCHASE_ORDER_EVENTS(purchaseOrderId),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: PurchaseEvent) =>
                  ({ type: 'PurchaseOrderEvents', id } as const)
              ),
              { type: 'PurchaseOrderEvents', id: 'LIST' },
            ]
          : [{ type: 'PurchaseOrderEvents', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetPurchaseRequestsQuery,
  useLazyGetPurchaseRequestQuery,
  useCreatePurchaseRequestsMutation,
  useUpdatePurchaseRequestsMutation,
  useBulkUpdatePurchaseRequestsMutation,
  useGetPurchaseOrdersQuery,
  useGetPurchaseOrderQuery,
  useLazyGetPurchaseOrderQuery,
  useCreatePurchaseOrderMutation,
  useUpdatePurchaseOrderMutation,
  useSendPurchaseOrderMutation,
  useGeneratePurchaseOrderPdfMutation,
  useDeletePurchaseOrderMutation,
  useGetAllPurchaseOrderLinesQuery,
  useGetPurchaseOrderLinesQuery,
  useCreatePurchaseOrderLinesMutation,
  useGetPurchaseOrderLineQuery,
  useUpdatePurchaseOrderLineMutation,
  useBulkUpdatePurchaseLinesMutation,
  useDeletePurchaseOrderLineMutation,
  useGetPurchaseOrderNoPartLinesQuery,
  useCreatePurchaseOrderNoPartLinesMutation,
  useGetPurchaseOrderNoPartLineQuery,
  useUpdatePurchaseOrderNoPartLineMutation,
  useBulkUpdatePurchaseNoPartLinesMutation,
  useDeletePurchaseOrderNoPartLineMutation,
  useGetPurchaseOrderChargesQuery,
  useCreatePurchaseOrderChargesMutation,
  useGetPurchaseOrderChargeQuery,
  useUpdatePurchaseOrderChargeMutation,
  useBulkUpdatePurchaseChargesMutation,
  useDeletePurchaseOrderChargeMutation,
  useGetPurchaseOrderEventsQuery,
  useMarkPurchaseLineUnshippedMutation,
} = extendedApiSlice;
