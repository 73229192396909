import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import * as Yup from 'yup';
import { MutableRefObject, ReactElement } from 'react';
import { PermissionScope } from 'types/apps';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import MutationCellWithInput from 'ui-component/DataGrid/Cell/MutationCellWithInput';
import { FieldValues } from 'react-hook-form';

export type RenderMutationCellWithInputProps = {
  params: GridRenderCellParams;
  apiRef: MutableRefObject<GridApiPremium>;
  InputElement?: ReactElement;
  validation?: Yup.SchemaOf<unknown, object>;
  permissionScope?: PermissionScope;
  // the `any` here seems to be the type used in the RTK Query type definitions
  useMutation: UseMutation<any>;
  getMutationArgs?: (data: FieldValues) => {};
  preSubmit?: (data: FieldValues, params: GridRenderCellParams) => FieldValues;
  defaultValue?: unknown;
  additionalInputElementProps?: unknown;
  tooltip?: string;
  clientV2?: boolean;
};

export function renderMutationCellWithInput({
  params,
  apiRef,
  InputElement,
  validation,
  permissionScope,
  useMutation,
  getMutationArgs,
  preSubmit,
  defaultValue,
  additionalInputElementProps,
  tooltip,
  clientV2,
}: RenderMutationCellWithInputProps) {
  return (
    <MutationCellWithInput
      params={params}
      apiRef={apiRef}
      InputElement={InputElement}
      validation={validation}
      permissionScope={permissionScope}
      useMutation={useMutation}
      getMutationArgs={getMutationArgs}
      preSubmit={preSubmit}
      defaultValue={defaultValue}
      additionalInputElementProps={additionalInputElementProps}
      tooltip={tooltip}
      clientV2={clientV2}
    />
  );
}
