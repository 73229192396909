import { ALL_APPS } from 'constants/appConstants';
import {
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import {
  BooleanIconRenderCell,
  renderQuantity,
  RenderStyledText,
  RenderWithCopyButton,
} from 'ui-component/DataGrid/Render';
import { Box } from '@mui/material';
import {
  dateValueFormatter,
  replaceStringWithHyphenValueFormatter,
} from 'ui-component/DataGrid/ValueFormatters';
import { dateValueGetter } from 'ui-component/DataGrid/ValueGetters';
import { formatToShortDate, isBeforeNowDate } from 'utils/dates';
import { MslValueOptions, packageValueOptions } from 'types/inventory';
import _ from 'lodash';
import {
  booleanFilterOperators,
  booleanValueOptions,
  PositiveNumberFilterOperators,
} from 'ui-component/DataGrid/FilterOperators';
import { IconAlertOctagon } from '@tabler/icons-react';

export const columns = [
  {
    field: 'allocatedQuantity',
    headerName: 'Allocated Quantity',
    description: 'Current unit quantity allocated from this stock lot',
    type: 'number',
    hideable: false,
    minWidth: 50,
    flex: 0.3,
    renderCell: ({ value }: GridRenderCellParams<string>) => value || 0,
  },
  {
    field: 'shippedQuant',
    headerName: 'Shipped Quantity',
    description: 'Current unit quantity shipped from this stock lot',
    type: 'number',
    hideable: false,
    minWidth: 50,
    flex: 0.3,
    renderCell: ({ value }: GridRenderCellParams<string>) => value || 0,
  },
  {
    field: 'quant',
    headerName: 'Stock Lot Quantity',
    description: 'Current unit quantity in this stock lot',
    type: 'number',
    hideable: true,
    minWidth: 100,
    flex: 0.5,
    filterOperators: PositiveNumberFilterOperators,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderQuantity(params.row.quant || 0, params.row.quantCertain),
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'locationDisplay',
    headerName: 'Location',
    description: 'Location of this stock lot',
    type: 'string',
    hideable: true,
    minWidth: 150,
    flex: 1,
    permissionScope: { app: ALL_APPS.KITTING.id },
    allowInBlended: true,
  },
  {
    field: 'expectedDate',
    headerName: 'Expected Delivery',
    description: 'Expected delivery date of expected stock lots',
    type: 'date',
    hideable: true,
    minWidth: 100,
    valueFormatter: dateValueFormatter,
    valueGetter: dateValueGetter,
    flex: 1,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'expiresAt',
    headerName: 'Expiration Date',
    description: 'Expiration date of life-limited stock lots',
    type: 'date',
    hideable: true,
    minWidth: 100,
    valueFormatter: dateValueFormatter,
    valueGetter: dateValueGetter,
    renderCell: (params: GridRenderCellParams<string>) => {
      const isExpired = params.value && isBeforeNowDate(params.value);
      const formattedDate = params.value && formatToShortDate(params.value);
      return RenderStyledText(
        formattedDate || '',
        isExpired && formattedDate
          ? `Stock lot expired on ${formattedDate}`
          : undefined,
        isExpired && formattedDate ? 'error' : undefined
      );
    },
    flex: 1,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'bonded',
    headerName: 'Re-Export By',
    description:
      'Date by which a stock lot imported under a temporary import bond must be re-exported',
    type: 'date',
    hideable: true,
    minWidth: 100,
    valueFormatter: dateValueFormatter,
    valueGetter: dateValueGetter,
    flex: 1,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    description: 'Date that this stock lot record was created',
    type: 'date',
    hideable: true,
    minWidth: 100,
    valueFormatter: dateValueFormatter,
    valueGetter: dateValueGetter,
    flex: 1,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'package',
    headerName: 'Package',
    description: 'Indicates how this stock lot is packaged',
    type: 'singleSelect',
    hideable: true,
    minWidth: 100,
    valueGetter: (params: GridValueGetterParams) => params.row.package || '-',
    valueOptions: packageValueOptions,
    valueFormatter: ({ value }: GridValueFormatterParams) => {
      const option = _.find(packageValueOptions, ['value', value]);
      if (option) {
        return option.label;
      }
      return '-';
    },
    flex: 0.5,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'dateCode',
    headerName: 'Date Code',
    description: 'Manufacturer’s date code for this stock lot',
    type: 'string',
    hideable: true,
    minWidth: 100,
    valueFormatter: replaceStringWithHyphenValueFormatter,
    flex: 0.5,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'mfgLot',
    headerName: 'Manufacturer Lot ID',
    description: 'Manufacturer’s lot ID or serial for this stock lot',
    type: 'string',
    hideable: true,
    minWidth: 100,
    valueFormatter: replaceStringWithHyphenValueFormatter,
    flex: 0.5,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'lotId',
    headerName: 'Cofactr Lot ID',
    description: 'Cofactr’s unique ID for this stock lot',
    type: 'string',
    hideable: true,
    minWidth: 180,
    flex: 0.5,
    permissionScope: { app: ALL_APPS.STOCK.id },
    renderCell: (params: GridRenderCellParams) =>
      RenderWithCopyButton(
        <>
          {params.row.part as string}
          <Box component="span" fontWeight="bold">
            {params.row.lotId as string}
          </Box>
        </>,
        params.row.lotId as string,
        'lot ID',
        `${ALL_APPS.STOCK.views?.STOCK_LOTS?.path}/${params.row.id}`,
        'Stock Lot'
      ),
  },
  {
    field: 'quarantined',
    headerName: 'Quarantined',
    description:
      'Indicates whether this stock lot has been quarantined due to a suspected issue',
    type: 'boolean',
    minWidth: 60,
    flex: 0.2,
    hideable: true,
    filterOperators: booleanFilterOperators,
    valueOptions: booleanValueOptions,
    renderCell: (params: GridRenderCellParams) =>
      BooleanIconRenderCell(params, IconAlertOctagon, null, 'error', 'primary'),
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'msl',
    headerName: 'MSL',
    description: 'Moisture sensitivity level of this part',
    type: 'singleSelect',
    valueOptions: MslValueOptions,
    minWidth: 60,
    valueFormatter: replaceStringWithHyphenValueFormatter,
    flex: 0.2,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'countryOfOrigin',
    headerName: 'Country of Origin',
    description: 'Country of manufacturing origin',
    type: 'string',
    hideable: true,
    minWidth: 60,
    valueFormatter: replaceStringWithHyphenValueFormatter,
    flex: 0.2,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'notes',
    headerName: 'Notes',
    description: 'Notes associated with stock lot',
    type: 'string',
    flex: 0.5,
    hideable: true,
    valueFormatter: replaceStringWithHyphenValueFormatter,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
];
