import { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import { FieldErrors } from 'react-hook-form';
import { useGetManufacturersAutocompleteQuery } from 'store/slices/part';
import { Manufacturer } from 'types/part';

type ManufacturerAutocompleteProps = {
  handleSetValue: (option: Manufacturer | null) => void;
  errors: FieldErrors;
  name: string;
  label: string;
  initialCustomValue?: string;
};

const ManufacturerAutocomplete = ({
  handleSetValue,
  errors,
  name,
  label,
  initialCustomValue,
}: ManufacturerAutocompleteProps) => {
  const [debouncedInput, setDebouncedInput] = useState('');
  const [value, setValue] = useState<Manufacturer>({
    label: initialCustomValue || '',
    id: initialCustomValue ? 'custom' : '',
  } as Manufacturer);

  const { data = [], isFetching } = useGetManufacturersAutocompleteQuery(
    debouncedInput,
    {
      skip: debouncedInput.length === 0,
    }
  );

  const options = [...data];
  !_.find(data, (o) => o.label === debouncedInput) &&
    options.push({ label: debouncedInput, id: 'custom' } as Manufacturer);

  useEffect(() => {
    initialCustomValue &&
      options.push({ label: initialCustomValue, id: 'custom' });
  }, [initialCustomValue]);

  const handleDebounce = useCallback(
    _.debounce((nextValue) => setDebouncedInput(nextValue), 1000),
    []
  );

  return (
    <Autocomplete
      options={options}
      onInputChange={(e, option) => {
        handleDebounce(option);
      }}
      onChange={(__, option) => {
        setValue(option as Manufacturer);
        handleSetValue(option);
      }}
      autoComplete
      autoSelect
      filterSelectedOptions
      value={value}
      getOptionLabel={(option) => option?.label}
      loading={isFetching}
      isOptionEqualToValue={(option) => option.id === value.id}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={Math.random()}>
          <MenuItem
            selected={selected}
            sx={{ fontStyle: option?.id === 'custom' ? 'italic' : 'normal' }}
          >
            {option?.label}
          </MenuItem>
        </li>
      )}
      renderInput={(params) => (
        <Box sx={{ mb: 2 }}>
          <TextField {...params} label={label} fullWidth />
          {errors?.[name]?.message && (
            <FormHelperText error id={`${name}Error`}>
              {errors?.[name]?.message}
            </FormHelperText>
          )}
        </Box>
      )}
    />
  );
};

export default ManufacturerAutocomplete;
