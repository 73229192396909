import PartAvatar from 'ui-component/extended/PartAvatar';
import { FC, memo } from 'react';
import { Grid, Tooltip, Typography } from '@mui/material';
import CopyClipboard from 'ui-component/extended/CopyClipboard';
import { Part, PartCore } from 'types/part';
import { StockLotCRUDSchema, StockLotLite } from 'types/inventory';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { ALL_APPS, ALL_FEATURE_IDS } from 'constants/appConstants';
import { useSelector } from 'store';
import isEqual from 'react-fast-compare';
import reactStringReplace from 'react-string-replace';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';

interface PartInfoWidgetProps {
  part: Part | PartCore | null;
  altCount?: number | null;
  stockLot?:
    | StockLotCRUDSchema
    | StockLotLite
    | null
    | StockLotCRUDSchema['id'];
  searchParams?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'badge' | 'xxl' | undefined;
  noPart?: string | null;
  unapprovedAltCount?: number | null;
  searchString?: string;
}

const PartInfoWidget: FC<PartInfoWidgetProps> = ({
  part,
  altCount,
  unapprovedAltCount,
  stockLot,
  searchParams,
  size = 'md',
  noPart,
  searchString,
}) => {
  const { isPermissionDisabled } = useAppAccessContext();
  const customPnsEnabled = !isPermissionDisabled({
    app: ALL_APPS.PART_LIBRARY.id,
    feature: ALL_FEATURE_IDS.CUSTOM_PNS,
  });
  const { activeOrg } = useSelector((state) => state.org);
  const theme = useTheme();

  const searchHighlightString = (input: string) => {
    if (!searchString) return input;
    const highlightRegex = _.chain(searchString)
      .split(' ')
      .compact()
      .map((s) => _.replace(s, /[.*+?^${}()|[\]\\]/g, '\\$&'))
      .join('|')
      .value();
    const replacedString = reactStringReplace(
      input,
      new RegExp(`(${highlightRegex})`, 'i'),
      (match, i) => (
        <span
          key={i}
          style={{ color: theme.palette.success.main, fontWeight: 'bold' }}
        >
          {match}
        </span>
      )
    );
    return replacedString;
  };

  return (
    <Grid container columnSpacing={1.5}>
      <Grid item xs="auto">
        <PartAvatar
          part={part}
          altCount={altCount}
          stockLot={stockLot}
          searchParams={searchParams}
          size={size}
          unapprovedAltCount={unapprovedAltCount}
        />
      </Grid>
      <Grid item xs sx={{ overflow: 'hidden' }}>
        <Typography variant="body1" textAlign="left">
          {noPart
            ? 'Unable to find match for:'
            : part?.mfg
            ? searchHighlightString(part.mfg)
            : '-'}
        </Typography>
        <Tooltip
          title={
            part?.altMpns && (part?.altMpns || []).length > 0
              ? `Other MPN Variants for this Part: ${part.altMpns.join(', ')}`
              : undefined
          }
        >
          <Typography variant="subtitle1" textAlign="left">
            {noPart || searchHighlightString(part?.mpn || '')}
            <CopyClipboard label="Part MPN" input={noPart || part?.mpn || ''} />
          </Typography>
        </Tooltip>
        {size !== 'sm' && customPnsEnabled && part?.customId && (
          <Typography variant="subtitle1" textAlign="left">
            {searchHighlightString(part?.customId)}
            <CopyClipboard
              label={`${activeOrg?.name || 'Custom'} Part Number`}
              input={part?.customId}
            />
          </Typography>
        )}
        {size !== 'sm' && (
          <Tooltip title={part?.description || ''}>
            <Typography variant="body1" textAlign="left">
              {searchHighlightString(part?.description || '')}
            </Typography>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

function partWidgetPropsAreEqual(
  prevProps: PartInfoWidgetProps,
  nextProps: PartInfoWidgetProps
) {
  if (prevProps.part?.mpn !== nextProps.part?.mpn) {
    return false;
  }
  if (prevProps.part?.customId !== nextProps.part?.customId) {
    return false;
  }
  if (prevProps.part?.id !== nextProps.part?.id) {
    return false;
  }
  if (prevProps.part?.mfg !== nextProps.part?.mfg) {
    return false;
  }
  if (prevProps.altCount !== nextProps.altCount) {
    return false;
  }
  if (isEqual(prevProps.stockLot, nextProps.stockLot)) {
    return false;
  }
  if (prevProps.searchParams !== nextProps.searchParams) {
    return false;
  }
  if (prevProps.unapprovedAltCount !== nextProps.unapprovedAltCount) {
    return false;
  }
  if (prevProps.noPart !== nextProps.noPart) {
    return false;
  }
  if (prevProps.size !== nextProps.size) {
    return false;
  }
  return true;
}

export default memo(PartInfoWidget, partWidgetPropsAreEqual);
