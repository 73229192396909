import {
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import { statusTextMapper } from 'constants/datagrid';
import {
  statusColorCallback,
  textPill,
} from 'ui-component/DataGrid/GridColTypeDef';
import { dateValueFormatter } from 'ui-component/DataGrid/ValueFormatters';
import { dateValueGetter } from 'ui-component/DataGrid/ValueGetters';

import { ALL_APPS } from 'constants/appConstants';
import { ExtendedGridColDef } from 'types/datagrid';
import {
  HandlingRequestType,
  HandlingRequestTypeLabels,
  MslValueOptions,
  packageValueOptions,
  tapeSizeOptions,
} from 'types/inventory';
import _ from 'lodash';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { RenderWithCopyButton } from 'ui-component/DataGrid/Render';
import { Box } from '@mui/material';
import { mslFloorLifeColumnText, mslRemainingFloorLife } from 'utils/functions';

export const columns: ExtendedGridColDef[] = [
  {
    field: 'quantity',
    headerName: 'Quantity',
    description: 'Current unit quantity in this stock lot',
    type: 'number',
    hideable: true,
    minWidth: 100,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.stockLot?.quant || 0,
    flex: 0.5,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'expectedBy',
    headerName: 'Expected Delivery',
    description: 'Expected delivery date of expected stock lots',
    type: 'date',
    hideable: true,
    minWidth: 100,
    valueFormatter: dateValueFormatter,
    valueGetter: dateValueGetter,
    flex: 1,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'package',
    headerName: 'Package',
    description: 'Indicates how this stock lot is packaged',
    type: 'string',
    hideable: true,
    minWidth: 100,
    valueOptions: packageValueOptions,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.stockLot?.package || '-',
    valueFormatter: ({ value }: GridValueFormatterParams) => {
      const option = _.find(packageValueOptions, ['value', value]);
      if (option) {
        return option.label;
      }
      return '-';
    },
    flex: 0.5,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'dateCode',
    headerName: 'Date Code',
    description: 'Manufacturer’s date code for this stock lot',
    type: 'string',
    hideable: true,
    minWidth: 100,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.stockLot?.dateCode || '-',
    flex: 0.5,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'mfgLot',
    headerName: 'Manufacturer Lot ID',
    description: 'Manufacturer’s lot ID or serial for this stock lot',
    type: 'string',
    hideable: true,
    minWidth: 100,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.stockLot?.mfgLot || '-',
    flex: 0.5,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'cofactrId',
    headerName: 'Cofactr Lot ID',
    description: 'Cofactr’s unique ID for this stock lot',
    type: 'string',
    hideable: true,
    minWidth: 180,
    valueGetter: (params: GridValueGetterParams) =>
      (typeof params.row.stockLot?.part === 'string'
        ? params.row.stockLot?.part
        : params.row.stockLot?.part.id
      ).concat(params.row.stockLot?.lotId) || '-',
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderWithCopyButton(
        <>
          {(params.value as string).slice(0, 12)}
          <Box component="span" fontWeight="bold">
            {(params.value as string).slice(-5)}
          </Box>
        </>,
        (params.value as string).slice(-5),
        'lot ID'
      ),
    flex: 0.5,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'msl',
    headerName: 'MSL',
    description: 'Moisture sensitivity level of this part',
    type: 'singleSelect',
    valueOptions: MslValueOptions,
    minWidth: 60,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.stockLot?.msl || '-',
    flex: 0.2,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'mslFloorHours',
    headerName: 'MSL Floor Life',
    description: 'Number of remaining hours of floor life before next bake',
    type: 'number',
    minWidth: 100,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) =>
      mslRemainingFloorLife(
        params.row.stockLot?.msl,
        params.row.stockLot?.mslFloorHours
      ),
    valueFormatter: (params: GridValueFormatterParams) =>
      mslFloorLifeColumnText(params.value),
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'tapeSize',
    headerName: 'Tape Size',
    description: 'Size of cut tape or reel that this stock lot is packaged in',
    type: 'singleSelect',
    valueOptions: tapeSizeOptions,
    minWidth: 60,
    flex: 0.2,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.stockLot?.tapeSize,
    valueFormatter: (params: GridValueFormatterParams) =>
      params.value ? `${params.value}mm` : '-',
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'status',
    headerName: 'Status',
    description: 'Status of this handling request',
    type: 'singleSelect',
    valueOptions: [
      'Draft',
      'Cancelled',
      'Requested',
      'In Progress',
      'Passed',
      'Failed',
      'Complete',
    ],
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) =>
      statusTextMapper[params.row.status as string],
    ...textPill(statusColorCallback),
    permissionScope: { app: ALL_APPS.MANAGED_INVENTORY.id },
  },
  {
    field: 'requestType',
    headerName: 'Request Type',
    description: 'Type of handling request',
    type: 'singleSelect',
    valueOptions: [
      ...Object.values(HandlingRequestType).map((type) => ({
        value: type,
        label: HandlingRequestTypeLabels[type],
      })),
      {
        value: 'user_create',
        label: 'User Created',
      },
    ],
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: ({ value }: GridValueFormatterParams) =>
      HandlingRequestTypeLabels[
        value as keyof typeof HandlingRequestTypeLabels
      ] || '-',
    permissionScope: { app: ALL_APPS.MANAGED_INVENTORY.id },
  },
  {
    field: 'submittedAt',
    headerName: 'Request Date',
    description: 'Date that request was created',
    type: 'date',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: dateValueFormatter,
    valueGetter: dateValueGetter,
    permissionScope: { app: ALL_APPS.MANAGED_INVENTORY.id },
  },
  {
    field: 'completedAt',
    headerName: 'Completion Date',
    description: 'Date request was completed or is expected to be completed',
    type: 'date',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: dateValueFormatter,
    valueGetter: dateValueGetter,
    permissionScope: { app: ALL_APPS.MANAGED_INVENTORY.id },
  },
  {
    field: 'notes',
    headerName: 'Notes',
    description: 'Notes about request',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) => params.row.notes || '-',
    permissionScope: { app: ALL_APPS.MANAGED_INVENTORY.id },
  },
];
