import { GridComparatorFn } from '@mui/x-data-grid-premium';
import _ from 'lodash';
import { User } from 'types/user';
import {
  stockStatus,
  multiSourcingStatus,
  factoryLeadDayStatus,
} from 'types/part';

// Elements used in Datagrid columns prop sortComparator

export const accuracyComparator: GridComparatorFn = (v1, v2, param) => {
  const isDesc = param.api.state.sorting.sortModel[0].sort === 'desc';
  const grades: { [key: string]: number } = {
    min: -1,
    'A+': 0,
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    F: 6,
    '': 7,
    max: 8,
  };

  const res1 = v1 === '' ? (isDesc ? grades.min : grades.max) : grades[v1];
  const res2 = v2 === '' ? (isDesc ? grades.min : grades.max) : grades[v2];

  return res1 - res2;
};

export const userNameComparator: GridComparatorFn = (v1: User, v2: User) =>
  (v1?.firstName || '') > (v2?.firstName || '') ? 1 : -1;

export const priceComparator: GridComparatorFn = (v1, v2, param) => {
  const isDesc = param.api.state.sorting.sortModel[0].sort === 'desc';
  const descCheck = isDesc ? -1 : Number.POSITIVE_INFINITY;
  const v1min = v1.min !== null ? v1.min : descCheck;
  const v1max = v1.max !== null ? v1.max : descCheck;
  const v2min = v2.min !== null ? v2.min : descCheck;
  const v2max = v2.max !== null ? v2.max : descCheck;

  return v1min - v2min || v1max - v2max;
};

export const buyableStockStatusComparator: GridComparatorFn = (
  v1,
  v2,
  param
) => {
  const isDesc = param.api.state.sorting.sortModel[0].sort === 'desc';
  const values: { [key: string]: number } = {
    min: -1,
    [stockStatus.SHORTAGE]: 0,
    [stockStatus.LIMITED]: 1,
    [stockStatus.OKAY]: 2,
    [stockStatus.STRONG]: 3,
    '': 4,
    max: 5,
  };

  const res1 = v1 === '' ? (isDesc ? values.min : values.max) : values[v1];
  const res2 = v2 === '' ? (isDesc ? values.min : values.max) : values[v2];

  return res1 - res2;
};

export const multiSourcingComparator: GridComparatorFn = (v1, v2, param) => {
  const isDesc = param.api.state.sorting.sortModel[0].sort === 'desc';
  const values: { [key: string]: number } = {
    min: -1,
    [multiSourcingStatus.TWO]: 0,
    [multiSourcingStatus.FIVE]: 1,
    [multiSourcingStatus.TEN]: 2,
    [multiSourcingStatus.MORE]: 3,
    '': 4,
    max: 5,
  };

  const res1 = v1 === '' ? (isDesc ? values.min : values.max) : values[v1];
  const res2 = v2 === '' ? (isDesc ? values.min : values.max) : values[v2];

  return res1 - res2;
};

export const medianFactoryLeadDaysComparator: GridComparatorFn = (
  v1,
  v2,
  param
) => {
  const isDesc = param.api.state.sorting.sortModel[0].sort === 'desc';
  const values: { [key: string]: number } = {
    min: -1,
    [factoryLeadDayStatus.THREE_MONTHS]: 0,
    [factoryLeadDayStatus.SIX_MONTHS]: 1,
    [factoryLeadDayStatus.ONE_YEAR]: 2,
    [factoryLeadDayStatus.MORE]: 3,
    '': 4,
    max: 5,
  };

  const res1 = v1 === '' ? (isDesc ? values.min : values.max) : values[v1];
  const res2 = v2 === '' ? (isDesc ? values.min : values.max) : values[v2];

  return res1 - res2;
};

export const relatedRecordsComparator: GridComparatorFn = (
  v1: { name: string }[],
  v2: { name: string }[],
  param
) => {
  const isDesc = param.api.state.sorting.sortModel[0]?.sort === 'desc' ?? false;
  if ((!v1 || v1.length === 0) && (!v2 || v2.length === 0)) return 0;
  if (!v1 || v1.length === 0) return isDesc ? -1 : 1;
  if (!v2 || v2.length === 0) return isDesc ? 1 : -1;

  const v1Names = _.map(v1, 'name').join(' ').toLowerCase();
  const v2Names = _.map(v2, 'name').join(' ').toLowerCase();

  return v1Names > v2Names ? 1 : -1;
};

export const tagsComparator: GridComparatorFn = (
  v1: { name: string }[],
  v2: { name: string }[],
  param
) => {
  const isDesc = param.api.state.sorting.sortModel[0]?.sort === 'desc' ?? false;
  if ((!v1 || v1.length === 0) && (!v2 || v2.length === 0)) return 0;
  if (!v1 || v1.length === 0) return isDesc ? -1 : 1;
  if (!v2 || v2.length === 0) return isDesc ? 1 : -1;

  const v1Names = _.map(v1, 'name').join(' ').toLowerCase();
  const v2Names = _.map(v2, 'name').join(' ').toLowerCase();

  return v1Names > v2Names ? 1 : -1;
};
