import { ChipColor, chipProps } from '.';
import { useTheme, Theme } from '@mui/material/styles';
import MuiChip from '@mui/material/Chip';
import { useMemo } from 'react';
import _ from 'lodash';

interface ProgressChipProps extends chipProps {
  sections: {
    chipColor: ChipColor;
    amount: number;
  }[];
}

const createHardGradient = (
  sections: ProgressChipProps['sections'],
  colorType: 'light' | 'main',
  theme: Theme
) => {
  const total = _.sumBy(sections, 'amount');
  let accumulatedPercentage = 0;
  return sections
    .map((section, index) => {
      const startPercentage = accumulatedPercentage;
      accumulatedPercentage += (section.amount / total) * 100;
      const endPercentage = accumulatedPercentage;
      const color = _.get(theme.palette, [section.chipColor, colorType]);
      return index === sections.length - 1
        ? `${color} ${startPercentage}%`
        : `${color} ${startPercentage}%, ${color} ${endPercentage}%`;
    })
    .join(', ');
};

const ProgressChip = ({
  sections,
  ChipIcon,
  icon,
  ...props
}: ProgressChipProps) => {
  const theme = useTheme();

  const backgroundGradient = useMemo(
    () => createHardGradient(sections, 'light', theme),
    [sections]
  );
  const textGradient = useMemo(
    () => createHardGradient(sections, 'main', theme),
    [sections]
  );

  return (
    <MuiChip
      {...props}
      sx={{
        background: `linear-gradient(to right, ${backgroundGradient})`,
        '& .MuiChip-label': {
          background: `linear-gradient(to right, ${textGradient})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text',
          color: 'transparent',
        },
        ...props.sx,
      }}
      icon={
        ChipIcon ? (
          <ChipIcon
            color={_.get(theme.palette, [
              _.first(sections)?.chipColor || 'primary',
              'main',
            ])}
          />
        ) : (
          icon
        )
      }
    />
  );
};

export default ProgressChip;
