import { useCreateStockDocumentMutation } from 'store/slices/inventory';
import {
  Shipment,
  StockDocType,
  StockLotCRUDSchema,
  StockLotLite,
} from 'types/inventory';
import _ from 'lodash';
import { PropertySubsetValidator } from 'types/api';
import { PurchaseLite } from 'types/purchasing';
import { BomLite } from 'types/bom';
import { ProductionRun } from 'types/production';
import { KitRequestSchema } from 'types/kitting';

interface StockDocData {
  img?: string;
  file?: File;
  documentType: StockDocType;
  stockLots?: PropertySubsetValidator<
    StockLotCRUDSchema | StockLotLite,
    'id' | 'lotId'
  >[];
  stockLotId?: StockLotLite['id'];
  purchaseId?: PurchaseLite['id'];
  bomId?: BomLite['id'];
  productionRunId?: ProductionRun['id'];
  shipmentId?: Shipment['id'];
  kitRequestId?: KitRequestSchema['id'];
  filename?: string;
  nonBillable?: boolean;
  processDocument?: boolean;
  notes?: string;
}

export function useCreateStockDocs() {
  const [createStockDocument, { isLoading: isLoadingCreateStockDoc }] =
    useCreateStockDocumentMutation();

  const createStockDoc = async (d: StockDocData) => {
    const stockDocData = new FormData();
    stockDocData.append('document_type', d.documentType);
    stockDocData.append('shared_document', 'false');
    stockDocData.append('billable', d.nonBillable ? 'false' : 'true');
    if (d.notes) stockDocData.append('notes', d.notes);
    if (d.stockLots) {
      stockDocData.append(
        'stock_lot_ids',
        JSON.stringify(_.map(d.stockLots, 'id'))
      );
      stockDocData.append(
        'name',
        `${_.first(d.stockLots)?.lotId}-${d.documentType}`
      );
    }
    if (d.stockLotId)
      stockDocData.append('stock_lot_ids', JSON.stringify([d.stockLotId]));
    if (d.purchaseId) stockDocData.append('purchase_id', d.purchaseId);
    if (d.processDocument) stockDocData.append('process_document', 'true');
    if (!d.processDocument) stockDocData.append('process_document', 'false');
    if (d.bomId) stockDocData.append('bom_id', d.bomId);
    if (d.productionRunId)
      stockDocData.append('production_run_id', d.productionRunId);
    if (d.shipmentId) stockDocData.append('shipment_id', d.shipmentId);
    if (d.kitRequestId) stockDocData.append('kit_request_id', d.kitRequestId);
    if (d.filename && !d.stockLots) stockDocData.append('name', d.filename);

    if (d.img) {
      await fetch(d.img)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File(
            [blob],
            d.filename && !d.stockLots
              ? d.filename
              : `${_.first(d.stockLots)?.lotId}-${d.documentType}.jpeg`
          );
          stockDocData.append('document', file);
        });
    }

    if (d.file) stockDocData.append('document', d.file);
    const result = await createStockDocument({
      payload: stockDocData,
    }).unwrap();

    return result;
  };

  const createStockDocuments = async (data: StockDocData[]) => {
    await Promise.allSettled(data.map((d) => createStockDoc(d)));
  };
  return {
    isLoading: isLoadingCreateStockDoc,
    createStockDocuments,
  };
}
