import {
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import {
  BooleanIconRenderCell,
  RelatedRecordRenderCell,
} from 'ui-component/DataGrid/Render';
import { IconLock, IconLockOpen } from '@tabler/icons-react';
import { ALL_APPS } from 'constants/appConstants';
import { ExtendedGridColDef } from 'types/datagrid';
import { orgPartNoStockLotColumnDef } from 'ui-component/DataGrid/PartColumnDef';
import { useUpdateKitRequestLinesMutation } from 'store/slices/clientV2/kitRequests';
import { appPermissionAccessLevels } from 'types/apps';
import { FieldValues } from 'react-hook-form';
import { Stack, Tooltip, Typography } from '@mui/material';
import { statusTextMapper } from 'constants/datagrid';
import _ from 'lodash';
import { KitRequestLineStatusChip } from 'hooks/useGetDecoratedKitRequestLines/KitRequestLineStatusChips';
import { RelatedRecordType } from 'types/inventory';
import { relatedRecordsComparator } from 'ui-component/DataGrid/SortComparators';
import {
  getApplyFilterFnRelatedRecords,
  relatedRecordsFilterOperators,
} from 'ui-component/DataGrid/FilterOperators';

export const columns = [
  {
    field: 'status',
    headerName: 'Status',
    description: 'Indicates the allocation status of this Kit Line',
    type: 'string',
    minWidth: 150,
    hideable: false,
    valueGetter: (params: GridValueGetterParams) =>
      [
        ..._.chain(params.row.stats?.statusCounts)
          .map('status')
          .map((s) => statusTextMapper[s as keyof typeof statusTextMapper])
          .uniq()
          .value(),
        ...(Math.max(
          (params.row.minimumQuantity || 0) -
            _.sumBy(params.row.allocations, 'quantity'),
          0
        ) > 0
          ? ['Unavailable']
          : []),
      ].join('/') ?? 'Unknown',
    renderCell: (params: GridRenderCellParams) => (
      <KitRequestLineStatusChip kitRequestLine={params.row} />
    ),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  orgPartNoStockLotColumnDef,
  {
    field: 'targetQuantity',
    headerName: 'Quantity',
    description: 'Quantity of this part in this Kit Line',
    type: 'number',
    flex: 0.25,
    permissionScope: {
      app: ALL_APPS.KITTING.id,
    },
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip
        title={`Minimum Quantity: ${params.row.minimumQuantity.toLocaleString()}`}
        placement="top"
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={0.5}
        >
          <Typography>{params.value.toLocaleString()}</Typography>
        </Stack>
      </Tooltip>
    ),
  },
  {
    field: 'locked',
    headerName: 'Locked',
    description:
      'Indicates whether the system can automatically calculate allocations for this Kit Line',
    type: 'boolean',
    flex: 0.3,
    hideable: true,
    renderCell: (params: GridRenderCellParams) =>
      BooleanIconRenderCell(params, IconLock, IconLockOpen, 'error', 'primary'),
    permissionScope: { app: ALL_APPS.KITTING.id },
  },
  {
    field: 'printedNotes',
    headerName: 'Printed Notes',
    description:
      'Notes to be printed on stock lot shipment label and packing slip',
    type: 'string',
    flex: 0.5,
    permissionScope: { app: ALL_APPS.KITTING.id },
    editable: true,
    inlineEditRenderCell: true,
    useMutation: useUpdateKitRequestLinesMutation,
    editPermissionScope: {
      app: ALL_APPS.KITTING.id,
      accessLevel: appPermissionAccessLevels.edit,
    },
    mutationPreSubmit: (data: FieldValues) => ({
      printedNotes: data.printedNotes,
    }),
    mutationAsClientV2: true,
  },
  {
    field: 'relatedRecords',
    headerName: 'Related Records',
    description: 'Other records related to transaction',
    type: 'string',
    minWidth: 200,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) =>
      _.chain(params.row.shipmentLines)
        .filter((sl) => !!sl.shipment.shippedAt)
        .map((sl) => ({
          name: sl.shipment.shipmentCode,
          recordType: RelatedRecordType.SHIPMENT,
          recordId: sl.shipment.id,
          orgId: sl.shipment.org,
        }))
        .value(),
    renderCell: (params: GridRenderCellParams) => (
      <RelatedRecordRenderCell {...params} />
    ),
    filterOperators: relatedRecordsFilterOperators,
    sortComparator: relatedRecordsComparator,
    getApplyQuickFilterFn: getApplyFilterFnRelatedRecords,
  },
] as ExtendedGridColDef[];
