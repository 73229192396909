import { Tooltip } from '@mui/material';
import { GridColTypeDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Icon as TablerIcon, IconMinus, IconX } from '@tabler/icons-react';
import Chip, { ChipColor } from 'ui-component/extended/Chip';
import _ from 'lodash';
import { ReactElement } from 'react';

export const statusColorMapper: { [key: string]: ChipColor } = {
  Pending: ChipColor.secondary,
  Requested: ChipColor.secondary,
  'No Bid': ChipColor.grey,
  Complete: ChipColor.success,
  'No Sources': ChipColor.error,
  Hold: ChipColor.warning,
  Issue: ChipColor.warning,
  Voided: ChipColor.error,
  Expected: ChipColor.secondary,
  'On Hand': ChipColor.success,
  'No Stock': ChipColor.grey,
  Quarantined: ChipColor.error,
  Passed: ChipColor.success,
  Request: ChipColor.secondary,
  inventory: ChipColor.success,
  buyable: ChipColor.primary,
  quotable: ChipColor.secondary,
  maybe: ChipColor.warning,
  'need to find': ChipColor.error,
  Valid: ChipColor.success,
  Expired: ChipColor.error,
  Received: ChipColor.success,
  Ingested: ChipColor.success,
  'Partially Received': ChipColor.success,
  Inventory: ChipColor.success,
  Buyable: ChipColor.primary,
  Quotable: ChipColor.secondary,
  Maybe: ChipColor.warning,
  'Need to Find': ChipColor.error,
  Draft: ChipColor.grey,
  'Partially Shipped': ChipColor.primary,
  'Partially Delivered': ChipColor.primary,
  Delivered: ChipColor.success,
  Shipped: ChipColor.success,
  Cancelled: ChipColor.grey,
  Rejected: ChipColor.error,
  Refreshed: ChipColor.grey,
  Purchased: ChipColor.primary,
  Processing: ChipColor.secondary,
  Processed: ChipColor.secondary,
  Canceled: ChipColor.grey,
  'Partially Canceled': ChipColor.grey,
  Confirmed: ChipColor.primary,
  Delayed: ChipColor.warning,
  'Action Required': ChipColor.warning,
  Returned: ChipColor.grey,
  'Partially Returned': ChipColor.primary,
  Released: ChipColor.grey,
  Consumed: ChipColor.secondary,
  Allocated: ChipColor.primary,
  Unallocated: ChipColor.primary,
  'Allocated to Run': ChipColor.primary,
  'Total Free': ChipColor.primary,
  Failed: ChipColor.error,
  'Do Not Place': ChipColor.grey,
  Ignore: ChipColor.grey,
  'Ready to Ship': ChipColor.success,
  'At Production': ChipColor.success,
  'From CM': ChipColor.grey,
  'From Other': ChipColor.grey,
  'Quote Requested': ChipColor.secondary,
  'Purchase Processing': ChipColor.primary,
  Ordered: ChipColor.success,
  'In Shipment': ChipColor.success,
  Picked: ChipColor.primary,
  Packed: ChipColor.success,
  'To Do': ChipColor.grey,
  Tracking: ChipColor.secondary,
  'No Match': ChipColor.error,
  'Used in BOM': ChipColor.warning,
  'From Supplier': ChipColor.warning,
  'MPN Search': ChipColor.warning,
  'Parametric Search': ChipColor.warning,
  Community: ChipColor.warning,
  'Form Fit Function': ChipColor.primary,
  Unknown: ChipColor.secondary,
  Identical: ChipColor.success,
  Other: ChipColor.secondary,
  'In Progress': ChipColor.secondary,
  Default: ChipColor.primary,
  Include: ChipColor.primary,
  'Not in Stock': ChipColor.warning,
  'At Production (Processing)': ChipColor.secondary,
  'At Production (On Hand)': ChipColor.success,
  'Not in Shipment': ChipColor.error,
  Approved: ChipColor.success,
  'Not Approved': ChipColor.secondary,
  'Not Sent': ChipColor.grey,
  Sent: ChipColor.secondary,
  Viewed: ChipColor.secondary,
  'Past Due': ChipColor.error,
  Paid: ChipColor.success,
  'No PNP SMT Part': ChipColor.warning,
  'Your Search': ChipColor.warning,
  'Partially Confirmed': ChipColor.secondary,
  Loading: ChipColor.grey,
  'Same Custom PN': ChipColor.secondary,
  Resell: ChipColor.secondary,
  'Approval Requested': ChipColor.warning,
  Submitted: ChipColor.secondary,
  Closed: ChipColor.success,
  Fulfilled: ChipColor.success,
  Unavailable: ChipColor.error,
  OnHand: ChipColor.success,
  Kitted: ChipColor.primary,
  'Added to Kit': ChipColor.secondary,
};

export const iconBooleanDot = (
  Icon: TablerIcon,
  trueTooltipTitle?: string,
  falseTooltipTitle?: string
): GridColTypeDef => ({
  type: 'boolean',
  minWidth: 100,
  flex: 0.2,
  renderCell: ({ value }: GridRenderCellParams) =>
    value && (
      <Tooltip title={value ? trueTooltipTitle || '' : falseTooltipTitle || ''}>
        <div>
          <Chip
            sx={{
              width: 35,
              justifyContent: 'flex-start',
              '.MuiChip-label': {
                padding: 0,
              },
            }}
            chipcolor={value ? ChipColor.success : ChipColor.grey}
            icon={<Icon color={value ? '#00C853' : '#616161'} />}
          />
        </div>
      </Tooltip>
    ),
});

export const iconQualityDot = (Icon: TablerIcon): GridColTypeDef => ({
  type: 'boolean',
  minWidth: 100,
  flex: 0.2,
  renderCell: (params: GridRenderCellParams) => (
    <Tooltip
      title={
        params.row.supplier?.authenticity > 0
          ? params.row.supplier?.authenticity > 0.9
            ? 'Cofactr’s Quality Mark indicates that this supplier has a track record of delivering the correct parts with no quality or authenticity issues to our customers'
            : 'This supplier has delivered incorrect, damaged, or counterfeit parts to our customers'
          : 'We do not have sufficient purchasing data to assign this supplier a quality rating'
      }
    >
      <div>
        <Chip
          sx={{
            width: 35,
            justifyContent: 'flex-start',
            '.MuiChip-label': {
              padding: 0,
            },
          }}
          chipcolor={
            params.row.supplier?.authenticity > 0
              ? params.row.supplier?.authenticity > 0.9
                ? ChipColor.success
                : ChipColor.error
              : ChipColor.grey
          }
          icon={
            params.row.supplier?.authenticity > 0 ? (
              params.row.supplier?.authenticity > 0.9 ? (
                <Icon color="#64ba5f" />
              ) : (
                <IconX color="#d54c48" />
              )
            ) : (
              <IconMinus color="#616161" />
            )
          }
        />
      </div>
    </Tooltip>
  ),
});

// Have to pass callback function into textPill in order
// to have access to each row's value, and then use
// each row's value to determine color
export const statusColorCallback = (val: string) => statusColorMapper[val];

// Expects a callback function that takes in a row's value, and returns a ChipColor
// See PartsQuotes for example
export const textPill = (
  color: (value: string) => ChipColor,
  uppercase: boolean = true,
  tooltip?: string,
  onClick?: () => void,
  clickDisabled?: boolean,
  tooltipOptions?: { [key: string]: string },
  icon?: ReactElement
): GridColTypeDef => ({
  renderCell: ({ value }: GridRenderCellParams) => (
    <Chip
      title={tooltip || _.get(tooltipOptions, value) || ''}
      label={value}
      chipcolor={color(value)}
      style={{ textTransform: uppercase ? 'uppercase' : 'none' }}
      onClick={onClick}
      disabled={clickDisabled || false}
      icon={icon}
    />
  ),
});
