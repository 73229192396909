import { URLS } from 'store/constant';
import {
  Availabilities,
  ExpandedPurchaseLine,
  PriceModel,
  Snapshot,
  Invoice,
  InvoicedLink,
  RequestOrApproveSnapshotRequest,
  KittingEstimate,
  KittingOptionsCreate,
  PurchaseLineUpdate,
  PurchaseCreate,
  PurchaseLite,
  PurchaseUpdate,
  ExpandedPurchaseLineGETSchema,
  ConfirmedPurchaseLine,
} from 'types/purchasing';
import { Part } from 'types/part';
import { ProductionRun } from 'types/production';
import { BomCRUDSchema } from 'types/bom';
import { apiSlice } from 'store/slices/api';
import {
  CreateOrderRemediationSnapshotRequest,
  CreatePurchaseEventRequest,
  Order,
  OrderLineLite,
  OrderLine,
  OrderSnapshot,
  OrderUpdates,
} from 'types/order';
import { StockLocation } from 'types/inventory';
import { DateString } from 'types';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPriceModel: builder.query<PriceModel, PriceModel['id']>({
      query: (priceModelId) => URLS.PRICE_MODEL(priceModelId),
      providesTags: (result) =>
        result
          ? [{ type: 'PriceModel', id: result.id }]
          : [{ type: 'PriceModel', id: 'LIST' }],
    }),
    approveSnapshot: builder.mutation<
      OrderSnapshot,
      { snapshotId: Snapshot['id']; payload: RequestOrApproveSnapshotRequest }
    >({
      query: ({ snapshotId, payload }) => ({
        url: URLS.SNAPSHOT_APPROVE(snapshotId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { snapshotId }) => [
        { type: 'AvailabilitiesSnapshot', id: snapshotId },
        { type: 'OrderSnapshot', id: 'LIST' },
        { type: 'OrderSnapshot', id: snapshotId },
        { type: 'Order', id: 'LIST' },
        { type: 'OrderLines', id: 'LIST' },
        { type: 'PurchaseRequests', id: 'LIST' },
        { type: 'PurchaseOrders', id: 'LIST' },
        { type: 'PurchaseOrderLinesWithPurchase', id: 'LIST' },
      ],
    }),
    requestApprovalForSnapshot: builder.mutation<
      Snapshot,
      { snapshotId: Snapshot['id']; payload: RequestOrApproveSnapshotRequest }
    >({
      query: ({ snapshotId, payload }) => ({
        url: URLS.SNAPSHOT_REQUEST_APPROVAL(snapshotId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { snapshotId }) => [
        { type: 'AvailabilitiesSnapshot', id: snapshotId },
        { type: 'OrderSnapshot', id: 'LIST' },
        { type: 'Order', id: 'LIST' },
        { type: 'OrderLines', id: 'LIST' },
      ],
    }),
    rejectSnapshot: builder.mutation<
      OrderSnapshot,
      { snapshotId: Snapshot['id']; payload: { rejectionReason?: string } }
    >({
      query: ({ snapshotId, payload }) => ({
        url: URLS.SNAPSHOT_REJECT(snapshotId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { snapshotId }) => [
        { type: 'AvailabilitiesSnapshot', id: snapshotId },
        { type: 'OrderSnapshot', id: 'LIST' },
        { type: 'OrderSnapshot', id: snapshotId },
        { type: 'Order', id: 'LIST' },
        { type: 'OrderLines', id: 'LIST' },
      ],
    }),
    getSnapshotRecalculateAvailabilities: builder.query<
      Availabilities,
      { snapshotId: OrderSnapshot['id'] }
    >({
      query: ({ snapshotId }) =>
        URLS.SNAPSHOT_RECALCULATE_AVAILABILITIES(snapshotId),
      providesTags: (result, error, { snapshotId }) =>
        result && snapshotId
          ? [
              { type: 'AvailabilitiesSnapshot', id: snapshotId },
              { type: 'AvailabilitiesSnapshot', id: 'LIST' },
            ]
          : [],
    }),
    recalculateAndSaveSnapshot: builder.mutation<
      OrderSnapshot,
      { snapshotId: OrderSnapshot['id']; selectedLead: number }
    >({
      query: ({ snapshotId, selectedLead }) => ({
        url: URLS.SNAPSHOT_SAVE_RECALCULATIONS(snapshotId),
        method: 'PATCH',
        body: { selectedLead },
      }),
      // Not exactly sure yet best way to manage tags for this
      invalidatesTags: (result, error, { snapshotId }) => [
        { type: 'AvailabilitiesSnapshot', id: snapshotId },
        { type: 'OrderSnapshot', id: 'LIST' },
        { type: 'OrderSnapshot', id: snapshotId },
        { type: 'Order', id: 'LIST' },
        { type: 'OrderLines', id: 'LIST' },
      ],
    }),
    createPurchase: builder.mutation<PurchaseLite, { payload: PurchaseCreate }>(
      {
        query: ({ payload }) => ({
          url: URLS.PURCHASES,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: [
          { type: 'ExpandedPurchaseLines', id: 'LIST' },
          { type: 'ExpandedPurchaseLines', id: 'PARTIAL-LIST' },
        ],
      }
    ),
    updatePurchase: builder.mutation<
      PurchaseLite,
      { purchaseId: PurchaseLite['id']; payload: PurchaseUpdate }
    >({
      query: ({ purchaseId, payload }) => ({
        url: URLS.PURCHASE(purchaseId),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [
        { type: 'ExpandedPurchaseLines', id: 'LIST' },
        { type: 'ExpandedPurchaseLines', id: 'PARTIAL-LIST' },
      ],
    }),
    deletePurchase: builder.mutation<string, string>({
      query: (purchaseId: string) => ({
        url: URLS.PURCHASE(purchaseId),
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'ExpandedPurchaseLines', id: 'LIST' },
        { type: 'ExpandedPurchaseLines', id: 'PARTIAL-LIST' },
      ],
    }),
    getConfirmedPurchaseLines: builder.query<
      ConfirmedPurchaseLine[],
      ExpandedPurchaseLineGETSchema
    >({
      query: ({ facilityId, sandbox }) =>
        URLS.CONFIRMED_PURCHASE_LINES(facilityId, sandbox),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: ConfirmedPurchaseLine) =>
                  ({
                    type: 'ConfirmedPurchaseLines',
                    id,
                  } as const)
              ),
              { type: 'ConfirmedPurchaseLines', id: 'LIST' },
            ]
          : [{ type: 'ConfirmedPurchaseLines', id: 'LIST' }],
    }),
    unreceivePurchaseLine: builder.mutation<
      ExpandedPurchaseLine,
      {
        purchaseId: PurchaseLite['id'];
        purchaseLineId: ExpandedPurchaseLine['id'];
      }
    >({
      query: ({ purchaseId, purchaseLineId }) => ({
        url: URLS.PURCHASE_LINE_UNRECEIVE({ purchaseId, purchaseLineId }),
        method: 'POST',
      }),
      invalidatesTags: [
        { type: 'ExpandedPurchaseLines', id: 'LIST' },
        { type: 'ExpandedPurchaseLines', id: 'PARTIAL-LIST' },
        { type: 'ConfirmedPurchaseLines', id: 'LIST' },
        { type: 'ConfirmedPurchaseLines', id: 'PARTIAL-LIST' },
      ],
    }),
    sendPurchaseToKb: builder.mutation<
      PurchaseLite,
      { purchaseId: PurchaseLite['id']; forceSubmit: boolean }
    >({
      query: ({ purchaseId, forceSubmit }) => ({
        url: URLS.PURCHASE_SEND_TO_KB(purchaseId, forceSubmit),
        method: 'POST',
      }),
      invalidatesTags: [
        { type: 'ExpandedPurchaseLines', id: 'LIST' },
        { type: 'ExpandedPurchaseLines', id: 'PARTIAL-LIST' },
      ],
    }),
    updateBatchPurchaseLines: builder.mutation<
      ExpandedPurchaseLine[],
      { orderId: Order['id']; payload: PurchaseLineUpdate[] }
    >({
      query: ({ payload }) => ({
        url: URLS.PURCHASE_LINES(null),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { orderId }) => [
        ...(result
          ? result.map(({ id }: ExpandedPurchaseLine) => ({
              type: 'OrderLines' as 'OrderLines',
              id,
            }))
          : [{ type: 'OrderLines' as 'OrderLines', id: 'LIST' }]),
        { type: 'Order', id: orderId },
      ],
    }),
    updateExpandedPurchaseLine: builder.mutation<
      ExpandedPurchaseLine,
      {
        purchaseLineId: ExpandedPurchaseLine['id'];
        payload: PurchaseLineUpdate;
      }
    >({
      query: ({ purchaseLineId, payload }) => ({
        url: URLS.PURCHASE_LINE(purchaseLineId),
        method: 'PATCH',
        body: payload,
      }),
      async onQueryStarted({ purchaseLineId }, { dispatch, queryFulfilled }) {
        const { data: updatedPurchaseLine } = await queryFulfilled;
        dispatch(
          extendedApiSlice.util.updateQueryData(
            'getConfirmedPurchaseLines',
            {},
            (draft) => {
              const index = draft.findIndex((x) => x.id === purchaseLineId);
              if (index > -1) {
                draft[index] = updatedPurchaseLine;
              }
            }
          )
        );
      },
      invalidatesTags: () => [{ type: 'ExpandedPurchaseLines', id: 'LIST' }],
    }),
    getOrders: builder.query<
      Order[],
      {
        productionRunId?: ProductionRun['id'] | null;
        bomId?: BomCRUDSchema['id'] | null;
        part?: Part['id'] | null;
        fromDate?: DateString | null;
        toDate?: DateString | null;
      }
    >({
      query: ({ productionRunId, bomId, part, fromDate, toDate }) =>
        URLS.ORDERS(productionRunId, bomId, part, fromDate, toDate),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: Order) =>
                  ({
                    type: 'Order',
                    id,
                  } as const)
              ),
              { type: 'Order', id: 'LIST' },
            ]
          : [{ type: 'Order', id: 'LIST' }],
    }),
    getOrder: builder.query<Order, Order['id']>({
      query: (orderId) => URLS.ORDER(orderId),
      providesTags: (result, error, orderId) => [
        { type: 'Order', id: orderId },
      ],
    }),
    getOrderLines: builder.query<OrderLine[], Order['id']>({
      query: (orderId) => URLS.ORDER_LINES(orderId),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: OrderLine) =>
                  ({
                    type: 'OrderLines',
                    id,
                  } as const)
              ),
              { type: 'OrderLines', id: 'LIST' },
            ]
          : [{ type: 'OrderLines', id: 'LIST' }],
    }),
    getOrderUpdates: builder.query<OrderUpdates[], Order['id']>({
      query: (orderId) => URLS.ORDER_UPDATES(orderId),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: OrderUpdates) =>
                  ({
                    type: 'OrderUpdates',
                    id,
                  } as const)
              ),
              { type: 'OrderUpdates', id: 'LIST' },
            ]
          : [{ type: 'OrderUpdates', id: 'LIST' }],
    }),
    createPurchaseEvent: builder.mutation<
      OrderUpdates[],
      {
        payload: CreatePurchaseEventRequest;
      }
    >({
      query: ({ payload }) => ({
        url: URLS.PURCHASE_EVENTS,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [
        { type: 'Order', id: 'LIST' },
        { type: 'OrderUpdates', id: 'LIST' },
        { type: 'OrderLines', id: 'LIST' },
        { type: 'ExpandedPurchaseLines', id: 'LIST' },
        { type: 'PurchaseOrderLines', id: 'LIST' },
        { type: 'PurchaseOrderEvents', id: 'LIST' },
        { type: 'PurchaseOrderLinesWithPurchase', id: 'LIST' },
      ],
    }),
    getOrderRemediations: builder.query<
      Availabilities,
      { orderId: Order['id']; orderLineId: OrderLineLite['id'] }
    >({
      query: ({ orderId, orderLineId }) =>
        URLS.ORDER_REMEDIATIONS({ orderId, orderLineId }),
      providesTags: (result, error, { orderLineId }) =>
        result
          ? [
              {
                type: 'OrderRemediations',
                id: orderLineId,
              },
              { type: 'OrderRemediations', id: 'LIST' },
            ]
          : [{ type: 'OrderRemediations', id: 'LIST' }],
    }),
    createRemediationSnapshot: builder.mutation<
      Snapshot,
      {
        orderId: Order['id'];
        orderLineId: OrderLineLite['id'];
        payload: CreateOrderRemediationSnapshotRequest;
      }
    >({
      query: ({ orderId, orderLineId, payload }) => ({
        url: URLS.ORDER_REMEDIATION_SNAPSHOT_APPROVE({ orderId, orderLineId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { orderId }) => [
        { type: 'OrderRemediations', id: result?.id },
        { type: 'OrderRemediations', id: 'LIST' },
        { type: 'Order', id: 'LIST' },
        { type: 'Order', id: orderId },
      ],
    }),
    getOrderLineAlts: builder.query<
      { alts: Part[] },
      { orderId: Order['id']; orderLineId: OrderLineLite['id'] }
    >({
      query: ({ orderId, orderLineId }) =>
        URLS.ORDER_LINE_ALTS({ orderId, orderLineId }),
      providesTags: (result, error, { orderLineId }) =>
        result
          ? [
              {
                type: 'OrderLineAlts',
                id: orderLineId,
              },
              { type: 'OrderLineAlts', id: 'LIST' },
            ]
          : [{ type: 'OrderLineAlts', id: 'LIST' }],
    }),
    updateOrderLineAlts: builder.mutation<
      { alts: Part[] },
      {
        orderId: Order['id'];
        orderLineId: OrderLineLite['id'];
        payload: { alts: Part['id'][] };
      }
    >({
      query: ({ orderId, orderLineId, payload }) => ({
        url: URLS.ORDER_LINE_ALTS({ orderId, orderLineId }),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, { orderLineId }) => [
        { type: 'OrderLineAlts', id: orderLineId },
        { type: 'OrderLineAlts', id: 'LIST' },
        { type: 'OrderRemediations', id: orderLineId },
        { type: 'OrderRemediations', id: 'LIST' },
      ],
    }),
    getOrderSnapshot: builder.query<OrderSnapshot, OrderSnapshot['id']>({
      query: (snapshotId) => URLS.SNAPSHOT(snapshotId),
      providesTags: (result, error, snapshotId) => [
        { type: 'OrderSnapshot', id: snapshotId },
      ],
    }),
    updateOrderSnapshot: builder.mutation<
      OrderSnapshot,
      {
        id: OrderSnapshot['id'];
        payload: {
          externalPoNumber?: string;
          externalPoTotal?: number;
          productionRun?: ProductionRun['id'];
          archived?: boolean;
        };
      }
    >({
      query: ({ id, payload }) => ({
        url: URLS.SNAPSHOT(id),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'OrderSnapshot', id },
        { type: 'OrderSnapshot', id: 'LIST' },
      ],
    }),
    getOrderSnapshots: builder.query<
      OrderSnapshot[],
      {
        productionRunId?: ProductionRun['id'] | null;
        fromDate?: DateString | null;
        toDate?: DateString | null;
        sandbox?: boolean;
        bomId?: BomCRUDSchema['id'] | null;
      }
    >({
      query: ({ productionRunId, fromDate, toDate, sandbox, bomId }) =>
        URLS.SNAPSHOTS({
          productionRunId,
          fromDate,
          toDate,

          sandbox,
          bomId,
        }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: OrderSnapshot) =>
                  ({
                    type: 'OrderSnapshot',
                    id,
                  } as const)
              ),
              { type: 'OrderSnapshot', id: 'LIST' },
            ]
          : [{ type: 'OrderSnapshot', id: 'LIST' }],
    }),
    getInvoices: builder.query<Invoice[], { fromDate: string; toDate: string }>(
      {
        query: ({ fromDate, toDate }) => URLS.INVOICES(fromDate, toDate),
        providesTags: (result) =>
          result
            ? [
                ...result.map(
                  ({ id }: Invoice) => ({ type: 'Invoice', id } as const)
                ),
                { type: 'Invoice', id: 'LIST' },
              ]
            : [{ type: 'Invoice', id: 'LIST' }],
      }
    ),
    getInvoice: builder.query<Invoice, string>({
      query: (invoiceNumber) => URLS.INVOICE(invoiceNumber),
      providesTags: (result) =>
        result
          ? [{ type: 'Invoice', id: result.id }]
          : [{ type: 'Invoice', id: 'LIST' }],
    }),
    getInvoicedLink: builder.query<InvoicedLink, void>({
      query: () => ({
        url: URLS.INVOICED_LINK,
      }),
    }),
    getStockLocationKittingOptions: builder.query<
      KittingEstimate,
      { id: StockLocation['id']; payload: KittingOptionsCreate }
    >({
      query: ({ id, payload }) => ({
        url: URLS.STOCK_LOCATION_KITTING_OPTIONS(id),
        method: 'POST',
        body: payload,
      }),
      providesTags: [{ type: 'KittingOptions', id: 'LIST' }],
    }),
    markOrderLineUnshipped: builder.mutation<
      OrderLine,
      { orderId: Order['id']; orderLineId: OrderLine['id'] }
    >({
      query: ({ orderId, orderLineId }) => ({
        url: URLS.ORDER_LINE_UNSHIP({ orderId, orderLineId }),
        method: 'POST',
      }),
      invalidatesTags: (result, error, { orderId }) =>
        result
          ? [
              { type: 'Order', id: orderId },
              { type: 'Order', id: 'LIST' },
              { type: 'OrderLines', id: orderId },
              { type: 'OrderLines', id: 'LIST' },
              { type: 'OrderUpdates', id: orderId },
            ]
          : [],
    }),
  }),
});

export const {
  useGetPriceModelQuery,
  useApproveSnapshotMutation,
  useRequestApprovalForSnapshotMutation,
  useRejectSnapshotMutation,
  useLazyGetSnapshotRecalculateAvailabilitiesQuery,
  useRecalculateAndSaveSnapshotMutation,
  useGetConfirmedPurchaseLinesQuery,
  useUnreceivePurchaseLineMutation,
  useUpdateExpandedPurchaseLineMutation,
  useUpdateBatchPurchaseLinesMutation,
  useGetOrderSnapshotQuery,
  useUpdateOrderSnapshotMutation,
  useGetOrderSnapshotsQuery,
  useGetOrdersQuery,
  useGetOrderQuery,
  useGetOrderLinesQuery,
  useGetOrderUpdatesQuery,
  useUpdatePurchaseMutation,
  useSendPurchaseToKbMutation,
  useDeletePurchaseMutation,
  useCreatePurchaseMutation,
  useCreatePurchaseEventMutation,
  useGetOrderRemediationsQuery,
  useCreateRemediationSnapshotMutation,
  useGetOrderLineAltsQuery,
  useUpdateOrderLineAltsMutation,
  useGetInvoicesQuery,
  useGetInvoiceQuery,
  useGetInvoicedLinkQuery,
  useGetStockLocationKittingOptionsQuery,
  useMarkOrderLineUnshippedMutation,
} = extendedApiSlice;
