import { FC, useState, JSX, SyntheticEvent } from 'react';
import {
  Box,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
} from '@mui/material';
import Chip from 'ui-component/extended/Chip';

import {
  IconBox,
  IconCalendarEvent,
  IconCopy,
  IconExternalLink,
  IconFileSpreadsheet,
  IconLink,
  IconListDetails,
  IconPackage,
  IconPlaneDeparture,
  IconReportMoney,
  IconShoppingCart,
  IconTruckDelivery,
} from '@tabler/icons-react';
import { RelatedRecord } from 'types/inventory';
import { useNavigate } from 'react-router-dom';
import { ALL_APPS, ALL_VIEWS } from 'constants/appConstants';
import { appPermissionStates } from 'types/apps';
import _ from 'lodash';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { useSelector, useDispatch } from 'store';
import { setStoredOrg } from 'store/actions';
import { setActiveOrgId } from 'store/slices/org';
import { orgFromUserOrgId } from 'utils/userPermissions';
import { useGetUserQuery } from 'store/slices/user';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import { copyClipboard } from 'utils/functions';

interface RelatedRecordChipProps {
  id: string;
  label: string;
  link: string;
  icon: JSX.Element;
  orgId: string | null;
  recordTypeLabel: string;
}

export const RelatedRecordChip: FC<RelatedRecordChipProps> = ({
  id,
  label,
  link,
  icon,
  orgId,
  recordTypeLabel,
}) => {
  const { blended } = useSelector((appState) => appState.org);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: user } = useGetUserQuery();
  const [anchorEl, setAnchorEl] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item xs="auto" key={id}>
      <Menu
        id="menu-earning-card"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        variant="selectedMenu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            if (blended && orgId) {
              dispatch(setStoredOrg(orgId));
              dispatch(
                setActiveOrgId({
                  orgId,
                  org: orgFromUserOrgId(user, orgId),
                  skipResetState: true,
                  blended: false,
                })
              );
            }
            navigate(link);
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>Go To {recordTypeLabel}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (blended && orgId) {
              dispatch(setStoredOrg(orgId));
              dispatch(
                setActiveOrgId({
                  orgId,
                  org: orgFromUserOrgId(user, orgId),
                  skipResetState: true,
                  blended: false,
                })
              );
            }
            window.open(`${window.location.origin}${link}`, '_blank');
            handleClose();
          }}
        >
          <ListItemIcon>
            <IconExternalLink />
          </ListItemIcon>
          <ListItemText>Open {recordTypeLabel} In New Tab</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            copyClipboard(`${window.location.origin}${link}`);
            handleClose();
          }}
        >
          <ListItemIcon>
            <IconCopy />
          </ListItemIcon>
          <ListItemText>Copy Link to {recordTypeLabel}</ListItemText>
        </MenuItem>
      </Menu>
      <Chip
        label={label}
        variant="outlined"
        icon={icon}
        clickable
        color="primary"
        type="button"
        onMouseDown={(event) => event.stopPropagation()}
        onTouchStart={(event) => event.stopPropagation()}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleClick(event);
        }}
      />
    </Grid>
  );
};

interface RelatedRecordsProps {
  relatedRecords: RelatedRecord[] | null | undefined;
  onOpenEditRelatedRecords?: () => void;
  isLoading?: boolean;
  EditButton?: JSX.Element | undefined;
}

const recordTypeToApp: { [key: string]: string } = {
  stock_lot: ALL_APPS.STOCK.id,
  shipment: ALL_APPS.KITTING.id,
  order: ALL_APPS.ORDERS.id,
  snapshot: ALL_APPS.ORDERS.id,
  production_run: ALL_APPS.PRODUCTION.id,
  bom: ALL_APPS.BOMS.id,
  purchase_order: ALL_APPS.PROCUREMENT.id,
  kit_request: ALL_APPS.KITTING.id,
  supplier_bill: ALL_APPS.PROCUREMENT.id,
};

const RelatedRecords: FC<RelatedRecordsProps> = ({
  relatedRecords,
  onOpenEditRelatedRecords,
  isLoading,
  EditButton,
}) => {
  const { appPermissions } = useAppAccessContext();
  const { blended } = useSelector((appState) => appState.org);

  const filteredRelatedRecords = relatedRecords?.filter(
    (r) =>
      _.get(
        appPermissions,
        [recordTypeToApp[r.recordType], 'state'],
        appPermissionStates.disabled
      ) === appPermissionStates.enabled ||
      (_.get(
        appPermissions,
        [recordTypeToApp[r.recordType], 'state'],
        appPermissionStates.disabled
      ) === appPermissionStates.noBlended &&
        blended)
  );

  return (
    <Box>
      <Grid
        container
        spacing={1}
        justifyContent="flex-start"
        sx={{ overflow: 'hidden' }}
      >
        {isLoading && filteredRelatedRecords?.length === 0 && (
          <Grid item xs="auto">
            <Skeleton variant="rectangular" width={120} height={36} />
          </Grid>
        )}
        {filteredRelatedRecords &&
          filteredRelatedRecords.map((related: RelatedRecord) => {
            const recordTypeConfig = {
              stock_lot: {
                icon: <IconPackage />,
                link: `${ALL_APPS.STOCK.views?.STOCK_LOTS?.path}/${related.recordId}`,
                recordTypeLabel: 'Stock Lot',
              },
              shipment: {
                icon: <IconPlaneDeparture />,
                link: `${ALL_APPS.KITTING.views?.SHIPMENTS?.path}/${related.recordId}`,
                recordTypeLabel: 'Shipment',
              },
              order: {
                icon: <IconTruckDelivery />,
                link: `${ALL_APPS.ORDERS.views?.ORDERS_TABLE?.path}/${related.recordId}`,
                recordTypeLabel: 'Order',
              },
              purchase_order: {
                icon: <IconFileSpreadsheet />,
                link: `${ALL_VIEWS.PURCHASE_ORDERS.path}/${related.recordId}`,
                recordTypeLabel: 'Purchase Order',
              },
              snapshot: {
                icon: <IconShoppingCart />,
                link: `${ALL_APPS.ORDERS.views?.YOUR_ORDERS?.path}/${related.recordId}`,
                recordTypeLabel: 'Cart',
              },
              production_run: {
                icon: <IconCalendarEvent />,
                link: `${ALL_APPS.PRODUCTION.path}/${related.recordId}`,
                recordTypeLabel: 'Program',
              },
              bom: {
                icon: <IconListDetails />,
                link: `${ALL_APPS.BOMS.path}/${related.recordId}`,
                recordTypeLabel: 'BOM',
              },
              kit_request: {
                icon: <IconBox />,
                link: `${ALL_APPS.KITTING.views?.KITS?.path}/${related.recordId}`,
                recordTypeLabel: 'Kit',
              },
              supplier_bill: {
                icon: <IconReportMoney />,
                link: `${ALL_VIEWS.SUPPLIER_BILLS.path}/${related.recordId}`,
                recordTypeLabel: 'Supplier Bill',
              },
            };

            const config = recordTypeConfig[
              related.recordType as keyof typeof recordTypeConfig
            ] || {
              icon: <IconLink />,
              link: '#',
              recordTypeLabel: related.recordType,
            };

            return (
              <RelatedRecordChip
                id={related.recordId}
                label={related.name}
                link={config.link}
                icon={config.icon}
                orgId={related.orgId}
                key={related.recordId}
                recordTypeLabel={config.recordTypeLabel}
              />
            );
          })}
        {onOpenEditRelatedRecords && (
          <Grid item xs="auto" sx={{ paddingTop: '6px' }}>
            <SegmentIconButton
              aria-label="Edit Related Program"
              title="Edit Related Program"
              size="small"
              onClick={onOpenEditRelatedRecords}
            >
              <IconLink size="20px" />
            </SegmentIconButton>
          </Grid>
        )}
        {EditButton && (
          <Grid item xs="auto" sx={{ paddingTop: '6px' }}>
            {EditButton}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RelatedRecords;
