import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import { FC, useState } from 'react';
import {
  ExtendedDialog,
  DialogTrackingNames,
} from 'ui-component/extended/ExtendedDialog';
import * as Yup from 'yup';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { PermissionScope } from 'types/apps';
import { handleErr } from 'utils/functions';
import { useNavigate } from 'react-router-dom';
import useSnackbar from 'hooks/useSnackbar';
import _ from 'lodash';

type BaseCreateRecordDialogProps = {
  formConstants: {
    [id: string]: {
      id: string;
      label: string;
    };
  };
  validationSchema: Yup.ObjectSchema<any>;
  newRecordDefaults?: object;
  onSave?: (record: object) => void;
  onSaveClientV2?: (recordId: string) => void;
  useCreateMutation: UseMutation<any>;
  preSubmit?: (data: object) => object;
  navigateToPath?: string;
  goToCreatedRecord?: boolean;
  otherProps?: object;
  submitAsPayload?: boolean;
  arrayBody?: boolean;
  trackingNames?: DialogTrackingNames;
  permissionScope?: PermissionScope;
  submitButtonCopy?: string;
};

type CreateRecordDialogProps = BaseCreateRecordDialogProps &
  (
    | {
        overrideTitle: string;
        overrideSuccessSnackbar: string;
        recordName?: string;
      }
    | {
        overrideTitle?: string;
        overrideSuccessSnackbar?: string;
        recordName: string;
      }
  );

export default function useCreateRecordDialog(props: CreateRecordDialogProps) {
  const methods = useForm<FieldValues>({
    defaultValues: props.newRecordDefaults,
    resolver: yupResolver(props.validationSchema),
    mode: 'all',
  });

  return {
    methods,
    control: methods.control,
    errors: methods.formState.errors,
    watch: methods.watch,
    dialogProps: {
      props,
      methods,
    },
    BaseCreateRecordDialog,
  };
}

const BaseCreateRecordDialog: FC<{
  open: boolean;
  onClose: () => void;
  dialogProps: {
    props: CreateRecordDialogProps;
    methods: UseFormReturn;
  };
  confirmClose?: boolean;
}> = ({ children, open, onClose, dialogProps, confirmClose }) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { dispatchSuccessSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [create, { isLoading: isLoadingCreate }] =
    dialogProps.props.useCreateMutation();

  const handleClose = () => {
    onClose();
    dialogProps.methods.reset();
    setSubmitError(null);
  };

  const onSubmit = async (data: FieldValues) => {
    try {
      const record = await create(
        dialogProps.props.arrayBody
          ? dialogProps.props.preSubmit
            ? dialogProps.props.preSubmit(data)
            : [data]
          : {
              ...(dialogProps.props.otherProps || {}),
              ...(dialogProps.props.submitAsPayload
                ? {
                    payload: dialogProps.props.preSubmit
                      ? dialogProps.props.preSubmit(data)
                      : data,
                  }
                : dialogProps.props.preSubmit
                ? dialogProps.props.preSubmit(data)
                : data),
            }
      ).unwrap();
      const recordId = _.get(record, 'id', _.get(record, 'createdIds[0]', ''));
      if (dialogProps.props.onSave) {
        dialogProps.props.onSave(record as object);
      }
      if (dialogProps.props.onSaveClientV2) {
        dialogProps.props.onSaveClientV2(recordId as string);
      }
      handleClose();
      dispatchSuccessSnackbar(
        dialogProps.props.overrideSuccessSnackbar ??
          `${dialogProps.props.recordName} Created`
      );
      if (
        dialogProps.props.goToCreatedRecord &&
        dialogProps.props.navigateToPath
      ) {
        navigate(`${dialogProps.props.navigateToPath}/${recordId}`);
      }
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        setSubmitError(errMessage);
      });
    }
  };

  return (
    <ExtendedDialog
      onSubmit={dialogProps.methods.handleSubmit(onSubmit)}
      formSubmitError={submitError}
      submitButtonCopy={dialogProps.props.submitButtonCopy || 'Save'}
      isSubmitting={isLoadingCreate}
      confirmClose={confirmClose}
      open={open}
      isForm
      onCloseDialog={handleClose}
      title={
        dialogProps.props.overrideTitle ??
        `Create ${dialogProps.props.recordName}`
      }
      trackingNames={dialogProps.props.trackingNames}
      permissionScope={dialogProps.props.permissionScope}
    >
      {children}
    </ExtendedDialog>
  );
};
