// constants used for Datagrid

export const defaultPageSize = 25;
export const defaultPageSizeOptions = [10, 25, 50, 100, 200];
export const defaultServerPageSizeOptions = [10, 25, 50, 100];
export const partRowHeight = 75;

export const statusTextMapper: { [key: string]: string } = {
  to_be_sent: 'Pending',
  sent: 'Sent',
  no_bid: 'No Bid',
  received: 'Received',
  partially_received: 'Partially Received',
  to_do: 'Pending',
  processing: 'Processing',
  processed: 'Sent',
  partially_processed: 'Processing',
  complete: 'Complete',
  no_sources: 'No Sources',
  hold: 'Hold',
  issue: 'Issue',
  voided: 'Voided',
  expected: 'Expected',
  on_hand: 'On Hand',
  onHand: 'On Hand',
  quarantined: 'Quarantined',
  requested: 'Requested',
  valid: 'Valid',
  passed: 'Passed',
  expired: 'Expired',
  inventory: 'Inventory',
  buyable: 'Buyable',
  quotable: 'Quotable',
  maybe: 'Maybe',
  need_to_find: 'Need to Find',
  draft: 'Draft',
  partially_shipped: 'Partially Shipped',
  shipped: 'Shipped',
  in_progress: 'In Progress',
  cancelled: 'Cancelled',
  rejected: 'Rejected',
  refreshed: 'Refreshed',
  purchased: 'Purchased',
  no_stock: 'No Stock',
  canceled: 'Canceled',
  partially_canceled: 'Canceled',
  confirmed: 'Confirmed',
  delayed: 'Delayed',
  action_required: 'Action Required',
  returned: 'Returned',
  partially_returned: 'Partially Returned',
  released: 'Released',
  consumed: 'Consumed',
  allocated: 'Allocated',
  unallocated: 'Unallocated',
  totalFree: 'Total Free',
  allocatedToRun: 'Allocated to Run',
  failed: 'Failed',
  partial: 'Partial',
  todo: 'To Do',
  approved: 'Approved',
  picked: 'Picked',
  delivered: 'Delivered',
  partially_delivered: 'Partially Delivered',
  packed: 'Packed',
  track: 'Tracking',
  used_in_bom: 'Used in BOM',
  supplier: 'From Supplier',
  mpn_search: 'MPN Search',
  parametric_search: 'Parametric Search',
  other_accounts: 'Community',
  form_fit_function: 'Form Fit Function',
  unknown: 'Unknown',
  identical: 'Identical',
  other: 'Other',
  ingested: 'Ingested',
  pending: 'Pending',
  smt_no_pnp: 'No PNP SMT Part',
  your_search: 'Your Search',
  partially_confirmed: 'Partially Confirmed',
  same_cpn: 'Same Custom PN',
  resell: 'Resell',
  approval_requested: 'Approval Requested',
  submitted: 'Submitted',
  closed: 'Closed',
  fulfilled: 'Fulfilled',
  unavailable: 'Unavailable',
  kitted: 'Kitted',
};
