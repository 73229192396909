import useCreateRecordDialog from 'hooks/useCreateRecordDialog';
import { useCreateKitRequestsMutation } from 'store/slices/clientV2/kitRequests';
import {
  createUpdateKitRequestFormConstants,
  createUpdateKitRequestValidationSchema,
} from 'views/kitting/components/constants';
import CreateUpdateKitRequestForm, {
  KitRequestFormDefaultValuesType,
} from 'views/kitting/components/CreateUpdateKitRequestForm';
import { createUpdateKitRequestPreSubmit } from 'views/kitting/components/utils';
import { FormProvider } from 'react-hook-form';
import { KitRequestSchema } from 'types/kitting';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import useGetCustomPropertyDefinitions from 'hooks/useGetCustomPropertyDefinitions';
import {
  CustomPropertyModel,
  CustomPropertyVisibility,
} from 'types/customProperty';

type CreateKitRequestDialogProps = {
  dialogOpen: boolean;
  onClose: () => void;
  onCreateSuccess?: (newKitRequestId: KitRequestSchema['id']) => void;
  overrideDefaults?: Record<string, string | boolean | string[] | null>;
};

const CreateKitRequestDialog = ({
  dialogOpen,
  onClose,
  onCreateSuccess,
  overrideDefaults,
}: CreateKitRequestDialogProps) => {
  const {
    customProperties,
    getDefaultValuesForCustomProperties,
    customPropertiesFieldName,
  } = useGetCustomPropertyDefinitions({
    model: CustomPropertyModel.KIT_REQUEST,
    visibilityContext: CustomPropertyVisibility.NEW_RECORD_DIALOG,
  });

  const formDefaults = useMemo(
    () =>
      ({
        [createUpdateKitRequestFormConstants.name.id]: overrideDefaults
          ? _.get(
              overrideDefaults,
              createUpdateKitRequestFormConstants.name.id,
              ''
            )
          : '',
        [createUpdateKitRequestFormConstants.sourceLocations.id]:
          overrideDefaults
            ? _.get(
                overrideDefaults,
                createUpdateKitRequestFormConstants.sourceLocations.id,
                []
              )
            : [],
        [createUpdateKitRequestFormConstants.productionRun.id]: overrideDefaults
          ? _.get(
              overrideDefaults,
              createUpdateKitRequestFormConstants.productionRun.id,
              ''
            )
          : '',
        [createUpdateKitRequestFormConstants.team.id]: overrideDefaults
          ? _.get(
              overrideDefaults,
              createUpdateKitRequestFormConstants.team.id,
              ''
            )
          : '',
        [createUpdateKitRequestFormConstants.shipTo.id]: overrideDefaults
          ? _.get(
              overrideDefaults,
              createUpdateKitRequestFormConstants.shipTo.id,
              ''
            )
          : '',
        [createUpdateKitRequestFormConstants.shipMethod.id]: overrideDefaults
          ? _.get(
              overrideDefaults,
              createUpdateKitRequestFormConstants.shipMethod.id,
              ''
            )
          : '',
        [createUpdateKitRequestFormConstants.shipPartial.id]: overrideDefaults
          ? _.get(
              overrideDefaults,
              createUpdateKitRequestFormConstants.shipPartial.id,
              false
            )
          : false,
        [createUpdateKitRequestFormConstants.targetShipDate.id]:
          overrideDefaults
            ? _.get(
                overrideDefaults,
                createUpdateKitRequestFormConstants.targetShipDate.id,
                null
              )
            : null,
        [customPropertiesFieldName]: getDefaultValuesForCustomProperties(),
      } as KitRequestFormDefaultValuesType),
    [
      overrideDefaults,
      customPropertiesFieldName,
      getDefaultValuesForCustomProperties,
    ]
  );

  const { methods, BaseCreateRecordDialog, dialogProps } =
    useCreateRecordDialog({
      formConstants: createUpdateKitRequestFormConstants,
      validationSchema: createUpdateKitRequestValidationSchema,
      newRecordDefaults: formDefaults,
      useCreateMutation: useCreateKitRequestsMutation,
      recordName: 'Kit',
      preSubmit: createUpdateKitRequestPreSubmit,
      onSaveClientV2: onCreateSuccess || undefined,
    });

  useEffect(() => {
    if (dialogOpen) {
      methods.reset(formDefaults);
    }
  }, [methods.reset, overrideDefaults, dialogOpen]);

  return (
    <BaseCreateRecordDialog
      open={dialogOpen}
      onClose={onClose}
      dialogProps={dialogProps}
    >
      <FormProvider {...methods}>
        <CreateUpdateKitRequestForm
          defaultValues={formDefaults}
          disableProgramField={
            !!_.get(
              overrideDefaults,
              createUpdateKitRequestFormConstants.productionRun.id
            )
          }
          customProperties={customProperties}
        />
      </FormProvider>
    </BaseCreateRecordDialog>
  );
};

export default CreateKitRequestDialog;
