import { useEffect } from 'react';
import { DialogContentText } from '@mui/material';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { GenericResource, ToggleMutation } from 'types/api';
import useToggleRecord from 'hooks/clientV2/useToggleRecordDialog';

export enum ToggleRecordActionTypes {
  APPROVE = 'APPROVE',
  ARCHIVE = 'ARCHIVE',
  LOCK = 'LOCK',
  VOID = 'VOID',
}

const ToggleActionStrings: {
  [key in ToggleRecordActionTypes]: ToggleRecordActionStrings;
} = {
  [ToggleRecordActionTypes.APPROVE]: {
    toggleToTrue: 'Approve',
    toggleToFalse: 'Unapprove',
    successToggledTrue: 'Approved',
    successToggledFalse: 'Unapproved',
  },
  [ToggleRecordActionTypes.ARCHIVE]: {
    toggleToTrue: 'Archive',
    toggleToFalse: 'Unarchive',
    successToggledTrue: 'Archived',
    successToggledFalse: 'Unarchived',
  },
  [ToggleRecordActionTypes.LOCK]: {
    toggleToTrue: 'Lock',
    toggleToFalse: 'Unlock',
    successToggledTrue: 'Locked',
    successToggledFalse: 'Unlocked',
  },
  [ToggleRecordActionTypes.VOID]: {
    toggleToTrue: 'Reject',
    toggleToFalse: 'Un-reject',
    successToggledTrue: 'Rejected',
    successToggledFalse: 'Un-rejected',
  },
};

export type ToggleRecordActionStrings = {
  toggleToTrue: string;
  toggleToFalse: string;
  successToggledTrue: string;
  successToggledFalse: string;
};

interface ToggleRecordDialogProps<T extends GenericResource> {
  id: string;
  isCurrentlyTrue: boolean;
  dialogOpen: boolean;
  onClose: () => void;
  recordName: string;
  message?: string;
  useToggleTrueMutation: ToggleMutation<T>;
  useToggleFalseMutation: ToggleMutation<T>;
  toggleActionType: ToggleRecordActionTypes;
  onSuccess?: () => void;
}

/**
 * ApproveUnapproveRecordDialog
 * To be used specifically with Client V2 api
 */
const ToggleRecordDialog = <T extends GenericResource>({
  id,
  isCurrentlyTrue,
  dialogOpen,
  onClose,
  recordName,
  message,
  useToggleTrueMutation,
  useToggleFalseMutation,
  toggleActionType,
  onSuccess,
}: ToggleRecordDialogProps<T>) => {
  const actionStrings = ToggleActionStrings[toggleActionType];

  const handleSuccess = () => {
    onClose();
    onSuccess && onSuccess();
  };

  const { handleApprove, isLoading, submitError, resetSubmitError } =
    useToggleRecord<T>({
      useToggleTrueMutation,
      useToggleFalseMutation,
      recordName,
      id,
      isCurrentlyTrue,
      onSuccess: handleSuccess,
      actionStrings: {
        successToggledTrue: actionStrings.successToggledTrue,
        successToggledFalse: actionStrings.successToggledFalse,
      },
    });

  useEffect(() => {
    if (!dialogOpen) {
      resetSubmitError();
    }
  }, [dialogOpen]);

  return (
    <ExtendedDialog
      title={`${
        isCurrentlyTrue
          ? actionStrings.toggleToFalse
          : actionStrings.toggleToTrue
      } ${recordName}`}
      open={dialogOpen}
      onCloseDialog={onClose}
      submitButtonCopy={
        isCurrentlyTrue
          ? actionStrings.toggleToFalse
          : actionStrings.toggleToTrue
      }
      onClickPrimaryButton={handleApprove}
      formSubmitError={submitError}
      isSubmitting={isLoading}
    >
      <DialogContentText>
        {message ||
          `Are you sure you want to ${(isCurrentlyTrue
            ? actionStrings.toggleToFalse
            : actionStrings.toggleToTrue
          ).toLowerCase()} this ${recordName}?`}
      </DialogContentText>
    </ExtendedDialog>
  );
};

export default ToggleRecordDialog;
