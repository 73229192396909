import { ALL_APPS, ALL_VIEW_IDS, ALL_VIEWS } from 'constants/appConstants';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import FullHeightMainCardWrapper from 'layout/MainLayout/FullHeightMainCardWrapper';

const Rfqs = Loadable(lazy(() => import('views/sourcing/Rfqs')));
const Rfq = Loadable(lazy(() => import('views/sourcing/Rfq')));
const Quotes = Loadable(lazy(() => import('views/sourcing/Quotes')));
const Quote = Loadable(lazy(() => import('views/sourcing/Quote')));

export const SourcingRoutes = [
  {
    path: `${ALL_APPS.SOURCING.path}`,
    element: (
      <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.SOURCING.id }}>
        <Navigate to={`${ALL_VIEWS.RFQ.path}`} replace />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.RFQ.path}`,
    element: (
      <FullHeightMainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.SOURCING.id,
            view: ALL_VIEW_IDS.RFQ,
          }}
        >
          <Rfqs />
        </PermissionsRouteWrapper>
      </FullHeightMainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.RFQ.path}/:rfqId`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.SOURCING.id,
          view: ALL_VIEW_IDS.RFQ,
        }}
      >
        <Rfq />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.QUOTE.path}`,
    element: (
      <FullHeightMainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.SOURCING.id,
            view: ALL_VIEW_IDS.QUOTE,
          }}
        >
          <Quotes />
        </PermissionsRouteWrapper>
      </FullHeightMainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.QUOTE.path}/:quoteId`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.SOURCING.id,
          view: ALL_VIEW_IDS.QUOTE,
        }}
      >
        <Quote />
      </PermissionsRouteWrapper>
    ),
  },
];
