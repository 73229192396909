import PartContextualSearchInputSingular from 'ui-component/PartContextualSearchInputSingular';
import { createKitRequestLineFormConstants } from 'views/kitting/Kit/components/constants';
import { HookFormInput } from 'ui-component/HookFormComponents';
import { useFormContext } from 'react-hook-form';
import { CustomProperty } from 'types/customProperty';
import _ from 'lodash';
import { HookFormCustomPropertyInput } from 'ui-component/HookFormComponents/HookFormCustomPropertyInput';
import { Stack } from '@mui/system';

const CreateKitRequestFromPart = ({
  customProperties,
}: {
  customProperties?: CustomProperty[];
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  return (
    <>
      <PartContextualSearchInputSingular
        id={createKitRequestLineFormConstants.part.id}
        label={createKitRequestLineFormConstants.part.label}
        helperText={
          errors[createKitRequestLineFormConstants.part.id]
            ? `${errors[createKitRequestLineFormConstants.part.id]?.message}`
            : undefined
        }
        error={Boolean(errors[createKitRequestLineFormConstants.part.id])}
        onChange={(__, value: { id: string }[]) => {
          if (value && value.length) {
            setValue(createKitRequestLineFormConstants.part.id, value[0]?.id);
          } else {
            setValue(createKitRequestLineFormConstants.part.id, '');
          }
        }}
        sx={{ mb: 2 }}
      />
      <HookFormInput
        control={control}
        name={createKitRequestLineFormConstants.targetQuantityPart.id}
        label={createKitRequestLineFormConstants.targetQuantityPart.label}
        type="number"
        errors={errors}
      />
      {customProperties && (
        <Stack sx={{ mt: 2 }}>
          {_.map(customProperties, (property) => (
            <HookFormCustomPropertyInput
              control={control}
              errors={errors}
              key={property.key}
              customProperty={property}
              setValue={setValue}
            />
          ))}
        </Stack>
      )}
    </>
  );
};

export default CreateKitRequestFromPart;
