import DetailLayout from 'ui-component/DetailLayout';
import { useNavigate } from 'react-router-dom';
import KitDetail from 'views/kitting/Kit/components/KitDetail';
import {
  IconFiles,
  IconLink,
  IconListDetails,
  IconShoppingCart,
} from '@tabler/icons-react';
import {
  ALL_APP_IDS,
  ALL_APPS,
  ALL_FEATURE_IDS,
  ALL_VIEW_IDS,
  ALL_VIEWS,
} from 'constants/appConstants';
import KitRequestLineTable from 'views/kitting/Kit/KitRequestLineTable';
import StockLotDocumentationTabV2 from 'views/inventory/StockLot/StockLotDocumentationTabV2';
import { appPermissionAccessLevels } from 'types/apps';
import SidebarApproveButtonClientV2 from 'ui-component/DetailLayout/SidebarComponents/SidebarApproveButtonClientV2';
import {
  useApproveKitRequestMutation,
  useDeleteKitRequestsMutation,
  useUnapproveKitRequestMutation,
  useUpdateKitRequestsMutation,
} from 'store/slices/clientV2/kitRequests';
import SidebarDeleteButtonClientV2 from 'ui-component/DetailLayout/SidebarComponents/SidebarDeleteButtonClientV2';
import { InfoSectionItemFormatTypes } from 'ui-component/InfoSectionItem';
import EditInline from 'ui-component/EditInline';
import {
  HookFormTeamAutocomplete,
  HookFormToggle,
} from 'ui-component/HookFormComponents';
import useCustomTeamTypeName from 'hooks/useCustomTeamTypeName';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { createUpdateKitRequestFormConstants } from 'views/kitting/components/constants';
import { EditInlineDatePicker } from 'ui-component/HookFormComponents/InlineEditInputComponents';
import { convertDate, formatToUniversalDate } from 'utils/dates';
import { RelatedRecord, RelatedRecordType } from 'types/inventory';
import RelatedProductionRunDialog from 'ui-component/RelatedProductionRunDialog';
import { useMemo, useState } from 'react';
import { useKitRequestContext } from 'views/kitting/Kit/KitRequestContext';
import { KitRequestSchema } from 'types/kitting';
import ProgressStack from 'ui-component/ProgressStack';
import { getProgressStackData } from 'views/kitting/Kit/utils';
import { useIsCofactr } from 'hooks/useIsCofactr';
import _ from 'lodash';
import SidebarCustomProperties from 'ui-component/DetailLayout/SidebarComponents/SidebarCustomProperties';
import { CustomPropertyModel } from 'types/customProperty';

const Kit = ({ kitRequestId }: { kitRequestId?: KitRequestSchema['id'] }) => {
  const navigate = useNavigate();
  const { isCofactr } = useIsCofactr();

  const [openRelatedProductionRunDialog, setOpenRelatedProductionRunDialog] =
    useState(false);

  const { hasAppPermission } = useAppAccessContext();
  const propertiesAppEnabled = hasAppPermission(ALL_APP_IDS.PROPERTIES);
  const { handleReplaceWithCustomTeamName } = useCustomTeamTypeName();

  const {
    kitRequest,
    isLoadingKitRequest,
    kitRequestStatuses,
    decoratedKitRequestLines,
  } = useKitRequestContext();

  const disableEdit = Boolean(kitRequest?.approved || kitRequest?.locked);

  const relatedShipments = _.chain(decoratedKitRequestLines)
    .flatMap('shipmentLines')
    .map('shipment')
    .uniqBy('id')
    .map(
      (shipment) =>
        ({
          name: shipment?.shipmentCode ?? '',
          recordType: RelatedRecordType.SHIPMENT,
          recordId: shipment?.id ?? '',
          orgId: kitRequest?.org ?? '',
        } as RelatedRecord)
    )
    .value();

  const relatedProductionRun = {
    name: kitRequest?.productionRun?.name ?? '',
    recordType: RelatedRecordType.PRODUCTION_RUN,
    recordId: kitRequest?.productionRun?.id ?? '',
    orgId: kitRequest?.org ?? '',
  } as RelatedRecord;

  const relatedSnapshot = {
    name: 'Cart',
    recordType: RelatedRecordType.SNAPSHOT,
    recordId: kitRequest?.kittedFor ?? '',
    orgId: kitRequest?.org ?? '',
  } as RelatedRecord;

  const progressStackBarData = useMemo(
    () => getProgressStackData(kitRequestStatuses),
    [kitRequestStatuses]
  );

  return (
    <>
      {kitRequestId && (
        <DetailLayout
          headerSection={<KitDetail kitRequestId={kitRequestId} />}
          headerBottomContent={<ProgressStack bars={progressStackBarData} />}
          tabOptions={[
            {
              name: 'lines',
              label: 'Parts',
              icon: <IconListDetails />,
              loading: false,
              contents: <KitRequestLineTable />,
              permissionScope: { app: ALL_APPS.KITTING.id },
              gridPadding: true,
            },
            {
              name: 'documents',
              label: 'Documents',
              icon: <IconFiles />,
              contents: (
                <StockLotDocumentationTabV2
                  kitRequestId={kitRequestId}
                  allowUpload
                  uploadPermissionScope={{
                    app: ALL_APP_IDS.ORG_SETTINGS,
                    feature: ALL_FEATURE_IDS.FILE_ATTACHMENTS,
                    accessLevel: appPermissionAccessLevels.edit,
                  }}
                />
              ),
              loading: isLoadingKitRequest,
              permissionScope: {
                app: ALL_APPS.ORG_SETTINGS.id,
                feature: ALL_FEATURE_IDS.FILE_ATTACHMENTS,
              },
              gridPadding: true,
            },
          ]}
          sidebarActionButtons={[
            <SidebarApproveButtonClientV2
              idToUpdate={kitRequestId}
              recordName="Kit"
              approved={!!kitRequest?.approved}
              useClientApproveMutation={useApproveKitRequestMutation}
              useClientUnapproveMutation={useUnapproveKitRequestMutation}
              disabled={
                isLoadingKitRequest ||
                (!!kitRequest?.approved && !isCofactr) ||
                (decoratedKitRequestLines ?? []).length === 0
              }
              tooltip={
                !!kitRequest?.approved && !isCofactr
                  ? 'If you need to unapprove this kit request, please contact Cofactr Support using the chat icon in the bottom right corner.'
                  : undefined
              }
            />,
            <SidebarDeleteButtonClientV2
              idToUpdate={kitRequestId}
              recordName="Kit"
              useClientDeleteMutation={useDeleteKitRequestsMutation}
              disabled={!!kitRequest?.approved}
              onSuccess={() => {
                navigate(ALL_VIEWS[ALL_VIEW_IDS.KITS].path as string);
              }}
            />,
          ]}
          sidebarInfoSections={[
            {
              caption: 'Approved By',
              placeholder: ' ',
              clipboard: false,
              isLoading: isLoadingKitRequest,
              formatType: InfoSectionItemFormatTypes.user,
              userValue: kitRequest?.approvedBy,
              dateValue: kitRequest?.approvedAt,
              useInfoSectionAutomationOption: true,
              visible: !!kitRequest?.approvedBy,
            },
            {
              caption: 'Target Ship Date',
              placeholder: ' ',
              clipboard: false,
              isLoading: isLoadingKitRequest,
              formatType: InfoSectionItemFormatTypes.dateShort,
              value: kitRequest?.targetShipDate,
              useInfoSectionAutomationOption: true,
              disableEdit,
              visible: !!kitRequest?.shipTo,
              editInline: (
                <EditInline
                  useMutation={useUpdateKitRequestsMutation}
                  preSubmit={(data) => ({
                    [createUpdateKitRequestFormConstants.targetShipDate.id]:
                      data ? formatToUniversalDate(data) : null,
                  })}
                  name={createUpdateKitRequestFormConstants.targetShipDate.id}
                  label={
                    createUpdateKitRequestFormConstants.targetShipDate.label
                  }
                  value={
                    kitRequest?.targetShipDate
                      ? convertDate(new Date(kitRequest.targetShipDate))
                      : undefined
                  }
                  id={kitRequestId}
                  submitAsClientV2Api
                >
                  {EditInlineDatePicker}
                </EditInline>
              ),
            },
            {
              caption: 'Ship Partial',
              isLoading: isLoadingKitRequest,
              formatType: InfoSectionItemFormatTypes.boolean,
              value: kitRequest?.shipPartial,
              visible: !!kitRequest?.shipTo,
              editInline: (
                <EditInline
                  useMutation={useUpdateKitRequestsMutation}
                  name={createUpdateKitRequestFormConstants.shipPartial.id}
                  hideLabel
                  value={kitRequest?.shipPartial}
                  id={kitRequest?.id || ''}
                  submitAsClientV2Api
                >
                  <HookFormToggle disableRequiredProps />
                </EditInline>
              ),
            },
            {
              caption: handleReplaceWithCustomTeamName('Team'),
              isLoading: isLoadingKitRequest,
              formatType: InfoSectionItemFormatTypes.string,
              value: kitRequest?.team?.name ?? '-',
              permissionScope: {
                app: ALL_APPS.PROPERTIES.id,
                accessLevel: appPermissionAccessLevels.edit,
              },
              disableEdit: Boolean(
                disableEdit || kitRequest?.productionRun?.team
              ),
              editInline: (
                <EditInline
                  useMutation={useUpdateKitRequestsMutation}
                  name={createUpdateKitRequestFormConstants.team.id}
                  label={handleReplaceWithCustomTeamName(
                    createUpdateKitRequestFormConstants.team.label
                  )}
                  value={kitRequest?.team?.id}
                  id={kitRequestId}
                  submitAsClientV2Api
                  preSubmit={(data) => ({
                    [createUpdateKitRequestFormConstants.team.id]: data ?? null,
                  })}
                >
                  <HookFormTeamAutocomplete isInlineEdit disableRequiredProps />
                </EditInline>
              ),
              visible: propertiesAppEnabled,
            },
            {
              caption: 'Programs',
              isLoading: isLoadingKitRequest,
              formatType: InfoSectionItemFormatTypes.relatedRecords,
              Icon: IconLink,
              onOpenEditRelatedRecords: () =>
                setOpenRelatedProductionRunDialog(true),
              count: kitRequest?.productionRun ? 1 : 0,
              relatedRecordsValue: kitRequest?.productionRun
                ? [relatedProductionRun]
                : [],
            },
            {
              caption: 'Shipments',
              isLoading: isLoadingKitRequest,
              formatType: InfoSectionItemFormatTypes.relatedRecords,
              Icon: IconLink,
              relatedRecordsValue: relatedShipments,
              visible: relatedShipments.length > 0,
            },
            {
              caption: 'Kitted For',
              isLoading: isLoadingKitRequest,
              formatType: InfoSectionItemFormatTypes.relatedRecords,
              Icon: IconShoppingCart,
              relatedRecordsValue: kitRequest?.kittedFor
                ? [relatedSnapshot]
                : [],
              visible: !!kitRequest?.kittedFor,
            },
          ]}
          additionalSidebarSections={[
            ...(kitRequest
              ? [
                  <SidebarCustomProperties
                    useMutation={useUpdateKitRequestsMutation}
                    record={kitRequest}
                    model={CustomPropertyModel.KIT_REQUEST}
                    isLoadingRecord={isLoadingKitRequest}
                    disableEdit={disableEdit}
                    idProp="id"
                    submitAsClientV2Api
                  />,
                ]
              : []),
          ]}
        />
      )}
      <RelatedProductionRunDialog
        onClose={() => setOpenRelatedProductionRunDialog(false)}
        open={openRelatedProductionRunDialog}
        relatedProductionRun={relatedProductionRun}
        ids={[kitRequestId ?? '']}
        useUpdateBatchMutation={useUpdateKitRequestsMutation}
      />
    </>
  );
};

export default Kit;
