import {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridActionsColDef,
  GridExcelExportMenuItem,
  GridExportMenuItemProps,
  GridFilterInitialState,
  GridPrintExportMenuItem,
  GridRowId,
  GridRowParams,
  GridSelectionModel,
  GridSlotsComponent,
  GridSortingInitialState,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  useGridApiRef,
  ValueOptions,
} from '@mui/x-data-grid-premium';
import { defaultPageSize, defaultPageSizeOptions } from 'constants/datagrid';
import useSavedDatagridState from 'hooks/useSavedDatagridState';
import {
  Box,
  ButtonProps,
  Divider,
  Grid,
  LinearProgress,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { nowShortDate } from 'utils/dates';
import _ from 'lodash';
import {
  Icon as TablerIcon,
  IconArrowBackUp,
  IconCirclePlus,
  IconColumns,
  IconDownload,
  IconFilter,
  IconMinus,
  IconPlus,
  IconUpload,
} from '@tabler/icons-react';
import { DatagridNames, ExtendedGridColDef, RowAction } from 'types/datagrid';
import { appPermissionStates, PermissionScope } from 'types/apps';
import { getApplyFilterFnCaseInsensitive } from 'ui-component/DataGrid/FilterOperators';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import VerboseLoader, {
  VerboseLoaderProps,
} from 'ui-component/extended/VerboseLoader';
import { Theme, useTheme } from '@mui/material/styles';
import { GenerateReportDataType, ReportDataTypes } from 'types/generateReports';
import { useFeature } from '@growthbook/growthbook-react';
import { COLOR_OPTIONS, FeatureFlags } from 'types';
import useGenerateReports from 'hooks/useGenerateReport';
import ExtendedQuickFilter from 'ui-component/DataGrid/ExtendedQuickFilter';
import AccordionCard from 'ui-component/cards/AccordionCard';
import { actionButtonSpacing, gridSpacing } from 'constants/themeConstants';
import DataGridChart from 'ui-component/DataGrid/DatagridChart';
import { SxProps } from '@mui/system';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { processCsvData, processExcelData } from 'ui-component/DataGrid/utils';
import { FieldValues } from 'react-hook-form';
import { renderMutationCellWithInput } from 'ui-component/DataGrid/Render';
import renderCellWithEditWrapper from 'ui-component/DataGrid/Render/RenderCellWithEditWrapper';
import { useDeviceContext } from 'hooks/useDeviceContext';
import { downloadFile } from 'utils/functions';
import ExtendedGridActionsCellItem from 'ui-component/DataGrid/ExtendedGridActionsCellItem';
import { gridClasses } from '@mui/x-data-grid';
import { FullOrIconButton } from 'ui-component/DataGrid/components/FullOrIconButton';
import { MoreActionsButton } from 'ui-component/DataGrid/components/MoreActionsButton';

const narrowWindowToolbarButtonSx = {
  width: '34px',
  height: '34px',
  borderRadius: '17px',
  minWidth: 'unset',
  '& .MuiButton-startIcon': { mx: 0 },
};
export interface VisibleColumnGroup {
  name: string;
  label: string;
  icon?: ReactNode;
  visibleColumns: string[];
  permissionScope?: PermissionScope;
}

type DynamicFilter = {
  field: string;
  options: ValueOptions[];
};

type DatagridRecordButtonProps = {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
  permissionScope?: PermissionScope;
  trackingName?: string;
};

export interface ExtendedDatagridProps<T> extends DataGridPremiumProps {
  children?: ReactNode;
  editable?: boolean;
  editLocked?: boolean;
  toolbarExport?: boolean;
  toolbarColumns?: boolean;
  toolbarFilters?: boolean;
  exportFileNamePrefix?: string | null;
  noRows?: string | ReactNode;
  initialSorting?: GridSortingInitialState | null;
  initialFilter?: GridFilterInitialState | null;
  initialVisibleColumns?: string[] | null;
  visibleColumnGroups?: VisibleColumnGroup[] | null;
  initialPinned?: string[] | null;
  pinnedActions?: boolean;
  gridName: DatagridNames;
  detailPanel?: boolean;
  detailExclusiveOpen?: boolean;
  hideToolbar?: boolean;
  noRowHeightAuto?: boolean;
  initialSx?: SxProps<Theme>;
  dynamicFilters?: DynamicFilter[];
  onPageSizeChange?: (pageSize: number) => void;
  columns: ExtendedGridColDef[];
  exportCsv?: () => void;
  isExportLoading?: boolean;
  apiRefServerSide?: MutableRefObject<GridApiPremium>;
  verboseLoader?: VerboseLoaderProps;
  generateReportData?: GenerateReportDataType;
  pageSizeOptions?: number[];
  showCharts?: boolean;
  loadingCharts?: boolean;
  overridePageSize?: number;
  shouldSaveDatagridState?: boolean;
  newRecordButton?: DatagridRecordButtonProps;
  uploadRecordButton?: DatagridRecordButtonProps;
  rowActions?: RowAction<T>[];
  fullCard?: boolean;
}

const defaultComponents = {};
const defaultInitialSx = {};
const defaultOnPageSizeChange = () => {};

const ExtendedDatagrid = <T,>({
  children,
  loading = false,
  pagination = true,
  editable = false,
  editLocked = false,
  toolbarExport = true,
  toolbarColumns = true,
  toolbarFilters = true,
  exportFileNamePrefix = null,
  noRows = 'No records found',
  initialSorting = null,
  initialFilter = null,
  initialVisibleColumns = null,
  visibleColumnGroups = null,
  initialPinned = null,
  pinnedActions = true,
  gridName,
  detailPanel = false,
  detailExclusiveOpen = false,
  getDetailPanelContent,
  onRowClick,
  checkboxSelection = false,
  components = defaultComponents,
  columns,
  rows,
  hideToolbar = false,
  noRowHeightAuto = false,
  initialSx = defaultInitialSx,
  dynamicFilters,
  onPageSizeChange = defaultOnPageSizeChange,
  exportCsv,
  isExportLoading,
  apiRefServerSide,
  verboseLoader,
  generateReportData,
  pageSizeOptions = defaultPageSizeOptions,
  showCharts = false,
  loadingCharts = false,
  overridePageSize,
  shouldSaveDatagridState = true,
  newRecordButton = {
    label: 'Add',
    disabled: false,
    onClick: undefined,
  },
  uploadRecordButton = {
    label: 'Upload',
    disabled: false,
    onClick: undefined,
  },
  aggregationModel,
  rowActions = [],
  onSelectionModelChange,
  fullCard = false,
  ...others
}: ExtendedDatagridProps<T>) => {
  const generateReportsFeature = useFeature(FeatureFlags.generateReports).on;
  const theme = useTheme();
  const { narrowWindow } = useDeviceContext();

  const {
    appPermissions,
    appsWithPermissions,
    isLoading: isLoadingAppAccess,
    isPermissionDisabled,
  } = useAppAccessContext();
  const apiRefClientSide = useGridApiRef();
  const apiRef = apiRefServerSide || apiRefClientSide;

  const [apiRefReady, setApiRefReady] = useState(false);

  useEffect(() => {
    if (apiRef?.current && !apiRefReady) {
      setApiRefReady(true);
    }
  }, [apiRef, apiRefReady]);

  const { savedDatagridState } = useSavedDatagridState({
    gridName,
    apiRef,
    shouldSaveDatagridState,
  });
  const [pageSize, setPageSize] = useState<number>(
    overridePageSize ??
      savedDatagridState?.pagination?.pageSize ??
      defaultPageSize
  );

  useEffect(() => onPageSizeChange(pageSize), []);

  // handle page size change
  const handlePageSizeChangeCallback = useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize);
      onPageSizeChange(newPageSize);
    },
    [onPageSizeChange]
  );

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<
    GridRowId[]
  >([]);
  const handleDetailPanelExpandedRowIdsChange = useCallback(
    (ids: GridRowId[]) => {
      setDetailPanelExpandedRowIds(ids);
    },
    []
  );

  // handle checkbox selection

  const calculatedCheckboxSelection =
    checkboxSelection ||
    _.filter(rowActions, (action) => !!action.onBatchClick).length > 0;

  const [selectedRowIds, setSelectedRowIds] = useState<GridSelectionModel>([]);
  const selectedRowData = useMemo(
    () => rows.filter((row) => selectedRowIds.includes(row?.id?.toString())),
    [rows, selectedRowIds]
  );
  const handleSelect = (selectionModel: GridSelectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  // generate action getter based on rowActions prop
  const actionGetter = useCallback(
    ({ row }: GridRowParams) =>
      _.chain(rowActions)
        .filter((action) => !!action.onRowClick && !action.hideRowAction?.(row))
        .map((action) => {
          const ActionIcon: TablerIcon = action.getRowIcon
            ? action.getRowIcon(row)
            : action.Icon;
          return (
            <ExtendedGridActionsCellItem
              onClick={() => {
                if (action.onRowClick) {
                  action.onRowClick(row);
                }
              }}
              color={action?.color ?? action.getColor?.(row)}
              icon={
                <ActionIcon
                  color={
                    action.disabled || action.isRowDisabled?.(row)
                      ? theme.palette.grey[500]
                      : _.get(theme.palette, [
                          action.color ||
                            action.getColor?.(row) ||
                            COLOR_OPTIONS.primary,
                          'main',
                        ])
                  }
                />
              }
              label={action.getLabel?.(row)}
              disabled={action.disabled || action.isRowDisabled?.(row)}
              showInMenu={action.showInRowMenu}
              permissionScope={action.permissionScope}
              tooltip={action.getRowTooltip?.(row)}
            />
          );
        })
        .value(),
    [rowActions]
  );

  // construct sx based on props
  const hasPartColumn = useMemo(
    () =>
      _.chain(columns)
        .map('field')
        .some((c) => _.includes(c, 'part'))
        .value(),
    [columns.length]
  );
  const sx: { [key: string]: object } = useMemo(() => {
    const sxObject: { [key: string]: object } = {
      '& ::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
      },
      '& ::-webkit-scrollbar-track': {
        backgroundColor: '#f5f5f5',
      },
      '& ::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        backgroundColor: '#c4c4c4',
      },
      '.MuiDataGrid-cell--withRenderer': {
        overflow: 'hidden',
        width: '100%',
      },
      [`.${gridClasses.cell}--editing:last-child`]: {
        display: 'flex',
        flexDirection: 'row-reverse',
      },
      '.MuiDataGrid-detailPanel': {
        backgroundColor: theme.palette.grey[100],
      },
      '.MuiDataGrid-row--detailPanelExpanded': {
        backgroundColor: theme.palette.grey[100],
      },
      '& .MuiDataGrid-pinnedRows': {
        boxShadow: 'none',
      },
      '& .MuiDataGrid-cell': {
        px: '3px',
      },
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'start',
      },
      ...(fullCard && {
        '& .MuiDataGrid-columnHeader:first-of-type, & .MuiDataGrid-cell:first-of-type':
          {
            paddingLeft: 2,
          },
      }),
    };

    if (!hasPartColumn) {
      sxObject['&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell'] = {
        py: '8px',
      };
      sxObject['&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell'] = {
        py: '15px',
      };
      sxObject['&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell'] = {
        py: '22px',
      };
    }
    if (onRowClick || editable) {
      sxObject['&:hover'] = {
        '.MuiDataGrid-row': {
          cursor: 'pointer',
        },
      };
    } else {
      sxObject['.MuiDataGrid-row.Mui-hovered'] = {
        background: 'white',
      };
      sxObject['& .MuiDataGrid-row:hover'] = {
        background: 'white',
      };
    }
    return sxObject;
  }, [hasPartColumn, !!onRowClick, editable, fullCard]);

  // construct visible column groups
  const memoizedVisibleGroups = useMemo(() => {
    if (!visibleColumnGroups) {
      return null;
    }
    const groups = visibleColumnGroups;
    // add action columns to visibleColumnGroups
    groups.forEach((g) => {
      g.visibleColumns.push('actions');
    });

    // filter visibleColumnGroups based on appPermissions
    return groups.filter(
      (g) =>
        !g?.permissionScope?.app ||
        _.get(
          appPermissions,
          [g?.permissionScope.app, 'state'],
          appPermissionStates.disabled
        ) === appPermissionStates.enabled
    );
  }, [appPermissions]);

  // construct memoized columnVisibilityModel
  const memoizedColumnVisibilityModel: { [key: string]: boolean } =
    useMemo(() => {
      const columnVisibilityModel: { [key: string]: boolean } = {};

      if (initialVisibleColumns) {
        initialVisibleColumns.push('actions');
        _.map(columns, 'field').forEach((col) => {
          columnVisibilityModel[col] = initialVisibleColumns.includes(col);
        });
      }

      return columnVisibilityModel;
    }, [initialVisibleColumns]);

  // construct combinedInitialState
  const combinedInitialState = useMemo(() => {
    const initialStateObject: { [key: string]: object } = {};
    if (initialSorting) {
      initialStateObject.sorting = initialSorting;
    }
    if (initialFilter) {
      initialStateObject.filter = initialFilter;
    }
    if (pinnedActions && !initialPinned) {
      initialStateObject.pinnedColumns = {
        right: ['actions'],
      };
    } else if (!pinnedActions && initialPinned) {
      initialStateObject.pinnedColumns = {
        left: initialPinned,
      };
    } else if (pinnedActions && initialPinned) {
      initialStateObject.pinnedColumns = {
        right: ['actions'],
        left: initialPinned,
      };
    }
    if (memoizedColumnVisibilityModel) {
      initialStateObject.columns = {
        columnVisibilityModel: memoizedColumnVisibilityModel,
        all: _.map(columns, 'field'),
      };
    } else {
      initialStateObject.columns = { all: _.map(columns, 'field') };
    }
    return initialStateObject;
  }, [
    initialSorting,
    initialFilter,
    pinnedActions,
    initialPinned,
    memoizedColumnVisibilityModel,
  ]);

  // propagate new columns to saved datagrid state that predates those columns being added to code
  const newSavedColumnVisibilityModel: { [key: string]: boolean } =
    useMemo(() => {
      const savedColumnVisibilityModel =
        savedDatagridState?.columns?.columnVisibilityModel ||
        memoizedColumnVisibilityModel;
      const newSavedColumnVisibilityModelObject: { [key: string]: boolean } =
        {};
      _.forOwn(memoizedColumnVisibilityModel, (value, key) => {
        newSavedColumnVisibilityModelObject[key] = _.get(
          savedColumnVisibilityModel,
          key,
          value
        );
      });
      return newSavedColumnVisibilityModelObject;
    }, [
      memoizedColumnVisibilityModel,
      savedDatagridState?.columns?.columnVisibilityModel,
    ]);

  const getUpdatedSavedDatagridState = useCallback(() => {
    if (savedDatagridState) {
      const updatedSavedDatagridState = structuredClone(savedDatagridState);
      _.set(
        updatedSavedDatagridState,
        ['columns', 'columnVisibilityModel'],
        newSavedColumnVisibilityModel
      );
      return updatedSavedDatagridState;
    }
    return combinedInitialState;
  }, [savedDatagridState]);

  // construct detailPanel attributes based on props
  const detailPanelAttributes: { [key: string]: unknown } = useMemo(() => {
    const getDetailPanelHeight = () => 'auto';
    const getDetailPanelContentCallback = (params: GridRowParams) =>
      getDetailPanelContent && getDetailPanelContent(params);
    const detailPanelAttributesObject: { [key: string]: unknown } = {};
    if (detailPanel) {
      detailPanelAttributesObject.getDetailPanelHeight = getDetailPanelHeight;
      detailPanelAttributesObject.getDetailPanelContent = (
        params: GridRowParams
      ) => getDetailPanelContentCallback(params);
      detailPanelAttributesObject.onDetailPanelExpandedRowIdsChange =
        handleDetailPanelExpandedRowIdsChange;
    }
    if (detailExclusiveOpen) {
      detailPanelAttributesObject.detailPanelExpandedRowIds =
        detailPanelExpandedRowIds;
    }
    return detailPanelAttributesObject;
  }, [
    detailPanel,
    detailExclusiveOpen,
    getDetailPanelContent,
    detailPanelExpandedRowIds.length,
    handleDetailPanelExpandedRowIdsChange,
  ]);

  // inject saved datagrid state for flex and editLocked state into columns
  const columnsWithSavedState = useMemo(() => {
    let columnsWithSavedStateObject = _.chain(columns)
      .cloneDeep()
      .filter((v) => !!v)
      .value();
    columnsWithSavedStateObject.forEach((c) => {
      if ('flex' in c) {
        c.flex = _.get(
          savedDatagridState,
          ['columns', 'dimensions', c.field, 'flex'],
          c.flex
        );
      }
      if ('editable' in c && !_.get(c, 'allowEditWhileLocked', false)) {
        if (editLocked) {
          c.editable = false;
        }
        if (isPermissionDisabled(c?.editPermissionScope)) {
          c.editable = false;
        }
      }
    });

    // add detail panel toggle column
    if (detailPanel) {
      columnsWithSavedStateObject = [
        {
          ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          type: 'string',
          hideable: false,
          headerName: 'Expand Toggle',
        },
        ...columnsWithSavedStateObject,
      ];
    }

    // add checkbox selection column
    if (calculatedCheckboxSelection) {
      columnsWithSavedStateObject = [
        {
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          type: 'string',
          hideable: false,
          headerName: 'Checkbox',
        },
        ...columnsWithSavedStateObject,
      ];
    }

    // inject case insensitive quick filter
    columnsWithSavedStateObject.forEach((c) => {
      if (!('getApplyQuickFilterFn' in c)) {
        c.getApplyQuickFilterFn = getApplyFilterFnCaseInsensitive;
      }
    });

    // add actions column if there are row actions
    if (_.filter(rowActions, (action) => !!action.onRowClick).length > 0) {
      columnsWithSavedStateObject = [
        ...columnsWithSavedStateObject,
        {
          field: 'actions',
          headerName: 'Actions',
          type: 'actions',
          hideable: false,
          minWidth:
            34 *
              (_.filter(rowActions, (a) => !!a.onRowClick && !a.showInRowMenu)
                .length +
                (_.some(rowActions, (a) => a.showInRowMenu) ? 1 : 0)) +
            20, // takes into account padding included in column width
          getActions: actionGetter,
        },
      ] as (ExtendedGridColDef | GridActionsColDef)[];
    }

    return columnsWithSavedStateObject;
  }, [
    columns,
    savedDatagridState,
    editLocked,
    detailPanel,
    calculatedCheckboxSelection,
    actionGetter,
    rowActions,
  ]);

  // handle state resetting
  const handleResetState = useCallback(() => {
    setPageSize(defaultPageSize);
    const stateToRestore = structuredClone(combinedInitialState);
    _.set(stateToRestore, 'columns.orderedFields', [
      calculatedCheckboxSelection && '__check__',
      detailPanel && '__detail_panel_toggle__',
      ..._.map(columns, 'field'),
    ]);
    // if no initialFilter set, default to no filters
    !Object.keys(stateToRestore).includes('filter') &&
      _.set(stateToRestore, 'filter', { filterModel: { items: [] } });
    apiRef?.current.restoreState(stateToRestore);
  }, [
    defaultPageSize,
    combinedInitialState,
    columns,
    calculatedCheckboxSelection,
    detailPanel,
  ]);

  const handleSetColumnGroup = useCallback(
    (name: string) => {
      const matchingColumnGroup = _.find(memoizedVisibleGroups || [], {
        name,
      });
      if (matchingColumnGroup) {
        const groupState: { [key: string]: object } = {};
        const groupColumnVisibilityModel: { [key: string]: boolean } = {};

        _.map(columns, 'field').forEach((col) => {
          groupColumnVisibilityModel[col] =
            matchingColumnGroup.visibleColumns.includes(col);
        });
        groupState.columns = {
          columnVisibilityModel: groupColumnVisibilityModel,
          all: _.map(columns, 'field'),
          orderedFields: [
            calculatedCheckboxSelection && '__check__',
            detailPanel && '__detail_panel_toggle__',
            ..._.map(columns, 'field'),
          ],
        };
        apiRef?.current.restoreState(groupState);
      }
    },
    [memoizedVisibleGroups, columns, calculatedCheckboxSelection, detailPanel]
  );

  // calculate fields to export
  const exportFields = useCallback(() => {
    const sortedVisibleFields = _.filter(
      apiRef?.current?.state?.columns?.all,
      (c) =>
        _.get(apiRef?.current?.state?.columns?.columnVisibilityModel, c) &&
        c !== 'actions'
    );
    const fieldsToExport: string[] = [];
    sortedVisibleFields.forEach((f) => {
      if (
        _.includes(f, 'part') &&
        _.includes(apiRef?.current?.state?.columns?.all, 'manufacturer') &&
        !_.includes(sortedVisibleFields, 'manufacturer')
      ) {
        fieldsToExport.push(`manufacturer`);
      }
      if (
        _.includes(f, 'part') &&
        _.includes(apiRef?.current?.state?.columns?.all, 'description') &&
        !_.includes(sortedVisibleFields, 'description')
      ) {
        fieldsToExport.push('description');
      }
      fieldsToExport.push(f);
    });
    return fieldsToExport;
  }, [apiRef?.current?.state?.columns?.all]);

  // construct components based on props
  const { GenerateReportDialog } = useGenerateReports(!!generateReportData);
  // construct GenerateReport button
  function GenerateReportMenuItem(props: GridExportMenuItemProps<{}>) {
    const { hideMenu } = props;
    const [generateReportOpen, setGenerateReportOpen] = useState(false);

    return (
      <>
        <MenuItem
          onClick={() => {
            setGenerateReportOpen(true);
          }}
        >
          Custom Report
        </MenuItem>
        <GenerateReportDialog
          data={generateReportData}
          open={generateReportOpen}
          onClose={() => {
            setGenerateReportOpen(false);
            hideMenu?.();
          }}
          gridName={gridName}
        />
      </>
    );
  }

  function CustomExportButton(props: ButtonProps) {
    return (
      <GridToolbarExportContainer {...props}>
        <MenuItem
          onClick={() => {
            const fieldsToExport = exportFields();
            const columnsToExport = columns.filter((column) =>
              fieldsToExport.includes(column.field)
            );
            const csvData = apiRef?.current?.getDataAsCsv({
              fields: fieldsToExport,
            });
            if (csvData) {
              const updatedCsvData = processCsvData(csvData, columnsToExport);
              const blob = new Blob([updatedCsvData], {
                type: 'text/csv;charset=utf-8;',
              });
              downloadFile(
                blob,
                `${exportFileNamePrefix || 'CofactrExport'}_${nowShortDate()}`,
                'csv'
              );
            }
          }}
        >
          Download as CSV
        </MenuItem>
        <GridExcelExportMenuItem
          options={{
            exceljsPostProcess: async (processInput) => {
              processExcelData(
                processInput,
                columns.filter((column) =>
                  exportFields().includes(column.field)
                )
              );
              return Promise.resolve();
            },
            fields: exportFields(),
            fileName: `${
              exportFileNamePrefix || 'CofactrExport'
            }_${nowShortDate()}`,
          }}
        />
        <GridPrintExportMenuItem />
        {_.includes(ReportDataTypes, gridName) && generateReportsFeature && (
          <GenerateReportMenuItem />
        )}
      </GridToolbarExportContainer>
    );
  }

  const [searchText, setSearchText] = useState('');
  const [quickSearchFocused, setQuickSearchFocused] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const debouncedFilterUpdate = useCallback(
    _.debounce((value: string) => {
      if (apiRef?.current) {
        apiRef?.current.setQuickFilterValues([value]);
      }
    }, 800),
    []
  );

  useEffect(() => {
    debouncedFilterUpdate(searchText);
  }, [searchText, apiRef]);

  const ActionBarComponent = () => {
    const batchActions = _.filter(
      rowActions,
      (action) => !!action.onBatchClick && !action.hideBatchAction
    );

    if (!batchActions) return null;

    return (
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={actionButtonSpacing}
        sx={{ my: 1 }}
      >
        {_.filter(batchActions, (action) => !action.showInBatchMenu).map(
          (action) => {
            const ActionIcon: TablerIcon = action.getRowIcon
              ? action.getRowIcon(selectedRowData[0])
              : action.Icon;

            return (
              <FullOrIconButton
                key={action.getLabel(selectedRowData[0])}
                onClick={() => {
                  if (action.onBatchClick) {
                    action.onBatchClick(selectedRowData);
                  }
                }}
                tooltip={
                  !selectedRowIds.length
                    ? 'Select at least one row checkbox to enable actions'
                    : action.getBatchTooltip
                    ? action.getBatchTooltip(selectedRowIds.length)
                    : action.getLabel(selectedRowData[0])
                }
                color={
                  action.color ??
                  action.getColor?.(selectedRowData[0]) ??
                  COLOR_OPTIONS.primary
                }
                disabled={
                  action.disabled ||
                  !selectedRowIds.length ||
                  (!!action.isBatchDisabled &&
                    action.isBatchDisabled(selectedRowData))
                }
                permissionScope={action.permissionScope}
                startIcon={<ActionIcon />}
                label={action.getLabel(selectedRowData[0])}
                variant="outlined"
                trackingName={action.trackingName}
              />
            );
          }
        )}
        <MoreActionsButton<T>
          rowActions={batchActions}
          selectedRowData={selectedRowData}
          narrowWindow={narrowWindow}
        />
      </Stack>
    );
  };

  const combinedComponents: Partial<GridSlotsComponent> = useMemo(
    () => ({
      detailPanelExpandIcon: IconPlus,
      detailPanelCollapseIcon: IconMinus,
      ExportIcon: IconDownload,
      OpenFilterButtonIcon: IconFilter,
      ColumnSelectorIcon: IconColumns,
      LoadingOverlay: LinearProgress,
      NoRowsOverlay: () => (
        <Typography sx={{ textAlign: 'center', mt: 4 }} variant="subtitle1">
          {noRows}
        </Typography>
      ),
      Toolbar: () =>
        hideToolbar ? null : (
          <GridToolbarContainer>
            <Grid
              container
              sx={{
                px: fullCard ? actionButtonSpacing : undefined,
                pt: fullCard ? gridSpacing : undefined,
              }}
            >
              <Grid
                container
                item
                columnSpacing={1.5}
                alignItems="center"
                xs={12}
              >
                {(toolbarColumns || toolbarFilters) && (
                  <Grid item xs="auto">
                    <FullOrIconButton
                      onClick={handleResetState}
                      tooltip={memoizedVisibleGroups ? 'Default' : 'Reset'}
                      color="primary"
                      startIcon={<IconArrowBackUp />}
                      label={memoizedVisibleGroups ? 'Default' : 'Reset'}
                      variant={undefined}
                    />
                  </Grid>
                )}
                {(memoizedVisibleGroups || []).map((g) => (
                  <Grid item xs="auto" key={g.name}>
                    <FullOrIconButton
                      startIcon={g.icon}
                      onClick={() => handleSetColumnGroup(g.name)}
                      tooltip={g.label}
                      color="primary"
                      label={g.label}
                      variant={undefined}
                    />
                  </Grid>
                ))}
                {(toolbarColumns || toolbarFilters) && (
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ ml: 1.5 }}
                  />
                )}
                {toolbarColumns && (
                  <Grid item xs="auto">
                    <GridToolbarColumnsButton
                      sx={
                        narrowWindow ? narrowWindowToolbarButtonSx : undefined
                      }
                    />
                  </Grid>
                )}
                {toolbarFilters && (
                  <Grid item xs="auto">
                    <GridToolbarFilterButton
                      sx={
                        narrowWindow ? narrowWindowToolbarButtonSx : undefined
                      }
                    />
                  </Grid>
                )}
                {toolbarExport && (
                  <Grid item xs="auto">
                    {exportCsv ? (
                      <FullOrIconButton
                        onClick={exportCsv}
                        tooltip="Export"
                        color="primary"
                        startIcon={<IconDownload />}
                        label="Export"
                        variant={undefined}
                        loadingButton
                        loading={isExportLoading}
                      />
                    ) : (
                      <CustomExportButton
                        sx={
                          narrowWindow ? narrowWindowToolbarButtonSx : undefined
                        }
                      />
                    )}
                  </Grid>
                )}
                <Grid item xs="auto">
                  <ExtendedQuickFilter
                    searchText={searchText}
                    setSearchText={setSearchText}
                    handleChange={handleChange}
                    quickSearchFocused={quickSearchFocused}
                    setQuickSearchFocused={setQuickSearchFocused}
                  />
                </Grid>
                {(newRecordButton.onClick || uploadRecordButton.onClick) && (
                  <Grid
                    item
                    xs
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={actionButtonSpacing}
                    >
                      {uploadRecordButton.onClick && (
                        <FullOrIconButton
                          onClick={uploadRecordButton.onClick}
                          tooltip={uploadRecordButton.label}
                          color="primary"
                          disabled={uploadRecordButton.disabled}
                          permissionScope={uploadRecordButton.permissionScope}
                          startIcon={<IconUpload />}
                          variant="outlined"
                          label={uploadRecordButton.label}
                          trackingName={uploadRecordButton.trackingName}
                        />
                      )}
                      {newRecordButton.onClick && (
                        <FullOrIconButton
                          onClick={newRecordButton.onClick}
                          tooltip={newRecordButton.label}
                          color="primary"
                          disabled={newRecordButton.disabled}
                          permissionScope={newRecordButton.permissionScope}
                          startIcon={<IconCirclePlus />}
                          variant="contained"
                          label={newRecordButton.label}
                          trackingName={newRecordButton.trackingName}
                        />
                      )}
                    </Stack>
                  </Grid>
                )}
              </Grid>
              {!!ActionBarComponent && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <ActionBarComponent />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                {children}
              </Grid>
            </Grid>
          </GridToolbarContainer>
        ),
      ...components,
    }),
    [
      components,
      children,
      memoizedVisibleGroups,
      handleResetState,
      handleSetColumnGroup,
      hideToolbar,
      toolbarExport,
      exportCsv,
      isExportLoading,
      exportFields,
      exportFileNamePrefix,
      gridName,
      generateReportsFeature,
      generateReportData,
      apiRef,
      searchText,
      setSearchText,
      handleChange,
      quickSearchFocused,
      setQuickSearchFocused,
      newRecordButton.onClick,
      newRecordButton.label,
      newRecordButton.disabled,
      uploadRecordButton.onClick,
      uploadRecordButton.label,
      uploadRecordButton.disabled,
      ActionBarComponent,
      fullCard,
    ]
  );

  // filter columns by app access && allowInBlended
  const memoizedColumns = useMemo(() => {
    const filteredColumns = columnsWithSavedState.filter((c) => {
      const columnAppPermissions = c.permissionScope?.app
        ? appsWithPermissions[c.permissionScope?.app]
        : undefined;
      const allowColumnInBlended = Boolean(
        !!c.allowInBlended &&
          columnAppPermissions?.permissions?.state ===
            appPermissionStates.noBlended
      );
      return !isPermissionDisabled(c?.permissionScope) || allowColumnInBlended;
    });

    const columnsWithEditableParams = filteredColumns.map((column) => {
      if (column.editable && column.inlineEditRenderCell) {
        const getMutationArgs = column.getMutationArgs;
        const mutationPreSubmit = column.mutationPreSubmit;
        const originalRenderCell = column.renderCell;
        const getIsDisabled = column?.mutationGetIsDisabled;
        const getDefaultValue = column?.getMutationDefaultValue;
        column.renderEditCell = (params) =>
          renderMutationCellWithInput({
            params,
            apiRef,
            InputElement: column.mutationInputElement ?? undefined,
            validation: column?.mutationValidation,
            permissionScope: column?.editPermissionScope,
            useMutation: column.useMutation,
            getMutationArgs: getMutationArgs
              ? (data: FieldValues) => getMutationArgs(data, params)
              : undefined,
            preSubmit: mutationPreSubmit,
            defaultValue: getDefaultValue ? getDefaultValue(params) : undefined,
            additionalInputElementProps:
              column?.additionalInputElementProps ?? undefined,
            clientV2: column?.mutationAsClientV2 ?? false,
          });
        column.renderCell = (params) =>
          renderCellWithEditWrapper({
            params,
            apiRef,
            renderMethod: originalRenderCell
              ? () => originalRenderCell(params)
              : null,
            permissionScope: column?.editPermissionScope,
            disabled: editLocked,
            getIsDisabled,
          });
      }
      return column;
    });

    if (dynamicFilters) {
      dynamicFilters.forEach((filter) => {
        const columnIndex = _.findIndex(columnsWithEditableParams, [
          'field',
          filter.field,
        ]);

        columnsWithEditableParams[columnIndex] = {
          ...columnsWithEditableParams[columnIndex],
          valueOptions: filter.options,
        };
      });
    }

    return columnsWithEditableParams;
  }, [dynamicFilters, columns]);

  const [datagridColumnVisibilityModel, setDatagridColumnVisibilityModel] =
    useState(newSavedColumnVisibilityModel);

  useEffect(() => {
    if (
      !_.isEqual(datagridColumnVisibilityModel, newSavedColumnVisibilityModel)
    ) {
      setDatagridColumnVisibilityModel(newSavedColumnVisibilityModel);
    }
  }, [columns, newSavedColumnVisibilityModel]);

  // construct chart components
  const chartColumns = useMemo(
    () =>
      showCharts
        ? _.chain(memoizedColumns)
            .filter('showChart')
            .filter(
              (c) =>
                !!c.showChart?.alwaysVisible ||
                !!datagridColumnVisibilityModel[c.field]
            )
            .value()
        : [],
    [memoizedColumns, datagridColumnVisibilityModel, showCharts]
  );

  const chartAccordionCards = useMemo(
    () =>
      showCharts
        ? _.chain(chartColumns)
            .map((c) => c.showChart?.accordionCardGroupTitle)
            .compact()
            .uniq()
            .value()
        : [],
    [chartColumns, showCharts]
  );

  const handleChartFilterClick = useCallback(
    (field: string, value: string) => {
      const currentFilterModelItems =
        apiRef?.current?.state?.filter.filterModel.items;
      const currentFilterModelQuickfilter =
        apiRef?.current?.state?.filter.filterModel.quickFilterValues;
      // check if filter already exists
      if (_.find(currentFilterModelItems, { columnField: field })) {
        // check if filter value is already active and remove filter if it is
        if (
          _.find(currentFilterModelItems, { columnField: field })?.value ===
          value
        ) {
          apiRef?.current?.setFilterModel({
            items: [
              ..._.filter(
                currentFilterModelItems,
                (f) => f.columnField !== field
              ),
            ],
            quickFilterValues: currentFilterModelQuickfilter,
          });
        } else {
          // if filter exists but is a different value, then update the filter value
          apiRef?.current?.setFilterModel({
            items: [
              ..._.filter(
                currentFilterModelItems,
                (f) => f.columnField !== field
              ),
              { columnField: field, operatorValue: 'is', value },
            ],
            quickFilterValues: currentFilterModelQuickfilter,
          });
        }
      } else {
        apiRef?.current?.setFilterModel({
          items: [
            ...currentFilterModelItems,
            { columnField: field, operatorValue: 'is', value },
          ],
          quickFilterValues: currentFilterModelQuickfilter,
        });
      }
    },
    [
      apiRef?.current?.state?.filter.filterModel.items,
      apiRef?.current?.state?.filter.filterModel.quickFilterValues,
      apiRef?.current?.setFilterModel,
    ]
  );

  const charts = useMemo(() => {
    if (!(chartColumns.length > 0 && showCharts)) {
      return [];
    }
    return _.filter(
      chartColumns,
      (c) => !c.showChart?.accordionCardGroupTitle
    ).map((c) => ({
      column: c,
      data: _.map(apiRef?.current?.state?.rows?.ids || [], (rowId) =>
        apiRef?.current?.getCellValue(rowId, c.field)
      ),
      selectedValue: _.find(apiRef?.current?.state?.filter.filterModel.items, {
        columnField: c.field,
      })?.value,
      partIds: _.chain(rows)
        .map((r) => _.get(r, 'part', null))
        .compact()
        .map((p) => (typeof p === 'string' ? p : p.id))
        .uniq()
        .value(),
      loading: loadingCharts === undefined ? loading : loadingCharts,
    }));
  }, [chartColumns, apiRefReady, rows, loadingCharts, loading]);

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        height: fullCard ? '100%' : undefined,
      }}
    >
      {loading && verboseLoader && <VerboseLoader {...verboseLoader} />}
      {chartColumns.length > 0 && showCharts && (
        <Grid container item xs={12} spacing={gridSpacing} alignItems="stretch">
          {charts.map((chart) => (
            <Grid item xs={3} key={chart.column.field}>
              <DataGridChart
                column={chart.column}
                data={chart.data}
                onFilterClick={handleChartFilterClick}
                selectedValue={chart.selectedValue}
                partIds={chart.partIds}
                loading={chart.loading}
              />
            </Grid>
          ))}
          {chartAccordionCards.map((cardTitle) => (
            <Grid item xs={12} key={cardTitle}>
              <AccordionCard title={cardTitle} unmountOnExit>
                <Grid container spacing={gridSpacing} alignItems="stretch">
                  {_.filter(
                    chartColumns,
                    (c) => c.showChart?.accordionCardGroupTitle === cardTitle
                  ).map(
                    (c) =>
                      !!c.showChart && (
                        <Grid item xs={3} key={c.field}>
                          <DataGridChart
                            column={c}
                            data={_.map(
                              apiRef?.current?.state?.rows?.ids || [],
                              (rowId) =>
                                apiRef?.current?.getCellValue(rowId, c.field)
                            )}
                            onFilterClick={handleChartFilterClick}
                            selectedValue={
                              _.find(
                                apiRef?.current?.state?.filter.filterModel
                                  .items,
                                {
                                  columnField: c.field,
                                }
                              )?.value
                            }
                            partIds={_.chain(rows)
                              .map((r) => _.get(r, 'part', null))
                              .compact()
                              .map((p) => (typeof p === 'string' ? p : p.id))
                              .uniq()
                              .value()}
                            loading={
                              loadingCharts === undefined
                                ? loading
                                : loadingCharts
                            }
                          />
                        </Grid>
                      )
                  )}
                </Grid>
              </AccordionCard>
            </Grid>
          ))}
        </Grid>
      )}
      <DataGridPremium
        {...others}
        localeText={{
          toolbarColumns: narrowWindow ? '' : 'Columns',
          toolbarFilters: narrowWindow ? '' : 'Filters',
          toolbarExport: narrowWindow ? '' : 'Export',
        }}
        disableRowGrouping
        experimentalFeatures={{
          ...(editable ? { newEditingApi: true } : {}),
          ...(aggregationModel ? { aggregation: true } : {}),
        }}
        columns={memoizedColumns}
        rows={rows}
        throttleRowsMs={100}
        getRowHeight={noRowHeightAuto ? undefined : () => 'auto'}
        autoHeight={!fullCard}
        loading={(loading && !verboseLoader) || isLoadingAppAccess}
        pagination={pagination}
        apiRef={apiRef}
        pageSize={pageSize}
        onPageSizeChange={handlePageSizeChangeCallback}
        rowsPerPageOptions={pageSizeOptions}
        onRowClick={onRowClick}
        sx={{
          ...sx,
          ...initialSx,
        }}
        disableSelectionOnClick
        checkboxSelection={calculatedCheckboxSelection}
        disableColumnReorder={false}
        components={combinedComponents}
        initialState={getUpdatedSavedDatagridState()}
        columnVisibilityModel={datagridColumnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setDatagridColumnVisibilityModel(newModel)
        }
        aggregationModel={aggregationModel}
        onSelectionModelChange={onSelectionModelChange || handleSelect}
        {...detailPanelAttributes}
      />
    </Box>
  );
};

export default ExtendedDatagrid;
