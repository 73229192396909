import { User, UserLite } from 'types/user';
import { FC } from 'react';
import { Avatar, AvatarProps, useTheme } from '@mui/material';
import { IconMessageChatbot, IconSparkles } from '@tabler/icons-react';
import { OrgUserFull } from 'types/org';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

interface UserAvatarProps extends AvatarProps {
  user?: User | UserLite | OrgUserFull | null;
  firstName?: string;
  lastName?: string;
  image?: string | null;
  size?: 'sm' | 'md';
  automation?: boolean;
  obscureCofactrUser?: boolean;
}

const UserAvatar: FC<UserAvatarProps> = ({
  user,
  firstName,
  lastName,
  image,
  size = 'md',
  sx = {},
  automation = false,
  obscureCofactrUser = false,
  ...others
}) => {
  const theme = useTheme();
  const userName = `${
    firstName ? firstName[0] : user?.firstName ? user?.firstName[0] : ''
  } ${lastName ? lastName[0] : user?.lastName ? user?.lastName[0] : ''}`;

  return (
    <Avatar
      src={obscureCofactrUser ? '' : image || user?.image || ''}
      sx={{
        ...sx,
        bgcolor:
          (automation && !user) || obscureCofactrUser
            ? theme.palette.primary.main
            : stringToColor(userName),
        color: 'white',
        width: { sm: 24, md: 34 }[size],
        height: { sm: 24, md: 34 }[size],
        fontSize: { sm: 12, md: 16 }[size],
      }}
      {...others}
    >
      {automation && !user && (
        <IconSparkles size={{ sm: 24, md: 34 }[size] - 6} />
      )}
      {obscureCofactrUser && (
        <IconMessageChatbot size={{ sm: 24, md: 34 }[size] - 6} />
      )}
      {!obscureCofactrUser &&
        (firstName ? firstName[0] : user?.firstName ? user?.firstName[0] : '')}
      {!obscureCofactrUser &&
        (lastName ? lastName[0] : user?.lastName ? user?.lastName[0] : '')}
    </Avatar>
  );
};

export default UserAvatar;
