import React, { FC, useState } from 'react';
import {
  formatToShortDate,
  formatToUniversalDateTime,
  formatToDateTime,
} from 'utils/dates';
import { formatPrice } from 'utils/functions';
import {
  Grid,
  Typography,
  Tooltip,
  Stack,
  TypographyTypeMap,
  AvatarGroup,
  Box,
} from '@mui/material';
import WithSkeleton from 'ui-component/extended/WithSkeleton';
import CopyClipboard from 'ui-component/extended/CopyClipboard';
import { useTheme } from '@mui/material/styles';
import {
  RelatedRecord,
  StockLocation,
  StockLocationLite,
} from 'types/inventory';
import { User, UserLite } from 'types/user';
import { DateString, DateTimeString } from 'types';
import _ from 'lodash';
import UserInfoSection from 'ui-component/UserInfoSection';
import RelatedRecords from 'ui-component/shared/RelatedRecords';
import AddressCard from 'ui-component/cards/AddressCard';
import { IconPencil } from '@tabler/icons-react';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import { PermissionScope } from 'types/apps';
import UserAvatar from 'ui-component/extended/UserAvatar';
import { StockLocationSchemaLite } from 'types/clientV2/stockLocations';
import { Tag } from 'types/clientV2/tag';
import Tags from 'ui-component/Tags';

export enum InfoSectionItemFormatTypes {
  string = 'string',
  number = 'number',
  dateShort = 'dateShort',
  dateMedium = 'dateMedium',
  dateLong = 'dateLong',
  priceShort = 'priceShort',
  price = 'price',
  children = 'children',
  stockLocationAddress = 'stockLocationAddress',
  user = 'user',
  users = 'users',
  relatedRecords = 'relatedRecords',
  boolean = 'boolean',
  tags = 'tags',
}

// TODO: update the typing for the functions --> is the value type correct also?
const formatFunctions: any = {
  [InfoSectionItemFormatTypes.string]: (value: string) => value,
  [InfoSectionItemFormatTypes.number]: (value: number) =>
    value ? value.toLocaleString('en-US') : '-',
  [InfoSectionItemFormatTypes.dateShort]: (value: string) =>
    value ? formatToShortDate(value) : '-',
  [InfoSectionItemFormatTypes.dateMedium]: (value: string) =>
    value ? formatToDateTime(value) : '-',
  [InfoSectionItemFormatTypes.dateLong]: (value: string) =>
    value ? formatToUniversalDateTime(value) : '-',
  [InfoSectionItemFormatTypes.priceShort]: (value: string) =>
    formatPrice(parseFloat(value).toFixed(2)),
  [InfoSectionItemFormatTypes.price]: (value: string | number) =>
    formatPrice(value),
  [InfoSectionItemFormatTypes.children]: (value: boolean) => value,
  [InfoSectionItemFormatTypes.stockLocationAddress]: (value: boolean) => value,
  [InfoSectionItemFormatTypes.user]: (value: boolean) => value,
  [InfoSectionItemFormatTypes.users]: (value: boolean) => value,
  [InfoSectionItemFormatTypes.relatedRecords]: (value: boolean) => value,
  [InfoSectionItemFormatTypes.boolean]: (value: boolean) =>
    value ? 'Yes' : 'No',
  [InfoSectionItemFormatTypes.tags]: (value: boolean) => value,
};

export interface InfoSectionItemProps {
  caption?: string;
  isLoading?: boolean;
  value?:
    | string
    | number
    | boolean
    | undefined
    | null
    | Date
    | DateTimeString
    | DateString;
  userValue?: UserLite | User | null;
  usersValue?: UserLite[] | User[] | null;
  dateValue?: Date | string | null;
  relatedRecordsValue?: RelatedRecord[];
  stockLocationValue?:
    | StockLocation
    | StockLocationLite
    | StockLocationSchemaLite
    | null;
  tagsValue?: Tag[];
  formatType?: InfoSectionItemFormatTypes;
  error?: boolean;
  clipboard?: boolean;
  prefix?: string;
  suffix?: string;
  typographyProps?: {
    variant?: TypographyTypeMap['props']['variant'];
    sx?: TypographyTypeMap['props']['sx'];
  };
  placeholder?: string;
  tooltip?: string;
  xs?: number | boolean;
  color?:
    | 'warning'
    | 'success'
    | 'error'
    | 'info'
    | 'primary'
    | 'secondary'
    | 'grey';
  editInline?: React.ReactElement;
  disableEdit?: boolean;
  permissionScope?: PermissionScope;
  onOpenEditRelatedRecords?: () => void;
  useInfoSectionAutomationOption?: boolean;
}

const InfoSectionItem: FC<InfoSectionItemProps> = ({
  caption,
  isLoading = false,
  value,
  userValue,
  usersValue,
  dateValue,
  relatedRecordsValue,
  stockLocationValue,
  tagsValue,
  formatType = InfoSectionItemFormatTypes.string,
  children,
  error = false,
  clipboard = true,
  prefix = '',
  suffix = '',
  tooltip = '',
  typographyProps = {},
  placeholder = '-',
  xs,
  color,
  editInline,
  disableEdit = false,
  permissionScope,
  onOpenEditRelatedRecords,
  useInfoSectionAutomationOption = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();

  const colorMap: { [key: string]: string } = {
    warning: theme.palette.warning.main,
    success: theme.palette.success.main,
    error: theme.palette.error.main,
    info: theme.palette.info.main,
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    grey: theme.palette.grey[500],
  };

  let clonedEditInline = null;

  if (editInline) {
    clonedEditInline = React.cloneElement(editInline, {
      closeEditing: () => setIsEditing(false),
    });
  }

  const EditButton = editInline && !isEditing && (
    <SegmentIconButton
      color="primary"
      onClick={() => setIsEditing(true)}
      size="small"
      disabled={disableEdit}
      permissionScope={permissionScope}
    >
      <IconPencil stroke="1.5px" width={20} height={20} />
    </SegmentIconButton>
  );
  return (
    <Tooltip title={tooltip}>
      <Grid item xs={xs || true}>
        {!!caption && <Typography variant="caption">{caption}</Typography>}
        {clonedEditInline && isEditing ? (
          <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
            {clonedEditInline}
          </Stack>
        ) : (
          <>
            <WithSkeleton isLoading={isLoading} width={60}>
              {!_.includes(
                [
                  InfoSectionItemFormatTypes.children,
                  InfoSectionItemFormatTypes.stockLocationAddress,
                  InfoSectionItemFormatTypes.user,
                  InfoSectionItemFormatTypes.relatedRecords,
                  InfoSectionItemFormatTypes.tags,
                ],
                formatType
              ) && (
                <>
                  <Typography
                    variant={
                      typographyProps.variant ??
                      (value ||
                      formatType === InfoSectionItemFormatTypes.boolean
                        ? 'h4'
                        : 'body1')
                    }
                    sx={{
                      ...(error
                        ? { color: theme.palette.error.dark }
                        : color
                        ? { color: colorMap[color] }
                        : {}),
                      fontStyle:
                        value ||
                        formatType === InfoSectionItemFormatTypes.boolean
                          ? 'normal'
                          : 'italic',
                      ...typographyProps.sx,
                    }}
                  >
                    {value || formatType === InfoSectionItemFormatTypes.boolean
                      ? `${prefix}${formatFunctions[formatType](
                          value
                        )}${suffix}`
                      : placeholder}
                    {EditButton}
                    {clipboard && Boolean(value) && (
                      <CopyClipboard
                        label={caption || ''}
                        input={`${prefix}${formatFunctions[formatType](
                          value
                        )}${suffix}`}
                      />
                    )}
                    {children}
                  </Typography>
                </>
              )}
              {formatType === InfoSectionItemFormatTypes.children && (
                <>
                  {EditButton}
                  {children}
                </>
              )}
              {formatType === InfoSectionItemFormatTypes.user && (
                <UserInfoSection
                  user={userValue}
                  subtitle={
                    dateValue ? formatToDateTime(dateValue) : userValue?.title
                  }
                  automation
                />
              )}
              {formatType === InfoSectionItemFormatTypes.users &&
                (usersValue ? (
                  <AvatarGroup max={6} sx={{ flexDirection: 'row' }}>
                    {usersValue.map((user) => (
                      <Tooltip
                        title={`${user.firstName} ${user.lastName}`}
                        key={user.id}
                      >
                        <Box>
                          <UserAvatar
                            user={user}
                            sx={{
                              ...theme.typography.mediumAvatar,
                            }}
                            size="sm"
                            obscureCofactrUser
                            automation={useInfoSectionAutomationOption}
                          />
                        </Box>
                      </Tooltip>
                    ))}
                  </AvatarGroup>
                ) : (
                  <Typography variant="h4">-</Typography>
                ))}
              {formatType === InfoSectionItemFormatTypes.relatedRecords && (
                <RelatedRecords
                  relatedRecords={relatedRecordsValue}
                  onOpenEditRelatedRecords={onOpenEditRelatedRecords}
                  EditButton={EditButton || undefined}
                />
              )}
              {formatType === InfoSectionItemFormatTypes.tags && (
                <Tags tags={tagsValue} />
              )}
              {formatType === InfoSectionItemFormatTypes.stockLocationAddress &&
                (stockLocationValue ? (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <AddressCard location={stockLocationValue} startCollapsed />
                    {EditButton}
                  </Stack>
                ) : (
                  <Typography variant="h4">-{EditButton}</Typography>
                ))}
            </WithSkeleton>
          </>
        )}
      </Grid>
    </Tooltip>
  );
};

export default InfoSectionItem;
