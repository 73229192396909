import { KitRequestStatus } from 'types/kitting';
import { AllocationSchema } from 'types/clientV2/allocations';
import { KitRequestSubtype } from 'views/kitting/Kit/KitRequestContext';
import {
  KitRequestAllocationStatus,
  CalculateKitRequestStatusReturn,
} from 'hooks/useGetDecoratedKitRequestLines/utils';
import { COLOR_OPTIONS } from 'types';
import { ProgressStackProps } from 'ui-component/ProgressStack';

export const calculateKitRequestStatus = (kitRequest: KitRequestSubtype) => {
  if (kitRequest.voidedAt) {
    return KitRequestStatus.VOIDED;
  }
  if (kitRequest.fulfilledAt) {
    return KitRequestStatus.FULFILLED;
  }
  if (kitRequest.approved) {
    return KitRequestStatus.APPROVED;
  }
  return KitRequestStatus.DRAFT;
};

export const calculateAvailableQuantForStockLot = ({
  allocations,
  stockLotQuant,
  kitRequest,
  kitRequestLineId,
}: {
  allocations: AllocationSchema[];
  stockLotQuant: number;
  kitRequest: KitRequestSubtype | null;
  kitRequestLineId?: KitRequestSubtype['id'];
}) => {
  const totalAllocations = allocations.reduce((total, allocation) => {
    if (
      ((!allocation.productionRun && !kitRequest?.productionRun?.id) ||
        allocation.productionRun?.id !== kitRequest?.productionRun?.id) &&
      (!kitRequestLineId ||
        !allocation.kitRequestLine ||
        allocation.kitRequestLine.id !== kitRequestLineId)
    ) {
      return total + allocation.quant;
    }
    return total;
  }, 0);

  return stockLotQuant - totalAllocations;
};

const statusColorMap: Record<KitRequestAllocationStatus, COLOR_OPTIONS> = {
  [KitRequestAllocationStatus.Unavailable]: COLOR_OPTIONS.error,
  [KitRequestAllocationStatus.Expected]: COLOR_OPTIONS.warning,
  [KitRequestAllocationStatus.OnHand]: COLOR_OPTIONS.primary,
  [KitRequestAllocationStatus.Kitted]: COLOR_OPTIONS.success,
  [KitRequestAllocationStatus.Quarantined]: COLOR_OPTIONS.secondary,
  [KitRequestAllocationStatus.Shipped]: COLOR_OPTIONS.primary,
  [KitRequestAllocationStatus.Delivered]: COLOR_OPTIONS.secondary,
};

const statusLabelMap: Record<KitRequestAllocationStatus, string> = {
  [KitRequestAllocationStatus.Unavailable]: 'Unavailable',
  [KitRequestAllocationStatus.Expected]: 'Expected',
  [KitRequestAllocationStatus.OnHand]: 'On Hand',
  [KitRequestAllocationStatus.Quarantined]: 'Quarantined',
  [KitRequestAllocationStatus.Kitted]: 'Kitted',
  [KitRequestAllocationStatus.Shipped]: 'Shipped',
  [KitRequestAllocationStatus.Delivered]: 'Delivered',
};

export const getProgressStackData = (
  kitRequestStatuses: CalculateKitRequestStatusReturn | undefined
): ProgressStackProps['bars'] => {
  if (!kitRequestStatuses) return [];

  const { lineStats } = kitRequestStatuses;

  // Count occurrences of each status across all lines
  const statusCounts = Object.values(lineStats).reduce((acc, line) => {
    line.calculatedStatuses.forEach((status) => {
      acc[status] = (acc[status] || 0) + 1;
    });
    return acc;
  }, {} as Record<KitRequestAllocationStatus, number>);

  return [
    {
      key: 'kit-request-status',
      label: 'Kit Status',
      sections: Object.entries(statusCounts)
        .filter(([_, count]) => count > 0)
        .map(([status, value]) => ({
          key: status,
          label: statusLabelMap[status as KitRequestAllocationStatus],
          value,
          color: statusColorMap[status as KitRequestAllocationStatus],
        })),
    },
  ];
};
