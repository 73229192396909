import { Alert, AlertProps, AlertTitle } from '@mui/material';
import { FC } from 'react';
import { AppCard } from 'ui-component/AppCard';
import _ from 'lodash';
import { appPermissionStates } from 'types/apps';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { Box } from '@mui/system';

interface AlertWithAppIconProps extends AlertProps {
  severity: 'error' | 'warning' | 'info' | 'success';
  title: string;
  app: string;
  ignorePermissions?: boolean;
  badge?: string | number | null;
}

const AlertWithAppIcon: FC<AlertWithAppIconProps> = ({
  severity,
  title,
  app,
  children,
  ignorePermissions,
  badge,
  ...others
}) => {
  const { appsWithPermissions } = useAppAccessContext();
  const appObject = _.get(appsWithPermissions, app, null);

  if (!appObject) {
    return <></>;
  }

  const disabled = ignorePermissions
    ? false
    : appObject.permissions?.comingSoon ||
      appObject.permissions?.state !== appPermissionStates.enabled;

  if (disabled) {
    return <></>;
  }

  return (
    <Alert
      variant="outlined"
      color={severity}
      icon={
        <Box sx={badge ? { mr: 4 } : { mr: 1 }}>
          <AppCard
            ignorePermissions
            app={appObject}
            size="sm"
            clickable={false}
            badge={badge}
            color={
              {
                error: 'error',
                warning: 'warning',
                info: 'secondary',
                success: 'success',
              }[severity] as 'error' | 'success' | 'warning' | 'secondary'
            }
            variant="title"
          />
        </Box>
      }
      {...others}
    >
      <AlertTitle>{title}</AlertTitle>
      {children}
    </Alert>
  );
};

export default AlertWithAppIcon;
